import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, ViewChild, inject } from '@angular/core';
import { DepositDoiService } from './deposit-doi.service';
import { GrowInAnimation, GrowOutAnimation } from '@exl-ng/mulo-common';
import { TranslateService } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { SharedModule } from '../../../shared/shared.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatIcon } from '@angular/material/icon';
import { AbstractControl, FormControl } from '@angular/forms';
import { validateDoiFormat } from '../../../shared/validators/doi.validator';

@Component({
    selector: 'esp-deposit-doi',
    templateUrl: './deposit-doi.component.html',
    styleUrls: ['./deposit-doi.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
    standalone: true,
    imports: [SharedModule, MatFormFieldModule, MatIcon],
})
export class DepositDoiComponent implements OnInit, OnChanges {
    private doiSvc = inject(DepositDoiService);
    private xl8Svc = inject(TranslateService);

    @Input() placeholder: string;
    @Input() hint: string;
    @Input() doi: string;
    @Input() assetType: string;
    @Input() isReserved = false;
    @Input() isReservable = true;
    @Input() isRegistered = false;
    @Input() isAutoPopulated = false;
    @Output() doiChanged = new EventEmitter<string>();
    @Output() doiReserved = new EventEmitter<string>();
    @Output() doiDismissed = new EventEmitter<void>();
    @ViewChild('dismissBtn') dismissBtn: MatButton;
    @ViewChild('reserveBtn') reserveBtn: MatButton;
    haveDoiConfigurationPerAssetType = false;
    reserveDoiInProcess = false;
    translatedResourceType = {};
    @Input() doiControl: AbstractControl = new FormControl('', [
        validateDoiFormat,
    ]);

    constructor() {
        if (this.doiControl) {
            this.doiControl.valueChanges.subscribe((value) => {
                this.doi = value;
                this.doiChanged.emit(value);
            });
        }
    }

    get isReservedDoiButtonVisible() {
        return (
            this.isReservable &&
            this.haveDoiConfigurationPerAssetType &&
            !this.doi
        );
    }

    ngOnInit() {
        this.checkIfhaveDoiConfigurationPerAssetType();
        this.setTranslatedResourceType();
    }

    ngOnChanges(changes: SimpleChanges) {
        const doi = changes['doi'];
        if (doi) {
            if (doi.firstChange) {
                this.doiControl.reset(doi.currentValue, { emitEvent: false });
            } else if (doi.currentValue !== this.doiControl.value) {
                this.doiControl.patchValue(doi.currentValue);
            }
        }
    }

    checkIfhaveDoiConfigurationPerAssetType() {
        this.doiSvc.haveReservedDoiButton(this.assetType).subscribe({
            next: (response) => {
                this.haveDoiConfigurationPerAssetType = response;
            },
            error: (error) => {
                console.error(error);
                this.haveDoiConfigurationPerAssetType = false;
            },
        });
    }

    setTranslatedResourceType() {
        const resourceType = this.assetType || '_';
        this.xl8Svc.get(resourceType).subscribe((translation: String) => {
            this.translatedResourceType['0'] = translation.toLowerCase();
        });
    }

    requestReserveDoi() {
        this.reserveDoiInProcess = true;
        this.doiSvc.getNewReserveDoi(this.assetType).subscribe((newDoi) => {
            this.doiControl.patchValue(newDoi);
            this.isReserved = true;
            this.doiReserved.emit(newDoi);
            this.reserveDoiInProcess = false;
            setTimeout(() => this.dismissBtn.focus());
        });
    }

    dismissDoi() {
        this.doiControl.reset();
        this.isRegistered = false;
        this.isReserved = false;
        this.doiDismissed.emit();
        setTimeout(() => this.reserveBtn.focus());
    }
}
