import { Directive, ElementRef, EventEmitter, OnDestroy, Output, NgModule, inject } from '@angular/core';

@Directive({
    selector: '[muloDomChange]',
    standalone: true,
})
export class DomChangeDirective implements OnDestroy {
  private elementRef = inject(ElementRef);

  private changes: MutationObserver;

  @Output()
  public domChange = new EventEmitter();

  constructor() {
    const element = this.elementRef.nativeElement;

    this.changes = new MutationObserver((mutations: MutationRecord[]) => {
      mutations.forEach((mutation: MutationRecord) =>
        this.domChange.emit(mutation)
      );
    });

    this.changes.observe(element, {
      attributes: true,
      childList: true,
      subtree: true,

    });
  }

  ngOnDestroy(): void {
    this.changes.disconnect();
  }
}


