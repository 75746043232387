import {Component, OnInit, Output, EventEmitter} from '@angular/core';
import { trigger, style, transition, animate } from '@angular/animations';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-stepper-skip-to-end-button',
    templateUrl: './deposit-stepper-skip-to-end-button.component.html',
    styleUrls: ['./deposit-stepper-skip-to-end-button.component.scss'],
    host: { 'class': 'esp-deposit-stepper-skip-to-end-button' },
    animations: [
        trigger('testAnim', [
            transition(':enter', [
                style({
                    transform: 'scale(.8)',
                    opacity: 0,
                    // height: 0
                }),
                animate('600ms cubic-bezier(0.23, 1, 0.32, 1)', style({
                    transform: 'scale(1)',
                    opacity: 1
                    // height: '*',
                }))
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1)',
                    // height: '*',
                    opacity: 1
                }),
                animate('450ms cubic-bezier(0.86, 0, 0.07, 1)', style({
                    transform: 'scale(.7)',
                    // height: 0,
                    opacity: 0
                }))
            ])
        ])
    ],
    standalone: true,
    imports: [MatButton, MatIcon, TranslateModule]
})
export class DepositStepperSkipToEndButtonComponent implements OnInit {

    @Output() skip: EventEmitter<any> =  new EventEmitter();
    constructor() { }

    ngOnInit() {
    }

    onButtonClick(){
        this.skip.emit();
    }
}
