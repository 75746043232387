import { Component, Input, OnInit, inject } from '@angular/core';
import {DepositFormDataService} from '../../deposit-form-data.service';
import {UntypedFormControl} from '@angular/forms';
import { DateInputComponent } from '../../../parts/date-input/date-input.component';

@Component({
    selector: 'esp-deposit-dates',
    templateUrl: './deposit-dates.component.html',
    styleUrls: ['./deposit-dates.component.scss'],
    standalone: true,
    imports: [DateInputComponent],
})
export class DepositDatesComponent implements OnInit {
    depositFormDataService = inject(DepositFormDataService);


    @Input() textPlaceholder: string;
    @Input() range = false;
    @Input() error = false;
    @Input() dateHint = false;
    @Input() autoPopulated: boolean;
    @Input() hasTextInput = false;
    @Input() fromDate: UntypedFormControl;
    @Input() toDate: UntypedFormControl;

    ngOnInit() {
    }

}
