<ng-content select="[slot='pre-brief']"></ng-content>
<span class="brief-body" exlDescriptionA11y>
  <span class="title-and-actions">
    <span class="title-wrap">
      <!-- pre-title (type + status) -->
      <p class="pre-title">
        @if (type) {
          <span class="type small-caps semibold"
            >{{ type }} @if (postType) {
            {{ postType }}
            }</span
            >
          }
          @if (status) {
            <span class="status">{{ status }}</span>
            @if (created || modified) {
              <span class="modified italic">{{ dateLabel }} {{ modified | amTimeAgo }}</span>
            }
          }
          <ng-content select="[slot='pre-title']"></ng-content>
        </p>

        <!-- thumbnail/s -->

        @if (thumbs) {
          <mulo-thumbnail
            (thumbHeight)="onThumbLoad($event)"
            [thumbs]="thumbs"
            [width]="thumbsWidth"
          ></mulo-thumbnail>
        }

        <!-- title -->
        @if (title) {
          <p
            [id]="id + '_title'"
            [class.is-link]="titleAsLink"
            class="title hoverable-link-parent"
            exlDescription
            >
            @if (titleUrl) {
              <a
                [attr.aria-label]="labelOpenInNewWindow"
                [class.is-active]="mouseOver"
                [href]="titleUrl"
                [innerHtml]="title | htmlSanitize"
                [muloElementAsHeading]="titleHeaderLevel"
                dir="auto"
                target="_blank"
              ></a>
            }
            @if (routerLinkUrl) {
              <a
                [class.is-active]="mouseOver"
                [innerHtml]="title | htmlSanitize"
                [muloElementAsHeading]="titleHeaderLevel"
                [queryParams]="queryParams"
                [routerLink]="routerLinkUrl"
                dir="auto"
                >
              </a>
            }
            @if (titleClickable && !titleUrl && !routerLinkUrl) {
              <a
                (click)="onTitleClick($event)"
                [class.is-active]="mouseOver"
                [innerHtml]="title | htmlSanitize"
                [muloElementAsHeading]="titleHeaderLevel"
                dir="auto"
                href
                >
              </a>
            }
            @if (!titleClickable && !titleUrl && !routerLinkUrl) {
              <span
                [innerHtml]="title | htmlSanitize"
                [muloElementAsHeading]="titleHeaderLevel"
                class="title-inner"
                dir="auto"
              ></span>
            }
          </p>
        }
        <ng-content select="[slot='post-title']"></ng-content>
      </span>
      <!-- item actions, menu and stats -->
      <ng-content select="[slot='actions']"></ng-content>
    </span>
    <ng-content></ng-content>
  </span>
