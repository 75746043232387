import { Component, OnInit, inject } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService, TranslateModule } from '@ngx-translate/core';

import { GrowInAnimation, BrandComponent, MultipleEventsDirective, AvatarComponent, ElementAsHeadingDirective } from '@exl-ng/mulo-common';
import { JwtUtilService } from '../../core/jwt-util.service';
import { LogoutService } from '../../logout/logout.service';
import { LoginAsService } from './login-as.service';
import { ResearcherProxyFor } from '../../shared/interfaces/researcher-proxy-for.interface';
import { ResearcherDataService } from '../../core/researcher-data.service';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { UrlUtils } from '../../shared/utils/url.utils';
import { StorageUtilService } from '../../core/storage-util.service';
import { SEOService } from '../../core/seo.service';
import { AffiliationService } from '../../settings/sections/settings-affiliations/affiliation.service';
import { ProfileService } from '../../profile/profile.service';
import { OnetrustPendoService } from '../../core/onetrust-pendo.service';
import { CdkMonitorFocus } from '@angular/cdk/a11y';
import { PersonBriefComponent, PersonBriefTitleDirective } from '../../parts/person-brief/person-brief.component';
import { MatIcon } from '@angular/material/icon';

import { MatButton } from '@angular/material/button';
import { CenteredBlockLayoutComponent } from '../../parts/layouts/centered-block-layout/centered-block-layout.component';

@Component({
    selector: 'esp-login-as',
    templateUrl: './login-as.component.html',
    styleUrls: ['./login-as.component.scss'],
    animations: [GrowInAnimation],
    standalone: true,
    imports: [
    CenteredBlockLayoutComponent,
    BrandComponent,
    MatButton,
    MatIcon,
    PersonBriefComponent,
    CdkMonitorFocus,
    MultipleEventsDirective,
    AvatarComponent,
    PersonBriefTitleDirective,
    ElementAsHeadingDirective,
    TranslateModule
],
})
export class LoginAsComponent implements OnInit {
    private jwtUtilService = inject(JwtUtilService);
    private logoutService = inject(LogoutService);
    private loginAsService = inject(LoginAsService);
    private researcherDataService = inject(ResearcherDataService);
    private profileSvc = inject(ProfileService);
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private router = inject(Router);
    private storageUtilService = inject(StorageUtilService);
    private SEOService = inject(SEOService);
    private translate = inject(TranslateService);
    affiliationService = inject(AffiliationService);
    private otpSvc = inject(OnetrustPendoService);

    accessToResearcherCenter: boolean;
    proxyFor: string;
    signInName: string;
    researchersPhotos = {};
    researchersProxyFor: ResearcherProxyFor[];

    mainInstCode: string;
    institutionCode: string;

    ngOnInit() {
        this.changeDocumentTitle();
        this.institutionCode = UrlUtils.getParam('institution');
        this.mainInstCode =
            this.configurationHandlerService.getMainInstitutionCode();
        this.accessToResearcherCenter =
            this.jwtUtilService.getAccessToResearcherCenter();
        this.proxyFor = this.jwtUtilService.getProxyFor();
        this.signInName = this.jwtUtilService.getUserDisplayName(true);
        this.loginAsService.getProxiesFor(this.proxyFor).subscribe((data) => {
            this.researchersProxyFor = data as ResearcherProxyFor[];
            this.getCorrespondingProfilesImages();
        });
    }

    changeDocumentTitle() {
        this.translate
            .get('research.login.as.document.title', {
                value0: this.jwtUtilService.getUserDisplayName(true),
                value1: this.configurationHandlerService.getMainInstitutionName(),
            })
            .subscribe((res) => {
                this.SEOService.updateTitle(res);
            });
    }

    getCorrespondingProfilesImages() {
        this.researchersProxyFor.forEach((user) => {
            const userId = user.userID;
            this.researcherDataService
                .getResearcherProfileImage(userId)
                .subscribe((imgBlob) => {
                    if (imgBlob) {
                        this.researchersPhotos[userId] =
                            window.URL.createObjectURL(imgBlob);
                    } else {
                        this.profileSvc
                            .getResearcherDataForSEO(userId)
                            .subscribe((data) => {
                                this.researchersPhotos[userId] =
                                    data.researcherImageUrl ?? '';
                            });
                    }
                });
        });
    }

    signOut() {
        this.logoutService.logout();
    }

    loginToOwnDahboard() {
        this.storageUtilService.removeItem('current_user');
        this.otpSvc.updateLoggedIn();
        this.goToProfile();
    }

    onPersonClick(researcher: ResearcherProxyFor) {
        this.storageUtilService.setObject('current_user', researcher);
        this.otpSvc.updateLoggedIn();
        this.goToProfile();
    }

    goToProfile() {
        this.router.navigate(['/profile'], {
            queryParams: { institution: this.institutionCode },
        });
    }

    getInitial(researcher: ResearcherProxyFor) {
        let initials = researcher.lastName.charAt(0).toUpperCase();
        if (researcher.firstName) {
            initials = researcher.firstName.charAt(0).toUpperCase() + initials;
        }
        return initials;
    }

    showTitleOrPosition(researcher: any) {
        return (
            (researcher.currentAffiliation &&
                (researcher.currentAffiliation.title ||
                    researcher.currentAffiliation.position)) ||
            researcher.position
        );
    }
}
