<mat-chip-set [attr.aria-label]="activeItemsAriaLabel" role="group">
  @for (item of activeItems; track trackByFn(i, item); let i = $index) {
    <div @widthOutAnimation @widthInAnimation>
      <ng-container *ngTemplateOutlet="itemTemplate; context: { item: item, index: i }"> </ng-container>
    </div>
  }
  <ng-template #itemTemplate let-item="item" let-index="index">
    <mat-chip
      #chip
      [class.is-hovered]="hoveredItem === index"
      [class.is-focused]="focusedItem === index"
      [class.is-excluded]="item.excluded"
      [class.is-locked]="item.locked"
      [attr.aria-label]="item.ariaLabel || item.label"
      [disabled]="disabled"
      [disableRipple]="true"
      (removed)="handleRemove(item)"
      (mouseenter)="hoveredItem = index"
      (mouseleave)="hoveredItem = null"
      >
      @if (!item.excluded) {
        <span class="chip-label">{{ item.label }}</span>
      }
      @if (item.excluded) {
        <del #del cdkAriaLive="assertive">
          <span class="cdk-visually-hidden">{{ excludedA11yLabel }}</span>
          <span class="chip-label">{{ item.label }}</span>
        </del>
      }
      <span class="cdk-visually-hidden">{{ removeBtnAriaLabel }}</span>
      <div class="lock-button-wrapper">
        @if (lockable) {
          <button
            mat-icon-button
            class="lock-button"
            [attr.aria-label]="item.label + ' ' + lockBtnAriaLabel"
            [class.is-visible]="hoveredItem === index"
            (click)="handleLock(item)"
            (focus)="focusedItem = index"
            (blur)="focusedItem = null"
            (mouseenter)="item.lockHover = item.locked"
            (mouseleave)="item.lockHover = !item.locked"
            >
            <mat-icon muloSvgViewbox [svgIcon]="item.lockHover ? 'lock-open-outline' : 'lock-outline'"></mat-icon>
          </button>
        }
      </div>
      <button
        matChipRemove
        muloFacetChip
        [attr.aria-labelledby]="chip.id"
        (focus)="focusedItem = index"
        (blur)="focusedItem = null"
        >
        <mat-icon muloSvgViewbox svgIcon="close"></mat-icon>
      </button>
    </mat-chip>
  </ng-template>
  @if (showActions) {
    <div class="actions">
      @if (lockable) {
        <button mat-button class="has-icon" (click)="handleRememberAllClick()" [disabled]="disabled">
          <mat-icon svgIcon="lock-outline"></mat-icon>
          <span>{{ rememberAllLabel }}</span>
        </button>
      }
      <button mat-button class="has-icon" (click)="handleResetClick()" [disabled]="disabled">
        <mat-icon class="mat-icon-rtl-mirror" svgIcon="refresh"></mat-icon>
        <span>{{ resetLabel }}</span>
      </button>
    </div>
  }
</mat-chip-set>
