import {
    Component,
    computed,
    ElementRef,
    inject,
    Input,
    model,
    OnInit,
    signal,
    ViewChild,
} from '@angular/core';
import {
    MatFormField,
    MatFormFieldControl,
    MatFormFieldModule,
    MatLabel,
} from '@angular/material/form-field';
import { FormControl, FormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import {
    MatAutocompleteModule,
    MatAutocompleteSelectedEvent,
} from '@angular/material/autocomplete';
import { MatIcon } from '@angular/material/icon';
import {
    AriaProgressBarDirective,
    DragDirective,
    DropListA11yDirective,
    DropListDirective,
    InputExpanderDirective,
} from '@exl-ng/mulo-common';
import { NgClass } from '@angular/common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { LiveAnnouncer } from '@angular/cdk/a11y';
import { MatIconButton } from '@angular/material/button';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ExternalOrganizationService } from '../../core/external-organization.service';
import { InternalOrganizationService } from '../internal-organization-input/internal-organization.service';
import { ExternalOrganization } from '../../shared/interfaces/external-organization.interface';
import { DragDropModule, moveItemInArray } from '@angular/cdk/drag-drop';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { ConfirmDialogComponent } from '../dialogs/confirm-dialog/confirm-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { MatOptionSelectionChange } from '@angular/material/core';

@Component({
    selector: 'esp-multiple-affiliations',
    standalone: true,
    imports: [
        MatChipsModule,
        MatAutocompleteModule,
        FormsModule,
        MatIcon,
        MatFormField,
        InputExpanderDirective,
        NgClass,
        AriaProgressBarDirective,
        MatLabel,
        MatProgressBar,
        MatIconButton,
        TranslateModule,
        MatFormFieldModule,
        DragDirective,
        DropListDirective,
        DragDropModule,
        DropListA11yDirective,
    ],
    templateUrl: './multiple-affiliations.component.html',
    styleUrl: './multiple-affiliations.component.scss',
    providers: [
        {
            provide: MatFormFieldControl,
            useExisting: MultipleAffiliationsComponent,
        },
    ],
})
export class MultipleAffiliationsComponent implements OnInit {
    extOrgSvc = inject(ExternalOrganizationService);
    private intOrgSvc = inject(InternalOrganizationService);
    configSvc = inject(ConfigurationHandlerService);
    confirmDialog = inject(MatDialog);
    private _t = inject(TranslateService);

    @Input() label: string = 'Affiliations';
    @Input() isComfortablyReadable = false;
    @Input() orgNameListCtrl: FormControl<string[]>;
    @Input() orgCodeListCtrl: FormControl<string[]>;
    @Input() newOrganizationType: string =
        'esploro.organization.types.education';

    @ViewChild('orgInput') input: ElementRef<HTMLInputElement>;
    organizationSearchInProgress = signal(false);

    readonly currentOrgName = model('');
    readonly extOrgs = signal<ExternalOrganization[]>([]);
    noValuesFound = false;

    readonly filteredIntOrgs = computed(() => {
        const name = this.currentOrgName().toLowerCase();
        return name
            ? this.intOrgSvc.departments?.filter((org) =>
                  org.organizationName.toLowerCase().includes(name),
              ) ?? []
            : this.intOrgSvc.departments?.slice() ?? [];
    });
    readonly filteredExtOrgs = computed(() => {
        const name = this.currentOrgName().toLowerCase();
        return name
            ? this.extOrgs().filter((org) =>
                  org.name.toLowerCase().includes(name),
              )
            : this.extOrgs().slice();
    });
    readonly canAddOrg = computed(
        () =>
            this.currentOrgName() &&
            !this.organizationSearchInProgress() &&
            this.configSvc.getResearcherProfileGeneralConfiguration()
                .allowToAddNewOrganiztion,
    );

    readonly announcer = inject(LiveAnnouncer);
    a11yEditing = false;

    ngOnInit(): void {
        this.listenToExternalOrganizationInput();
    }

    listenToExternalOrganizationInput() {
        this.currentOrgName.subscribe((value) => {
            if (value && value.length > 0) {
                this.organizationSearchInProgress.set(true);
                const orgNameCleaned = value.trim().replace(/\s\s+/g, ' ');
                this.getExternalOrganizationAutoComplete(orgNameCleaned);
            }
        });
    }

    getExternalOrganizationAutoComplete(value) {
        this.extOrgSvc.getExternalOrganizationAutoComplete(value).subscribe({
            next: (data: ExternalOrganization[]) => {
                if (data) {
                    const newOrgs = [...this.extOrgs()];
                    data.forEach((org) => {
                        if (!this.extOrgs().some((o) => o.code === org.code)) {
                            newOrgs.push(org);
                        }
                    });
                    newOrgs.sort((a, b) => a.name.localeCompare(b.name));
                    this.extOrgs.set(newOrgs);
                }
                this.organizationSearchInProgress.set(false);
                this.filteredExtOrgs();
            },
            error: (error) => {
                console.error(error);
                this.organizationSearchInProgress.set(false);
            },
        });
    }

    remove(orgCode: string): void {
        const tempOrgNames = this.orgNameListCtrl.getRawValue();
        const tempOrgCodes = this.orgCodeListCtrl.getRawValue();
        const index = tempOrgCodes.indexOf(orgCode);
        if (index >= 0) {
            const orgName = tempOrgNames[index];
            tempOrgCodes.splice(index, 1);
            tempOrgNames.splice(index, 1);

            this.announcer.announce(
                this._t.instant('research.aria.description.announce.removed', {
                    value: orgName,
                }),
            );
        }
    }

    selected(event: MatAutocompleteSelectedEvent): void {
        if (event.option.id !== 'addNew') {
            const names = this.orgNameListCtrl.getRawValue();
            const codes = this.orgCodeListCtrl.getRawValue();
            this.orgCodeListCtrl.setValue([...codes, event.option.value]);
            this.orgNameListCtrl.setValue([...names, event.option.viewValue]);

            this.currentOrgName.set('');
            this.input.nativeElement.value = '';
            event.option.deselect();
        }
    }

    drop(event) {
        moveItemInArray(
            this.orgNameListCtrl.getRawValue(),
            event.previousIndex,
            event.currentIndex,
        );
        moveItemInArray(
            this.orgCodeListCtrl.getRawValue(),
            event.previousIndex,
            event.currentIndex,
        );
    }

    onA11yEditing(isEditing: boolean) {
        this.a11yEditing = isEditing;
    }

    addNewExtOrg(aff: string, event: MatOptionSelectionChange) {
        const affiliation = event.source.value.trim().replace(/\s\s+/g, ' ');
        const dialog = this.openConfirmationDialog();
        dialog.afterClosed().subscribe((result) => {
            if (result === 'ok') {
                this.addNewExternalOrganizationSelect(affiliation);
                this.currentOrgName.set('');
                this.input.nativeElement.value = '';
            }
            event.source.deselect(false);
        });
    }
    openConfirmationDialog() {
        return this.confirmDialog.open(ConfirmDialogComponent, {
            maxWidth: '500px',
            data: {
                title: 'research.popup.create.external.organization.title',
                message: 'research.popup.create.external.organization.message',
                actions: {
                    confirm: 'research.popup.create.external.organization.save',
                    cancel: 'research.popup.create.external.organization.cancel',
                },
                actionFocus: 'confirm',
            },
        });
    }
    addNewExternalOrganizationSelect(affiliation: string) {
        this.noValuesFound = false;

        this.extOrgSvc
            .addExternalOrganization(affiliation, this.newOrganizationType)
            .subscribe({
                next: (data: ExternalOrganization) => {
                    if (data) {
                        const newOrgs = [...this.extOrgs()];
                        if (!this.extOrgs().some((o) => o.code === data.code)) {
                            newOrgs.push(data);
                        }
                        newOrgs.sort((a, b) => a.name.localeCompare(b.name));
                        this.extOrgs.set(newOrgs);

                        this.orgNameListCtrl.getRawValue().push(data.name);
                        this.orgCodeListCtrl.getRawValue().push(data.code);
                    }
                    this.organizationSearchInProgress.set(false);
                },
                error: (error) => {
                    console.error(error);
                    this.organizationSearchInProgress.set(false);
                },
            });
    }
}
