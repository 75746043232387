import {
  Component,
  Input,
  HostBinding,
  ViewChild,
  ElementRef,
  OnDestroy,
  DoCheck,
  EventEmitter,
  Output,
} from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { AriaDescriberDirective } from '../../directives/aria-describer.directive';


/**
 * Expandable container enabling cropping any type of content.
 * Dynamically maintains visible area on different screen sizes.
 */
@Component({
    selector: 'mulo-expandable',
    templateUrl: './expandable.component.html',
    styleUrls: ['./expandable.component.scss'],
    host: {
        class: 'mulo-expandable',
        '[style]': 'styleString',
    },
    standalone: true,
    imports: [
    MatButton,
    AriaDescriberDirective,
    MatIcon
],
})
export class ExpandableComponent implements OnDestroy, DoCheck {
  @HostBinding('class.is-expandable') expandable = false;
  @HostBinding('class.is-expanded') expanded: boolean;
  @HostBinding('class.is-expanding') expanding: boolean;
  @HostBinding('class.is-collapsing') collapsing: boolean;

  @Input() ratio = 7;
  @Input() buttonColor: 'primary' | 'accent' | 'default' = 'primary';
  @Input() expandLabel = 'Expand';
  @Input() collapseLabel = 'Collapse';
  @Input() hideButtonOnExpand = false;
  @Input() buttonOverlap = true;
  @Input() buttonStyle: 'icon' | 'text' | 'full' = 'full';
  @Input() buttonPosition: 'right' | 'left' = 'right';
  @Input() buttonDisabled = false;
  @Input() descriptionLabel = 'For display interface';

  /** Event emitted every time the Expandable is closed. */
  @Output() closed = new EventEmitter<void>();
  /** Event emitted every time the Expandable is opened. */
  @Output() opened = new EventEmitter<void>();
  /**
   * Emits whenever the expanded state of the Expandable changes.
   * Primarily used to facilitate two-way binding.
   */
  @Output() expandedChange = new EventEmitter<boolean>();

  @ViewChild('content', { read: ElementRef }) content: ElementRef;
  @ViewChild(MatButton, { static: true }) toggleButton: MatButton;
  buttonIcon = { down: 'chevron-down', up: 'chevron-up' };
  buttonHeight = 36;
  defaultHeight = 70;
  multiplier = 1.75;
  base = 10000;
  align = 3;

  viewChecked = false;

  calcHeight = 70;
  contentHeight = -1;
  transitionTimeoutID;

  get styleString() {
    return `
      --button-height: ${this.buttonHeight}px;
      --content-height: ${this.contentHeight}px;
      --calc-height: ${this.calcHeight}px;
    `;
  }

  constructor() {}

  ngDoCheck() {
    const el = this.content?.nativeElement;
    const calcHeight = this.calc(el?.offsetWidth);
    this.expandable = el?.offsetHeight >= calcHeight;
    this.calcHeight = calcHeight;
    this.contentHeight = this.content?.nativeElement?.scrollHeight || -1;

    if (this.toggleButton) {
      this.buttonHeight =
        this.toggleButton._elementRef.nativeElement.offsetHeight;
    }
  }

  ngOnDestroy() {}

  calc(width) {
    const area = this.multiplier * this.base * this.ratio;
    return Math.ceil(area / width) + this.align;
  }

  public toggle() {
    const willBeExpanded = this.expanded || this.expanding ? false : true;
    if (this.transitionTimeoutID) {
      clearTimeout(this.transitionTimeoutID);
    }

    if (willBeExpanded) {
      this.expanding = true;
      this.collapsing = false;
      this.transitionTimeoutID = setTimeout(() => {
        // after the animation
        this.expanded = true;
        this.expanding = false;
        this.expandedChange.emit(true);
        this.opened.emit();
      }, 500);
    } else {
      this.expanded = false;
      this.expanding = false;
      // use the collapsing class quickly to trigger the smooth transition
      this.collapsing = true;
      this.transitionTimeoutID = setTimeout(() => {
        this.collapsing = false;
        this.expandedChange.emit(false);
        this.closed.emit();
      }, 500);
    }
  }
}
