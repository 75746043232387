import { Injectable, Pipe, PipeTransform, inject } from '@angular/core';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { LockConfigurationMode } from '../interfaces/researcher-profile-lock-fields-configuration';

@Injectable({
    providedIn: 'root',
})
@Pipe({
    name: 'disableByConfiguration',
    standalone: true
})
export class DisableByConfigurationPipe implements PipeTransform {
    private configurationHandlerService = inject(ConfigurationHandlerService);


    transform(value: string): string {
        return this.hideIfFiledIsLocked(value);
    }

    private hideIfFiledIsLocked(fieldName: string) {
        const researcherProfileLockFieldsConfiguration =
            this.configurationHandlerService.getResearcherProfileLockFieldsConfiguration();

        return (
            researcherProfileLockFieldsConfiguration?.lockConfigurationMode ===
                LockConfigurationMode.ALL_LOCKED ||
            (researcherProfileLockFieldsConfiguration?.lockConfigurationMode ===
                LockConfigurationMode.SELECTED_LOCKED &&
                researcherProfileLockFieldsConfiguration?.profileFieldsWrapper[
                    fieldName
                ])
        );
    }
}
