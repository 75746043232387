<div class="grant-search-form">
  <mat-form-field
    class="is-comfortably-readable"
    [muloInputExpander]="searchTerm.value"
    [muloInputExpanderBuffer]="7"
    >
    <input #grantInput [formControl]="searchTerm" [matAutocomplete]="auto" autocomplete="off" matInput />
    <mat-label>{{ 'funding.lookup' | customTranslate }}</mat-label>
    @if (searchInProgress) {
      <mat-progress-bar color="primary" mode="indeterminate"></mat-progress-bar>
    }
    @for (error of searchTerm.errors | keyvalue; track error.key) {
      <mat-error>{{ error.value | translate }}</mat-error>
    }
  </mat-form-field>
  @if (showAddBtn) {
    <span class="add-btn">
      <span class="margin-left-sm margin-right-xs">{{ 'funding.or' | customTranslate }}</span>
      <button #addNewBtn (click)="addBtnClick.emit($event)" color="primary" mat-button type="button">
        {{ 'funding.add' | customTranslate }}
      </button>
    </span>
  }
</div>

<mat-autocomplete #auto="matAutocomplete" class="is-wide">
  <!-- no results -->
  @if (!searchInProgress && noResultsMessage) {
    <mat-option class="no-results-option" disabled>
      <span role="alert" [innerHTML]="noResultsMessage | translate"></span>
    </mat-option>
  }

  @if (filteredSuggestedGrant.length > 0) {
    <mat-optgroup [label]="'research.grants.suggestions' | customTranslate">
      @for (suggested of filteredSuggestedGrant; track suggested) {
        <mat-option
          (onSelectionChange)="handleGrantSelect(suggested, $event)"
          >
          <div class="autocomplete-option-multiline">
            <span
                    [innerHTML]="
                        suggested.grantName + (suggested?.grantId ? ' (' + suggested.grantId + ')' : '')
                            | highlight: searchTerm.value
                    "
              class="semibold"
            ></span>
            @if (!suggested.funderAgency) {
              <small class="italics">{{
                suggested.funderCode | defaultTranslate: { default: suggested.funderName }
              }}</small>
            }
            @if (suggested.funderAgency) {
              <small class="italics"
                >{{ suggested.funderCode | defaultTranslate: { default: suggested.funderName } }}
                ,
                {{ suggested.funderAgencyCode | defaultTranslate: { default: suggested.funderAgency } }}</small
                >
              }
            </div>
          </mat-option>
        }
      </mat-optgroup>
    }
    @if (grantsFromPivot.length > 0) {
      <mat-optgroup label="{{ 'research.grants.pivot' | customTranslate }}">
        @for (suggested of grantsFromPivot; track suggested) {
          <mat-option
            (onSelectionChange)="onPivotGrantSelect(suggested, $event)"
            >
            <div class="autocomplete-option-multiline">
              <span
                    [innerHTML]="
                        suggested.grantName + (suggested?.grantId ? ' (' + suggested.grantId + ')' : '')
                            | highlight: searchTerm.value
                    "
                class="semibold"
              ></span>
            </div>
          </mat-option>
        }
      </mat-optgroup>
    }

    @if (filteredGrants.length > 0) {
      <mat-optgroup label="{{ 'research.grants.esploro' | customTranslate }}">
        @for (result of filteredGrants; track result) {
          <mat-option (onSelectionChange)="handleGrantSelect(result, $event)">
            <div class="autocomplete-option-multiline">
              <span
                    [innerHTML]="
                        result.grantName + (result?.grantId ? ' (' + result.grantId + ')' : '')
                            | highlight: searchTerm.value
                    "
                class="semibold"
              ></span>
              <small class="italics"
                >{{ result.funderCode | defaultTranslate: { default: result.funderName } }}
                @if (result.funderAgency) {
                  {{ ', ' + (result.funderAgencyCode | defaultTranslate: { default: result.funderAgency }) }}
                }
              </small>
            </div>
          </mat-option>
        }
      </mat-optgroup>
    }
  </mat-autocomplete>
