<div
  class="section-anchor"
  [attr.id]="underscoreStr(title)"
  [attr.aria-describedby]="title ? 'section-title-' + underscoreStr(title) : null"
></div>

@if (imageFullWidth && title) {
  <h2 id="{{ 'section-title-' + underscoreStr(title) }}" class="section-title">
    {{ title }}
  </h2>
}
@if (imageSrc) {
  <img
    class="section-img"
    [class.img-on-right]="imageOnRight"
    [class.full-width]="imageFullWidth"
    [src]="imageSrc"
    [alt]="imageAlt"
    />
}
@if (!imageFullWidth) {
  <div class="section-content">
    @if (title) {
      <h2 id="{{ 'section-title-' + underscoreStr(title) }}" class="section-title">
        {{ title }}
      </h2>
    }
    <ng-content></ng-content>
  </div>
}
<div class="page-highlight-overlay" [class.is-active]="highlightActive$ | async">
  <div matRipple #ripple></div>
</div>
