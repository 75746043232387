import { Component, OnDestroy, OnInit, Input, inject } from '@angular/core';
import { DepositForm } from '../../../../shared/configurations/deposit-form.constant';
import { DepositPublicationDetailsService } from '../deposit-publication-details.service';
import { EsploroRecord } from '../../../../shared/interfaces/esploro-record.model';
import { CZSearchResult } from '../../../../shared/interfaces/cz-search-result.interface';
import { DepositFormDataService } from '../../../deposit-form-data.service';
import { StatusUtils } from '../../../../shared/utils/status.utils';
import { AutoPopulateKeys } from '../../../../shared/configurations/auto-populate-keys.config';
import { Subject, distinctUntilChanged, debounceTime, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { EspMatAutocompleteA11yDirective } from '../../../../shared/directives/a11y/mat-autocomplete-extension.directive';
import { AriaProgressBarDirective } from '@exl-ng/mulo-common';
import { MatProgressBar } from '@angular/material/progress-bar';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix, MatError, MatHint } from '@angular/material/form-field';


@Component({
    selector: 'esp-deposit-publication-details-journal-article',
    templateUrl: './deposit-publication-details-journal-article.component.html',
    styleUrls: ['./deposit-publication-details-journal-article.component.scss'],
    standalone: true,
    imports: [
    MatFormField,
    MatInput,
    FormsModule,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatLabel,
    MatIcon,
    MatPrefix,
    MatTooltip,
    MatProgressBar,
    AriaProgressBarDirective,
    MatAutocomplete,
    EspMatAutocompleteA11yDirective,
    MatOption,
    MatError,
    MatHint,
    TranslateModule,
    CustomTranslatePipe
],
})
export class DepositPublicationDetailsJournalArticleComponent
  implements OnInit, OnDestroy {
  private depositPublicationDetailsService = inject(DepositPublicationDetailsService);
  depositFormDataService = inject(DepositFormDataService);

  @Input() status;

  searchIssnInProgress = false;
  searchTitleInProgress = false;
  noValuesFound: boolean;
  czSearchResults: CZSearchResult[] = [];

  private issnDestroy = new Subject<void>();
  private titleDestroy = new Subject<void>();
  private volumeDestroy = new Subject<void>();
  private issueDestroy = new Subject<void>();
  private pagesDestroy = new Subject<void>();
  private doiDestroy = new Subject<void>();
  private pmidDestroy = new Subject<void>();

  isPosted = false;
  displayVolume = false;
  displayIssue = false;
  displayPages = false;
  displayPmid = false;

  ngOnInit() {
    this.listenToIssnInput();
    this.listenToTitleInput();
    this.onChangesVolume();
    this.onChangesIssue();
    this.onChangesPages();
    this.onChangesPmid();
    this.statusOnChanges(this.status);
  }

  onChangesPmid(): void {
    this.depositFormDataService.pmid.valueChanges
      .pipe(takeUntil(this.pmidDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PMID
          );
        }
      });
  }

  onChangesVolume(): void {
    this.depositFormDataService.isPartOfVolume.valueChanges
      .pipe(takeUntil(this.volumeDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_VOLUME
          );
        }
      });
  }

  onChangesIssue(): void {
    this.depositFormDataService.isPartOfIssue.valueChanges
      .pipe(takeUntil(this.issueDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_ISSUE
          );
        }
      });
  }

  onChangesPages(): void {
    this.depositFormDataService.isPartOfPages.valueChanges
      .pipe(takeUntil(this.pagesDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_PAGES
          );
        }
      });
  }

  listenToIssnInput() {
    this.depositFormDataService.isPartOfIssn.valueChanges
      .pipe(
        debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.issnDestroy)
      )
      .subscribe((value) => {
        if (
          this.depositPublicationDetailsService.lastPartOfIssn !==
          value &&
          !this.depositFormDataService.startPopulating
        ) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.IS_PART_OF_ISSN
          );
        }
        if (
          value &&
          this.depositPublicationDetailsService.lastPartOfIssn !==
          value &&
          this.depositPublicationDetailsService.issnRegex.test(
            value.trim()
          )
        ) {
          this.searchIssnInProgress = true;
          this.issnEnrichment(value.trim());
        }
      });
  }

  listenToTitleInput() {
    this.depositFormDataService.relationTitle.valueChanges
      .pipe(
        debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
        distinctUntilChanged(),
        takeUntil(this.titleDestroy)
      )
      .subscribe((value) => {
        this.noValuesFound = false;
        if (
          this.depositPublicationDetailsService.lastPartOfTitle !==
          value &&
          !this.depositFormDataService.startPopulating
        ) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.RELATION_TITLE
          );
        }
        if (
          value &&
          this.depositPublicationDetailsService.lastPartOfTitle !==
          value &&
          value.length >= DepositForm.MIN_INPUT_LENGTH
        ) {
          this.searchTitleInProgress = true;
          this.journalNameEnrichment(value);
        }
      });
  }

  issnEnrichment(value) {
    this.depositPublicationDetailsService.enrichFromIssn(value).subscribe(
      (data) => {
        this.successAfterIssnSearch(data as EsploroRecord);
      },
      (error) => {
        this.searchIssnInProgress = false;
      }
    );
  }

  journalNameEnrichment(value) {
    this.depositPublicationDetailsService
      .enrichFromJournalName(value)
      .subscribe(
        (data) => {
          this.searchTitleInProgress = false;
          if (data) {
            this.czSearchResults = data as CZSearchResult[];
            this.czSearchResults = this.czSearchResults.filter(
              (result) => result.issn
            );
          } else {
            this.noDataFound();
          }
        },
        (error) => {
          this.searchTitleInProgress = false;
          this.noDataFound();
        }
      );
  }

  noDataFound() {
    this.noValuesFound = true;
    this.czSearchResults = [];
  }

  successAfterIssnSearch(data: EsploroRecord) {
    this.searchIssnInProgress = false;
    this.depositFormDataService.populatePartOfFormFromIssn(data, true);
  }

  statusOnChanges(data) {
    this.status = data;
    this.isPosted = StatusUtils.isPosted(data);
    this.displayVolume = StatusUtils.displayVolume(data);
    this.displayIssue = StatusUtils.displayIssue(data);
    this.displayPages = StatusUtils.displayPages(data);
    this.displayPmid = StatusUtils.displayPmid(data);
  }

  onJournalNameSelect(result: CZSearchResult) {
    this.depositFormDataService.populatePartOfFormFromTitle(result);
  }

  isAutoPopulated(key) {
    return this.depositFormDataService.isKeyAutoPopulated(key);
  }

  ngOnDestroy() {
    if (!this.issnDestroy.isStopped) {
      this.issnDestroy.next();
      //unsubscribe from the subject itself
      this.issnDestroy.unsubscribe();
    }
    if (!this.titleDestroy.isStopped) {
      this.titleDestroy.next();
      //unsubscribe from the subject itself
      this.titleDestroy.unsubscribe();
    }
    if (!this.volumeDestroy.isStopped) {
      this.volumeDestroy.next();
      //unsubscribe from the subject itself
      this.volumeDestroy.unsubscribe();
    }
    if (!this.issueDestroy.isStopped) {
      this.issueDestroy.next();
      //unsubscribe from the subject itself
      this.issueDestroy.unsubscribe();
    }
    if (!this.pagesDestroy.isStopped) {
      this.pagesDestroy.next();
      //unsubscribe from the subject itself
      this.pagesDestroy.unsubscribe();
    }
    if (!this.doiDestroy.isStopped) {
      this.doiDestroy.next();
      this.doiDestroy.unsubscribe();
    }
    if (!this.pmidDestroy.isStopped) {
      this.pmidDestroy.next();
      this.pmidDestroy.unsubscribe();
    }
  }
}
