import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-stepper-back-button',
    templateUrl: './deposit-stepper-back-button.component.html',
    styleUrls: ['./deposit-stepper-back-button.component.scss'],
    host: { 'class': 'esp-deposit-stepper-back-button' },
    standalone: true,
    imports: [MatButton, NgClass, MatIcon, TranslateModule]
})
export class DepositStepperBackButtonComponent implements OnInit {

    @Input() disabled = true;
    @Input() rounded = false;
    @Output() back: EventEmitter<any> = new EventEmitter();

    constructor() { }

    ngOnInit() {
    }

    onButtonClick(){
        this.back.emit(null);
    }

}
