import { Component, OnInit, Input, HostBinding, Output,EventEmitter } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';

import { ChatBubbleComponent } from '@exl-ng/mulo-common';

@Component({
    selector: 'exl-citation-brief-notification',
    templateUrl: './citation-brief-notification.component.html',
    styleUrls: ['./citation-brief-notification.component.scss'],
    host: { 'class': 'exl-citation-brief-notification' },
    standalone: true,
    imports: [ChatBubbleComponent, MatButton, MatIcon, TranslateModule]
})
export class CitationBriefNotificationComponent implements OnInit {

    @Input() notification: any;
    @Input() color: 'accent' | 'primary' | 'default' = 'accent';
    @Input() icon = 'alert-decagram';
    @Input() time = new Date();
    @Input() actions: Array<any> = new Array();

    @Output() action: EventEmitter<any> = new EventEmitter();

    @HostBinding('class') get colorClass(){
        return  'exl-' + this.color;
    }

    constructor() { }

    ngOnInit() {
    }

    onActionClick(action){
        this.action.emit(action);
    }



}
