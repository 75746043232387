<mat-form-field [class.is-autopopulated]="isAutoPopulated" class="is-comfortably-readable">
  <mat-label>{{placeholder}}</mat-label>
  <input matInput
    [formControl]="depositFormDataService.publisher">
  @if (isAutoPopulated) {
    <mat-icon matPrefix svgIcon="auto-populate"
      matTooltip="{{'research.auto.populate.tooltip' | customTranslate}}">
    </mat-icon>
  }
</mat-form-field>
