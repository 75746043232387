import {Component, OnInit, Input, HostBinding} from '@angular/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { AvatarComponent } from '@exl-ng/mulo-common';

import { MatButton } from '@angular/material/button';

@Component({
    selector: 'exl-user-menu-button',
    templateUrl: './user-menu-button.component.html',
    styleUrls: ['./user-menu-button.component.scss'],
    host: { 'class': 'exl-user-menu-button' },
    standalone: true,
    imports: [MatButton, MatMenuTrigger, AvatarComponent, MatIcon, TranslateModule]
})
export class UserMenuButtonComponent implements OnInit {

    @Input() avatar: string;
    @Input() userName: string;
    @Input() userInitials: string;
    @Input() proxyAvatar: string;
    @Input() proxyName: string;
    @Input() proxyInitials: string;
    @Input() userMatMenu: MatMenuPanel;

    @HostBinding('class.has-proxy') get hasProxy(){
        return this.proxyName ? this.proxyName.length : '';
    }

    constructor() { }

    ngOnInit() {
    }

}
