import {
  Component,
  ContentChildren,
  TemplateRef,
  Input,
  Output,
  EventEmitter,
} from '@angular/core';
import {
  CdkDragDrop,
  moveItemInArray,
  CdkDropListGroup,
  CdkDropList,
  CdkDrag,
} from '@angular/cdk/drag-drop';

import {
  WidthInAnimation,
  WidthOutAnimation,
} from '../../animations/width.animation';
import { DragDirective } from './drop-list.directive';
import { DropListA11yDirective } from '../../directives/drop-list-a11y.directive';
import { MatChipListbox, MatChipOption } from '@angular/material/chips';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'mulo-drop-list',
  templateUrl: './drop-list.component.html',
  styleUrls: ['./drop-list.component.scss'],
  host: { class: 'mulo-drop-list' },
  animations: [WidthInAnimation, WidthOutAnimation],
  standalone: true,
  imports: [
    MatChipListbox,
    CdkDropListGroup,
    DropListA11yDirective,
    CdkDropList,
    MatChipOption,
    CdkDrag,
    NgTemplateOutlet
],
})
export class DropListComponent {
  @Input() listData: any[] = [];
  @Input('type') cmpType: string;

  @Output() changed = new EventEmitter<any[]>();

  @ContentChildren(DragDirective, { read: TemplateRef })
  muloDragTemplates;

  pickedItem: number = 0;
  targetContainer: number = 0;
  lastEntered: number = 0;
  sortBuffer: 1 | 0;
  dragging: boolean = false;

  constructor() {}

  trackItem(index: number, item: any) {
    // forces the "array changed" analysis to happen after we've completed our changes, not during
    return index;
  }

  onA11yMove(moveEvent) {
    moveItemInArray(
      this.listData,
      moveEvent.previousIndex,
      moveEvent.currentIndex,
    );
    this.pickedItem = moveEvent.currentIndex;
  }

  onA11yEditing(editingIdx) {
    this.dragging = editingIdx != null;
    this.pickedItem = editingIdx;
  }

  picked(idx: number) {
    this.dragging = true;
    this.pickedItem = idx;
    this.targetContainer = idx;
  }

  /**
   * this guard handles moving to a neighboring container
   * (the placeholder doesn't move so we don't want to confuse users)
   * sortBuffer check is to handle flinging (sortBuffer stays null)
   */
  entered(idx: number) {
    if (this.sortBuffer === null || Math.abs(this.targetContainer - idx) > 1) {
      this.targetContainer = idx;
    }
    this.lastEntered = idx;
  }

  sorted(ev, idx) {
    if (ev.currentIndex == ev.previousIndex) {
      // put pickedItem calculation here too to avoid bug when grabbing remove btn
      this.pickedItem = idx;
      this.targetContainer = idx;
    } else {
      // when we sort: if we're moving forward in the array, we have 1 too many
      this.sortBuffer = this.lastEntered > this.pickedItem ? 0 : 1;
      this.targetContainer = idx - ev.previousIndex + this.sortBuffer;
    }
  }
  released(idx: number) {
    this.dragging = false;
  }

  reset() {
    this.targetContainer = 0;
    this.pickedItem = null;
  }

  drop(event: CdkDragDrop<string[]>, idx: number) {
    if (event.previousContainer !== event.container) {
      moveItemInArray(this.listData, this.pickedItem, this.targetContainer);
      this.reset();

      // this.changed.emit(this.listData)
    }
  }
}
