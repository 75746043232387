<div class="paging-container">
  @if (!(autoHide && p.pages.length <= 1)) {
    <nav role="navigation" [attr.aria-label]="screenReaderPaginationLabel">
      @if (directionLinks && !p.isFirstPage()) {
        <button
          mat-button
          class="go-dir-btn"
          (click)="p.previous()"
          (keyup.enter)="p.previous()"
          [attr.aria-label]="previousLabel + ' ' + screenReaderPageLabel"
          [class.is-square]="icons"
          >
          @if (!icons) {
            {{ previousLabel }}
            <span class="cdk-visually-hidden">{{ screenReaderPageLabel }}</span>
          } @else {
            <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
          }
        </button>
      }
      @for (page of p.pages; track page; let i = $index) {
        @if (!hideLastPage || page.value !== p.getLastPage() || p.pages[p.pages.length - 2].label !== '...') {
          <button
            mat-button
            [class.is-current]="p.getCurrent() === page.value"
            [class.is-ellipsis]="page.label === '...'"
            [attr.aria-label]="page.label === '...' ? _parseJumpLabel(page.value) : null"
            class="is-square"
            (click)="p.setCurrent(page.value)"
            [disabled]="p.getCurrent() === page.value"
            (keyup.enter)="p.setCurrent(page.value)"
            >
            <span class="cdk-visually-hidden">{{ screenReaderPageLabel }} </span>
            {{ page.label === '...' ? page.label : (page.label | number : '') }}
          </button>
        }
      }
      @if (directionLinks && !p.isLastPage()) {
        <button
          mat-button
          class="go-dir-btn"
          (keyup.enter)="p.next()"
          (click)="p.next()"
          [attr.aria-label]="nextLabel + ' ' + screenReaderPageLabel"
          [class.is-square]="icons"
          >
          @if (!icons) {
            {{ nextLabel }}
            <span class="cdk-visually-hidden">{{ screenReaderPageLabel }}</span>
          } @else {
            <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-right"></mat-icon>
          }
        </button>
      }
    </nav>
  }
</div>
