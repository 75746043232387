import { Component, Input } from '@angular/core';
import { MatMenuPanel, MatMenuTrigger } from '@angular/material/menu';
import { BadgeComponent } from '../badge/badge.component';

import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';

@Component({
    selector: 'mulo-notify-bell',
    templateUrl: './notify-bell.component.html',
    styleUrls: ['./notify-bell.component.scss'],
    standalone: true,
    imports: [
    MatIconButton,
    MatMenuTrigger,
    MatIcon,
    SvgViewboxDirective,
    BadgeComponent
],
})
export class NotifyBellComponent {
  @Input() ariaLabel: string;
  @Input() triggerFor: MatMenuPanel;
  @Input() count: number;

  constructor() {
    this.ariaLabel = `Notifications (${this.count})`;
  }
}
