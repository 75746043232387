import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { Subscription } from 'rxjs';

import { FileDropService } from './file-drop.service';

import { ScaleInOutAnimation } from '../../animations/scale-in-out.animation';
import { AlertDialogComponent } from '../../dialogs/alert-dialog/alert-dialog.component';
import labels from './file-drop.labels';
import { ExtendedFile } from '../../models/extended-file.model';
import {
  MatList,
  MatListSubheaderCssMatStyler,
  MatListItem,
} from '@angular/material/list';
import { MatIcon } from '@angular/material/icon';
import { MatButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { FileDropDirective } from './file-drop.directive';

@Component({
  selector: 'mulo-file-drop',
  templateUrl: './file-drop.component.html',
  styleUrls: ['./file-drop.component.scss'],
  host: { class: 'mulo-file-drop', tabIndex: '0' },
  animations: [ScaleInOutAnimation],
  standalone: true,
  imports: [
    FileDropDirective,
    NgClass,
    MatButton,
    MatIcon,
    MatList,
    MatListSubheaderCssMatStyler,
    MatListItem
],
})
export class FileDropComponent implements OnInit, OnChanges, OnDestroy {
  dialog = inject(MatDialog);
  private fileDropService = inject(FileDropService);

  @Input() allowedFileTypes: string[] | string;
  @Input() multiple: boolean = true;
  @Input() minimal: boolean = false;
  @Input() hidden: boolean = false;
  @Output() output: EventEmitter<ExtendedFile[]> = new EventEmitter();
  @Output()
  actualFilesOutput: EventEmitter<ExtendedFile[]> = new EventEmitter();

  @ViewChild('fileRequester') fileRequester;

  @Input() multiFilesDropLabel: string = labels.dropzone.multiple;
  @Input() singleFileDropLabel: string = labels.dropzone.single;
  @Input() multiDropActionLabel: string = labels.action.multiple;
  @Input() singleDropActionLabel: string = labels.action.single;
  @Input() multiSelectBtnLabel: string = labels.select.multiple;
  @Input() singleSelectBtnLabel: string = labels.select.single;
  @Input() fileDropLimitMsg: string = labels.limit.fileDrop;
  @Input() typeChangedLimitMsg: string = labels.limit.typeChanged;
  @Input() confirmFileReplaceMsg: string = labels.confirmReplace;
  @Input() invalidFilesHeaderLabel: string = labels.invalidFilesHeader;

  private allPanelsToggled: boolean = false;
  private currentDroppedArray: number;
  public dragOverActive: boolean = false;
  public invalidFiles: ExtendedFile[] = [];
  public fileless: boolean = false;
  public fileList: ExtendedFile[] = [];

  @Input() showMultiFileWarning: boolean = false;
  @Input() fileLimitWarningTitle: string = labels.limit.warningTitle;
  @Input() fileLimitWarningMsg: string = labels.limit.warningMsg;
  @Input() fileLimitWarningConfirm: string = labels.limit.warningConfirm;

  private subscriptions: Subscription[] = [];

  // private uploadBlockWarning: string;
  // private selectedFiles: any = [];
  // private urls: any = [];
  // private url;
  // private uploadProgress: number = 0;
  allowedExtensions: string[];
  // private filesDropWarning: string;
  // private fileMetadaEditable: boolean = false;
  // private countdown;

  constructor() {
    this.subscriptions.push(
      this.fileDropService.filesChangeEmitter.subscribe((data: any) => {
        this.onFilesChange(data);
      }),
      this.fileDropService.filesInvalidEmitter.subscribe((data: any) => {
        this.onFileInvalids(data);
      }),
      this.fileDropService.filesDroppedEmitter.subscribe((data: any) => {
        this.onFilesDropped(data);
      }),
    );
  }

  ngOnInit() {
    // this.allowedExtensions = this.allowedFileTypes;
    let allowedExtensions: string[];
    if (typeof this.allowedFileTypes === 'string') {
      allowedExtensions = this.allowedFileTypes.split(',');
    } else if (Array.isArray(this.allowedFileTypes)) {
      allowedExtensions = this.allowedFileTypes;
    }

    this.allowedExtensions = allowedExtensions?.map(
      (type) => '.' + type.replace('.', ''),
    );
  }

  ngOnChanges() {
    if (!this.multiple && this.fileList.length > 1) {
      this.onMultiFilesWarning();
    }
  }
  ngOnDestroy() {
    this.subscriptions.forEach((sub) => sub.unsubscribe());
  }

  onMultiFilesWarning() {
    this.showMultiFileWarning = true;
  }

  onFilesSelected(event) {
    this.fileDropService.processFiles(
      event.target.files,
      this.allowedFileTypes,
    );
  }

  onFilesDropped(filesList: FileList | File[]) {
    if (filesList.length) {
      this.fileDropService.processFiles(filesList, this.allowedFileTypes);
    }
  }

  onFilesChange(files: ExtendedFile[]) {
    this.fileList = [];
    this.dragOverActive = false;
    var index = files.length;
    // multiple files upload allowed
    if (this.multiple) {
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        this.addFile(file);
      }
    }
    // Mutiple files upload NOT allowed
    else {
      // only one file dropped
      if (this.currentDroppedArray == 1) {
        // if the file list is empty
        if (!files.length) {
          // this.addFile(file, index)
        }
        // if there is already a file in the list
        else {
          // this.showFileReplaceConfirmDialog(file)
        }
      }
    }
    this.output.emit(files);
    this.actualFilesOutput.emit(files);

    // this.fileList = []
  }

  showFileLimitWarningDialog() {
    let dialog = this.dialog.open(AlertDialogComponent, {
      maxWidth: '400px',
      data: {
        title: this.fileLimitWarningTitle,
        message: this.fileLimitWarningMsg,
        actions: {
          confirm: this.fileLimitWarningConfirm,
          close: false,
        },
      },
    });
  }

  addFile(file, index?) {
    this.fileList.push(file);
  }

  onFileInvalids(files: ExtendedFile[]) {
    this.invalidFiles = files;
  }

  onExpandFiles() {
    this.allPanelsToggled = !this.allPanelsToggled;
  }

  onDragOver(event) {
    this.dragOverActive = event;
  }

  onFilelessCheck() {
    this.invalidFiles = [];
  }

  onFileUploadButtonClick(event) {
    this.fileRequester.nativeElement.click();
  }

  onFileInputClick(event) {
    event.stopPropagation();
  }
}
