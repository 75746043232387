import { Component, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogClose, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { ChatService, ChatComponent, ChatInputComponent } from '@exl-ng/mulo-common';
import { NotificationService } from '../../../core/notification.service';
import { TranslateModule } from '@ngx-translate/core';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'esp-chat-dialog',
    templateUrl: './chat-dialog.component.html',
    styleUrls: ['./chat-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, MatIconButton, MatDialogClose, MatIcon, ChatComponent, CdkScrollable, MatDialogContent, MatDialogActions, ChatInputComponent, MatButton, TranslateModule]
})
export class ChatDialogComponent {
    data = inject(MAT_DIALOG_DATA);
    service = inject(ChatService);
    private dialogRef = inject<MatDialogRef<ChatDialogComponent>>(MatDialogRef);
    private notificationService = inject(NotificationService);

    actionFocus = 'confirm';
    accentColor = 'accent';
    closeAfterSend = false;

    constructor() {
        if (this.data.actionFocus) {
            this.actionFocus = this.data.actionFocus;
        }
        if (this.data.closeAfterSend) {
            this.closeAfterSend = this.data.closeAfterSend;
        }
    }

    enableSendMessage() {
        const result = this.service.input?.replace(/\s/g, '');
        return this.service.input && result.length !== 0;
    }

    addUserMessage() {
        if (this.enableSendMessage()) {
            this.notificationService.addCommunicationMessage(this.service.input, this.data.model.mmsId)
                .subscribe((result) => {
                    if (this.closeAfterSend) {
                        this.dialogRef.close();
                    } else {
                        this.service.addUserMessage();
                    }
                });
        }
    }
}
