import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  Output,
  QueryList,
  SimpleChanges,
  inject,
  contentChildren,
} from '@angular/core';
import { AsyncPipe, Location, NgTemplateOutlet } from '@angular/common';
import {
  GrowInAnimation,
  GrowOutAnimation,
  HorizontalNavComponent,
  InViewDirective,
  MuloNavLink,
  OpacityOutAnimation,
  underscoreString,
  VerticalNavComponent,
  VerticalNavItemComponent,
} from '@exl-ng/mulo-common';
import {
  componentDestroyed,
  MediaQueryAlias,
  MediaService,
} from '@exl-ng/mulo-core';
import {
  BehaviorSubject,
  map,
  Observable,
  startWith,
  takeUntil,
  tap,
} from 'rxjs';
import { MULO_CONTAINER_WIDTH } from '../../models/container-widths.model';
import { PageSectionComponent } from '../page-section/page-section.component';
import { PageMastComponent } from '../page-mast/page-mast.component';
import { NavSize } from '../../layouts';
import { MatIcon } from '@angular/material/icon';
import { RouterLink } from '@angular/router';
import { NavMainLayoutComponent } from '../../layouts/nav-main-layout/nav-main-layout.component';
import { PagePlaceholderComponent } from '../page-placeholder/page-placeholder.component';
import { CenteredBlockLayoutComponent } from '../../layouts/centered-block-layout/centered-block-layout.component';

@Component({
  selector: 'mulo-page-base',
  templateUrl: './page-base.component.html',
  styleUrls: ['./page-base.component.scss'],
  host: { class: 'mulo-page-base' },
  animations: [OpacityOutAnimation, GrowOutAnimation, GrowInAnimation],
  standalone: true,
  imports: [
    CenteredBlockLayoutComponent,
    PagePlaceholderComponent,
    NgTemplateOutlet,
    InViewDirective,
    NavMainLayoutComponent,
    VerticalNavComponent,
    VerticalNavItemComponent,
    RouterLink,
    MatIcon,
    HorizontalNavComponent,
    AsyncPipe,
  ],
})
export class PageBaseComponent
  implements OnChanges, AfterContentInit, OnDestroy
{
  private location = inject(Location);
  media = inject(MediaService);

  @Input() loading = true;
  @Input() contentWidth: number = MULO_CONTAINER_WIDTH.READING;
  @Input() navWidth: NavSize = 150;
  @Input() backToTopLabel = 'Title';
  @Input() hNavMediaCondition: MediaQueryAlias = 'lt-lg';
  @Output() sectionsInit = new EventEmitter<null>();

  /** Highlight a specific section briefly - used to help focus on a sextion that the was navigated to within the page */
  @Input() sectionHighlight: string;

  @Input() stickyOffset;
  @Input() navBehavior: 'sticky' | 'fixed' | 'default' = 'sticky';
  @Input() navPosition: 'top' | 'bottom' | 'left' | 'right';
  @Input() rtl = false;

  @ContentChildren(PageSectionComponent, { descendants: true })
  _sections: QueryList<PageSectionComponent>;
  _masts = contentChildren(PageMastComponent, { descendants: true });

  anchors$: Observable<MuloNavLink[]>;

  private _showBackToTop = false;
  get showBackToTop() {
    return this._showBackToTop;
  }
  set showBackToTop(state) {
    this._showBackToTop = state;
  }

  private _anchors = new BehaviorSubject<MuloNavLink[]>([]);

  private subscriptions = [];
  constructor() {
    this.anchors$ = this._anchors
      .asObservable()
      .pipe(map((links) => links.filter((link) => link.label != '')));
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return;
    if (changes.sectionHighlight && changes.sectionHighlight.currentValue) {
      this.highlightSection(this.sectionHighlight);
    }
  }

  ngAfterContentInit() {
    this._sections.changes
      .pipe(
        startWith(this._sections),
        tap((res: QueryList<PageSectionComponent>) =>
          res.forEach((item, index) => {
            if (!this.subscriptions[index]) {
              this.subscriptions[index] = item.navLinkChanges
                .pipe(takeUntil(componentDestroyed(this)))
                .subscribe(() => this._sections.notifyOnChanges());
            }
          }),
        ),
        map((res: QueryList<PageSectionComponent>) => res.toArray()),
        takeUntil(componentDestroyed(this)),
        tap(() => this.emitSectionsInit()),
      )
      .subscribe((list) => {
        const array: MuloNavLink[] = list.map((item, index) => {
          const navLink = item.navLink || this.mapToMuloNavLink(item, index);
          if (item.navLink) item.navLink.id = index;
          return navLink;
        });
        this._anchors.next(array);
      });
  }

  ngOnDestroy() {}

  mapToMuloNavLink(item, index) {
    const name = underscoreString(item.title);
    return {
      label: item.title,
      name,
      id: index,
      url: `#${name}`,
    };
  }

  get containerWidth() {
    return this.contentWidth - Number(this.navWidth);
  }

  navStuck(state) {
    this.showBackToTop = state;
  }

  onAnchorNav(ev) {
    if (ev && ev.name) this.highlightSection(ev.name);
  }

  handleBackToTop() {
    const path = this.location.path();
    this.location.replaceState(path);
    this._masts().at(0)?.highlight();
  }

  highlightSection(sectionName) {
    if (!sectionName) return;
    const sections = this._sections.toArray();
    const section = sections.find(
      (s) => underscoreString(s.title) === sectionName,
    );
    section.highlight();
  }

  emitSectionsInit() {
    this.sectionsInit.emit(null);
  }
}
