<mat-drawer-container [hasBackdrop]="false" autosize>
  <mat-drawer #drawer [mode]="'side'" [(opened)]="drawerOpened" (closed)="onDrawerClosed()">
    <button
      mat-button
      class="has-icon drawer-button"
      (click)="drawer.toggle()"
      attr.aria-label="{{ 'research.aria.deposit.terms.and.condition.navigation.hide.links' | translate }}"
      >
      <mat-icon class="mat-icon-rtl-mirror" svgIcon="menu-open"></mat-icon>
      <span>
        {{ 'research.terms.hide' | customTranslate }}
      </span>
    </button>
    <div class="nav-wrapper">
      @for (link of anchorsArray; track link; let i = $index) {
        <a
          mat-button
          animation-child
          color="primary"
          class="is-wrapping"
          (click)="focusOnSection($event, i)"
          href=""
          attr.aria-label="{{ 'research.aria.navigation.navigate.to' | translate }}{{ ' ' }}{{ link }}"
          >
          {{ link }}
        </a>
      }
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div [innerHTML]="html" class="mulo-html-formatted"></div>
  </mat-drawer-content>
</mat-drawer-container>
