import { Component, OnInit, ViewChild, ElementRef, NgModule, AfterViewInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { NavigableTextComponent } from '../../navigable-text/navigable-text.component';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { FocusDirective } from '../../../shared/directives/focus/focus.directive';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'exl-long-text-dialog',
    templateUrl: './long-text-dialog.component.html',
    styleUrls: ['./long-text-dialog.component.scss'],
    standalone: true,
    imports: [MatIconButton, MatDialogClose, MatIcon, FocusDirective, MatDialogTitle, CdkScrollable, MatDialogContent, NavigableTextComponent, MatDialogActions, MatButton, TranslateModule, CustomTranslatePipe]
})


export class LongTextDialogComponent implements OnInit, AfterViewInit {
    data = inject(MAT_DIALOG_DATA);


    @ViewChild('confirm', {static: false}) confirm: ElementRef;
    @ViewChild('cancel', {static: false}) cancel: ElementRef;
    actionFocus = 'confirm';
    accentColor = 'accent';
    showDrawerButton = false;
    drawerOpened = false;
    viewInit = false;
    drawerDelay = 300;

    constructor() {
        if (this.data.actionFocus) {
            this.actionFocus = this.data.actionFocus;
        }
    }

    ngOnInit() {
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.openDrawer();
            this.viewInit = true;
        }, this.drawerDelay);
    }

    openDrawer() {
        this.drawerOpened = true;
    }

    onDrawerClosed() {
        this.drawerOpened = false;
    }

}
