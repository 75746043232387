import { Component, ElementRef, EventEmitter, HostBinding, Input, NgZone, Output, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { ANIMATION_MODULE_TYPE } from '@angular/platform-browser/animations';
import { Platform } from '@angular/cdk/platform';
import { IntervalerDirective } from '../../directives/intervaler.directive';

import { MatRipple } from '@angular/material/core';

@Component({
    selector: 'mulo-funky-button, [mulo-funky-button]',
    templateUrl: './funky-button.component.html',
    styleUrls: ['./funky-button.component.scss'],
    host: {
        class: 'mulo-funky-button has-icon is-loud is-funky-cool is-rounded mdc-button mdc-button--raised mat-mdc-raised-button mat-mdc-button-base',
        '[class.jelly-on-hover]': 'jellyOnHover',
        '[class.is-large]': 'isLarge',
        '[disabled]': 'disabled',
        '[attr.aria-label]': 'ariaLabel',
        '(click)': 'buttonClick.emit($event)',
    },
    standalone: true,
    imports: [
    MatRipple,
    IntervalerDirective
],
})
export class FunkyButtonComponent extends MatButton {
  _elementRef: ElementRef;

  @Input() gleam = true;
  @Input('class') classList = '';
  @Input() ariaLabel: string;
  @Output('clicked') buttonClick = new EventEmitter();

  @HostBinding('class.jelly-on-hover') @Input() jellyOnHover = true;
  @Input() isLarge = false;

  constructor() {
    const platform = inject(Platform);
    const _elementRef = inject(ElementRef);
    const _ngZone = inject(NgZone);
    const animationMode = inject(ANIMATION_MODULE_TYPE, { optional: true });

    super(_elementRef, platform, _ngZone, animationMode);
    this._elementRef = _elementRef;

  }
}
