<esp-deposit-form-section sectionTitle="{{ 'research.status.header.title' | customTranslate }}" [required]="required">
  <esp-deposit-publication-status></esp-deposit-publication-status>
</esp-deposit-form-section>

@if (displayMoreInformation()) {
  <esp-deposit-form-section
    sectionTitle="{{ 'research.status.info' | customTranslate }}"
    @scaleInOut
    >
    @if (isJournalArticle() || isBookReview()) {
      <esp-deposit-publication-details-journal-article
        [status]="status"
      ></esp-deposit-publication-details-journal-article>
    }
    @if (isBookChapter()) {
      <esp-deposit-publication-details-book-chapter
      ></esp-deposit-publication-details-book-chapter>
    }
    @if (isConferenceProceedings()) {
      <esp-deposit-publication-details-conference-proceeding
      ></esp-deposit-publication-details-conference-proceeding>
    }
    @if (isBook()) {
      <esp-deposit-publication-details-book></esp-deposit-publication-details-book>
    }
  </esp-deposit-form-section>
}

<esp-deposit-form-section sectionTitle="{{ 'research.published.DOI' | customTranslate }}">
  <esp-deposit-doi
    [assetType]="depositFormDataService.resourceType"
    [doi]="depositFormDataService.doi.value"
    [isAutoPopulated]="depositFormDataService.isKeyAutoPopulated('doi')"
    [isRegistered]="depositFormDataService.isRegisteredDOI.value"
    [isReserved]="depositFormDataService.isReservedDOI.value"
    (doiReserved)="depositFormDataService.handleDoiReserved($event)"
    (doiDismissed)="depositFormDataService.handleDoiDismissed()"
    (doiChanged)="depositFormDataService.updateDoi($event)"
    placeholder="research.status.doi"
  ></esp-deposit-doi>
</esp-deposit-form-section>

@if (displayDate()) {
  <esp-deposit-form-section
    sectionTitle="{{ dateTitle() | customTranslate }}"
    @scaleInOut
    [required]="true"
    >
    @if (status !== statusConst.PUBLISHED) {
      <esp-deposit-dates
        [autoPopulated]="isDateAutoPopulated"
        [range]="false"
        [dateHint]="true"
        [fromDate]="depositFormDataService.date"
        [error]="depositFormDataService.mainForm?.errors?.validDate && depositFormDataService.date.touched"
        [exlRequiredInput]="depositFormDataService.date"
        >
      </esp-deposit-dates>
    }
    @if (status === statusConst.PUBLISHED) {
      <esp-deposit-published-date></esp-deposit-published-date>
    }
  </esp-deposit-form-section>
}
