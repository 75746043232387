import {
    Component,
    Input,
    Output,
    EventEmitter,
    ViewChild,
    OnInit, SimpleChanges, OnChanges
} from '@angular/core';
import { UntypedFormControl, FormsModule, ReactiveFormsModule } from '@angular/forms';
import {CdkTextareaAutosize} from '@angular/cdk/text-field';
import { CustomTranslatePipe } from '../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { FocusDirective } from '../../shared/directives/focus/focus.directive';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '@exl-ng/mulo-common';
import { NgClass } from '@angular/common';
import { MatFormField, MatLabel, MatPrefix, MatSuffix, MatHint, MatError } from '@angular/material/form-field';

@Component({
    selector: 'exl-plain-text-input',
    templateUrl: './plain-text-input.component.html',
    styleUrls: ['./plain-text-input.component.scss'],
    host: { 'class': 'exl-plain-text-input' },
    standalone: true,
    imports: [MatFormField, NgClass, InputExpanderDirective, MatLabel, MatInput, FormsModule, ReactiveFormsModule, FocusDirective, CdkTextareaAutosize, MatIcon, MatPrefix, MatTooltip, MatIconButton, MatSuffix, MatHint, MatError, TranslateModule, CustomTranslatePipe]
})
export class PlainTextInputComponent implements OnInit, OnChanges{

    @Input() input: UntypedFormControl;
    @Input() jump;
    @Input() label: string;
    @Input() placeholder: string;
    @Input() autoPopulated = false;
    @Input() multipleLines = false;
    @Input() lengthLimit: number;
    @Input() focus = false;
    @Input() expanded = false;
    @Input('autocomplete') autocompleteLabel: string;
    @Output() output: EventEmitter<string> = new EventEmitter();


    @ViewChild('autosize', {static: false}) autosize: CdkTextareaAutosize;

    @ViewChild('inputElement', {static: false}) inputElement;

    constructor(){}

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges): void {
        if (changes && changes.focus && changes.focus.currentValue) {
            setTimeout(() => {
                this.focusOnInput();
            }, 0);
        }
    }

    focusOnInput(){
        this.inputElement.nativeElement.focus();
    }

    clearInput() {
        this.input.reset();
    }


}
