import { Component, OnInit, Input, Output, HostBinding, EventEmitter } from '@angular/core';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';

import { FormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'esp-deposit-radios-boolean',
    templateUrl: './deposit-radios-boolean.component.html',
    styleUrls: ['./deposit-radios-boolean.component.scss'],
    standalone: true,
    imports: [MatRadioGroup, FormsModule, BadgeAutoPopulatedComponent, MatRadioButton]
})
export class DepositRadiosBooleanComponent implements OnInit {

    @Input() input: string;
    @Input() buttonOne;
    @Input() buttonTwo;
    @Output() output: EventEmitter<string> = new EventEmitter();
    @HostBinding('class.is-autopopulated') @Input() autoPopulated: string = null;
    @Input() autoPopulatedTooltip: string;
    @Input() model = 'UNKNOWN';
    @Input('aria-label') ariaLabel: string;


    constructor() { }

    ngOnInit() {
    }

    onSelectionChange(event){
        this.output.emit(event);
    }

}
