import { Directive, ElementRef, Input, SimpleChanges, OnChanges, inject } from '@angular/core';

@Directive({
    selector: '[exlFocus]',
    standalone: true
})
export class FocusDirective implements OnChanges{
    private el = inject(ElementRef);


    @Input('exlFocus') activated = true;


    ngOnChanges(changes: SimpleChanges){
        if (changes.activated && this.activated) {
            this.initFocus();
        }
    }

    initFocus(){
        setTimeout(() => {
            this.el.nativeElement.focus();
        }, 700);
    }


}
