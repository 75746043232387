import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { UntypedFormBuilder, UntypedFormGroup, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ProfileService } from '../../../profile/profile.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SnackbarService } from '../../../core/snackbar.service';
import { SuccessSnackbarComponent } from '../../snackbars/success-snackbar/success-snackbar.component';
import { MatButton } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';


@Component({
    selector: 'exl-change-password-dialog',
    templateUrl: './change-password-dialog.component.html',
    styleUrls: ['./change-password-dialog.component.scss'],
    standalone: true,
    imports: [FormsModule, ReactiveFormsModule, MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, MatError, MatDialogActions, MatButton, MatDialogClose, TranslateModule]
})
export class ChangePasswordDialogComponent implements OnInit {
    data = inject(MAT_DIALOG_DATA);
    private formBuilder = inject(UntypedFormBuilder);
    private dialogRef = inject<MatDialogRef<ChangePasswordDialogComponent>>(MatDialogRef);
    profileService = inject(ProfileService);
    private translate = inject(TranslateService);
    private snackbarService = inject(SnackbarService);


    passwordForm: UntypedFormGroup;
    errorMessage = '';
    saveInProgress = false;

    constructor() {
        const dialogRef = this.dialogRef;

        dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.passwordForm = this.formBuilder.group({
                old: [null, Validators.required],
                new: ['', Validators.required],
                repeat: ['', Validators.required]
            }
        )
    }

    getPasswordFormValue = (fieldName: string): string => {
        return this.passwordForm.get(fieldName).value;
    }

    onSubmit() {
        if (this.passwordForm.valid){
            if (this.passwordForm.value.new !== this.passwordForm.value.repeat){
                this.errorMessage = this.translate.instant('research.password.change.error.notUnique');
                return;
            }
            this.resetErrors();
            this.saveInProgress = true;
            this.profileService.changePassword(this.getPasswordFormValue('old'), this.getPasswordFormValue('new'),
                this.getPasswordFormValue('repeat')).subscribe(data =>{
                    this.saveInProgress = false;
                if (data.status === 'ok'){
                    this.dialogRef.close();
                    this.showSuccessfullMessage();
                } else {
                    this.errorMessage = data['reply-text'];
                }
            }, () => this.saveInProgress = false);
        }
    }

    resetErrors(){
        this.errorMessage = '';
    }

    showSuccessfullMessage() {
        this.snackbarService.showComponent(
            SuccessSnackbarComponent,
            'research.password.change.success',
            6000
        );
    }

}
