import { Component, OnInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'exl-file-preview-dialog',
    templateUrl: './file-preview-dialog.component.html',
    styleUrls: ['./file-preview-dialog.component.scss'],
    host: { class: 'exl-file-preview-dialog' },
    standalone: true,
    imports: [
        MatDialogTitle,
        CdkScrollable,
        MatDialogContent,
    ],
})
export class FilePreviewDialogComponent implements OnInit {
    data = inject(MAT_DIALOG_DATA);
    private sanitizer = inject(DomSanitizer);

    file;
    safeData;

    constructor() {
        this.file = this.data?.file;
    }

    ngOnInit() {
        this.safeData = this.sanitizer.bypassSecurityTrustUrl(this.file?.data);
    }
}
