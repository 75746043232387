import {
  AfterViewInit,
  Component,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { MatChip, MatChipRemove, MatChipSet } from '@angular/material/chips';
import {
  FacetChipDirective,
  WidthInAnimation,
  WidthOutAnimation,
} from '@exl-ng/mulo-common';
import { FacetQuery } from '../facets.model';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { MatButton, MatIconButton } from '@angular/material/button';
import { CdkAriaLive } from '@angular/cdk/a11y';
import { NgTemplateOutlet } from '@angular/common';

@Component({
  selector: 'mulo-facets-active',
  templateUrl: './facets-active.component.html',
  styleUrls: ['./facets-active.component.scss'],
  animations: [WidthInAnimation, WidthOutAnimation],
  host: {
    // role: 'application',
    class: 'mulo-facets-active',
    '[class.is-disabled]': 'disabled',
  },
  standalone: true,
  imports: [
    MatChipSet,
    NgTemplateOutlet,
    MatChip,
    CdkAriaLive,
    MatIconButton,
    MatIcon,
    SvgViewboxDirective,
    MatChipRemove,
    FacetChipDirective,
    MatButton
],
})
export class FacetsActiveComponent implements AfterViewInit {
  @Input() lockedItemsAriaLabel = 'Locked Items';
  @Input() activeItemsAriaLabel = 'Search filters';
  @Input() unlockBtnAriaLabel = 'Unlock';
  @Input() lockBtnAriaLabel = 'Lock';
  @Input() removeBtnAriaLabel = 'Remove';
  @Input() rememberAllLabel = 'Remember filters';
  @Input() resetLabel = 'Reset filters';
  @Input() excludedA11yLabel = 'Excluded: ';

  @Input() lockable = true;
  @Input() showActions = true;
  @Input() disabled = false;

  @Input() activeItems: FacetQuery[] = [];
  // @Input() lockedItems: FacetQuery[] = [];

  @Output() lock: EventEmitter<FacetQuery> = new EventEmitter();
  // @Output() unlocked: EventEmitter<FacetQuery> = new EventEmitter();
  @Output() remove: EventEmitter<FacetQuery> = new EventEmitter();
  @Output() lockAll: EventEmitter<null> = new EventEmitter();
  @Output() removeAll: EventEmitter<null> = new EventEmitter();

  // @ViewChild(MatChipListbox) chipList: MatChipListbox;
  // @ViewChildren(MatChip) chips: QueryList<MatChip>;

  hoveredItem: number | null = null;
  focusedItem: number | null = null;

  constructor() {}

  ngAfterViewInit(): void {
    this.updateDecendantsChipsInChiplist();
  }

  handleLock(item: FacetQuery) {
    this.lock.emit(item);
  }

  // handleUnlock(item: FacetQuery) {
  //   this.unlocked.emit(item);
  // }

  handleRemove(item: FacetQuery) {
    // let index = this.activeItems.findIndex(i => i.key === item.key )
    // this.activeItems.splice(index, 1)
    // setTimeout(() => {
    this.remove.emit(item);
    // }, 0);
  }

  handleRememberAllClick() {
    this.lockAll.emit();
  }

  handleResetClick() {
    this.removeAll.emit();
  }

  trackByFn(index: number, item) {
    return item.key;
  }

  /**
   * ! Accessibility.
   *  Update the aria roles for chip list
   */
  private updateDecendantsChipsInChiplist() {
    // this.chipList.chips = this.chips;
    // this.chipList.ngAfterContentInit();
  }
}
