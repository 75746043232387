import {Component, Output, EventEmitter, Input, HostBinding, OnInit} from '@angular/core';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { RichTextEditorComponent } from '@exl-ng/mulo-common';
import { BadgeAutoPopulatedComponent } from '../../../bits/badge/badge-auto-populated/badge-auto-populated.component';


@Component({
    selector: 'esp-deposit-free-text',
    templateUrl: './deposit-free-text.component.html',
    styleUrls: ['./deposit-free-text.component.scss'],
    standalone: true,
    imports: [BadgeAutoPopulatedComponent, RichTextEditorComponent, CustomTranslatePipe]
})
export class DepositFreeTextComponent implements OnInit{

    @Input() freeText;
    @Input() placeholder;
    @Output() output: EventEmitter<string> = new EventEmitter;
    @HostBinding('class.has-content') content;
    @HostBinding('class.is-autoPopulated') @Input() autoPopulated = false;

    constructor() {}

    ngOnInit() {
        this.content = this.freeText.value;
    }


    onEditorContentChange(editorContent){
        this.content = editorContent;
      //  this.output.emit(editorContent);
    }

}
