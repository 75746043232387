import { Injectable, inject } from '@angular/core';
import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import {
    HttpClient,
    HttpParameterCodec,
    HttpParams,
} from '@angular/common/http';
import { UrlUtils } from '../shared/utils/url.utils';
import { Discovery } from '../shared/configurations/discovery.constant';
import { JwtUtilService } from '../core/jwt-util.service';
import { Profile } from '../shared/configurations/profile.constant';
import { I18nService } from '../core/i18n.service';
import { ProjectDetails } from '../shared/interfaces/project.interface';
import { SearchResultData } from '../shared/interfaces/search-result-data';
import { SearchParameters } from '../shared/interfaces/search-parameters';

export class CustomURLEncoder implements HttpParameterCodec {
    encodeKey(key: string): string {
        return encodeURIComponent(key);
    }
    encodeValue(key: string): string {
        return encodeURIComponent(key);
    }
    decodeKey(key: string): string {
        return decodeURIComponent(key);
    }
    decodeValue(key: string) {
        return decodeURIComponent(key);
    }
}

@Injectable({
    providedIn: 'root',
})
export class DiscoveryService {
    private http = inject(HttpClient);
    private jwtUtilService = inject(JwtUtilService);
    private i18nService = inject(I18nService);

    institutionCode = UrlUtils.getParam('institution');

    scopes = ['name', 'affiliation', 'assets'];

    getResearchersBySearchIndex(
        searchTerm,
        offset,
        scope,
        qInclude,
        qExclude,
        mfacetByGroups,
        unit,
    ) {
        const params = this.getParamsForResearchersSearch(
            searchTerm,
            offset,
            qInclude,
            qExclude,
            mfacetByGroups,
            scope,
            unit,
        );
        return this.http.get(RestApiBase.GET_RESEARCHERS_BY_SEARCH_INDEX, {
            params,
        });
    }

    getParamsForResearchersSearch(
        searchTerm,
        offset,
        qInclude,
        qExclude,
        mfacetByGroups,
        scope,
        unit,
    ) {
        let params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('searchTerm', searchTerm)
            .set('isUserLoggedIn', String(this.jwtUtilService.isLoggedIn()))
            .set('lang', this.i18nService.getLanguage())
            .set('offset', offset.toString())
            .set('bulkSize', Discovery.RESEARCHERS_BULK_SIZE.toString())
            .set('sortBy', Discovery.SORT_BY)
            .set('scope', scope);

        if (qInclude) {
            params = params.set('qInclude', qInclude);
        }
        if (qExclude) {
            params = params.set('qExclude', qExclude);
        }
        if (unit) {
            params = params.set('unit', unit);
        }
        for (const m of mfacetByGroups) {
            params = params.append('multiFacets', m);
        }
        return params;
    }

    getUnitResearchersCount(unit) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('unit', unit);
        return this.http.get<number>(RestApiBase.GET_RESEARCHER_COUNT, {
            params,
        });
    }

    getReseracherProfileImage(userID) {
        const params = new HttpParams()
            .set('userID', userID)
            .set('institution', this.institutionCode)
            .set('date', Date().toString());
        return this.http.get(RestApiBase.GET_RESEARCHER_PROFILE_IMAGE, {
            params,
            responseType: 'blob',
        });
    }

    getAssets(
        query,
        scope,
        qInclude,
        qExclude,
        multiFacets,
        offset = 0,
        sort,
        enableAsteriskSearch,
        unit,
    ) {
        const params = this.getParamsForSearch(
            query,
            scope,
            qInclude,
            qExclude,
            multiFacets,
            offset,
            sort,
            enableAsteriskSearch,
            unit,
        );
        return this.http.get(RestApiBase.GET_ASSETS, { params });
    }

    getUnitAssetsCount(unit) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('unit', unit);
        return this.http.get<number>(RestApiBase.GET_ASSETS_COUNT, { params });
    }

    getUnitProjectsCount(unit: string) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.post<number>(
            RestApiBase.GET_PROJECTS_COUNT,
            this.getProjectParamForSearch(
                'ALL',
                '*',
                'date_d',
                '',
                '',
                [],
                0,
                unit,
            ),
            { params },
        );
    }

    getFacets(
        query,
        scope,
        qInclude,
        qExclude,
        multiFacets,
        offset,
        sort,
        enableAsteriskSearch,
        unit,
    ) {
        const params = this.getParamsForSearch(
            query,
            scope,
            qInclude,
            qExclude,
            multiFacets,
            offset,
            sort,
            enableAsteriskSearch,
            unit,
        );
        return this.http.get(RestApiBase.GET_FACETS, { params });
    }

    getProjects(
        searchBy,
        searchTerm,
        qInclude,
        qExclude,
        multiFacets,
        offset = 0,
        sortBy = 'date_d',
        unit?,
    ) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.post<SearchResultData<ProjectDetails>>(
            RestApiBase.GET_PROJECTS,
            this.getProjectParamForSearch(
                searchBy,
                searchTerm,
                sortBy,
                qInclude,
                qExclude,
                multiFacets,
                offset,
                unit,
            ),
            { params },
        );
    }

    getProjectParamForSearch(
        searchBy: string,
        searchTerm: string,
        sortBy: string,
        qInclude: string,
        qExclude: string,
        multiFacets: string[],
        offset: number,
        unit: string,
    ) {
        let sortDir = 'desc';
        if (sortBy === 'date_a') {
            sortDir = 'asc';
        }
        const params: SearchParameters = {
            searchBy,
            searchTerm,
            offset,
            limit: Discovery.ASSETS_BULK_SIZE,
            sortBy: 'start_date',
            sortDir,
            lang: this.i18nService.getLanguage(),
        };
        if (qInclude) {
            params['qInclude'] = qInclude;
        }
        if (qExclude) {
            params['qExclude'] = qExclude;
        }
        if (multiFacets.length) {
            params['multiFacets'] = multiFacets;
        }
        if (unit) {
            // if (qInclude && qInclude !== '') {
            //     params['qInclude'] += '|,|';
            // } else {
            //     params['qInclude'] = '';
            // }
            // params['qInclude'] += 'academic_units_facet,exact,' + unit;
            params['orgUnit'] = unit;
        }
        return params;
    }

    getParamsForSearch(
        query,
        scope,
        qInclude,
        qExclude,
        multiFacets,
        offset,
        sort,
        enableAsteriskSearch,
        unit,
    ) {
        let params = new HttpParams({ encoder: new CustomURLEncoder() })
            .set('institution', this.institutionCode)
            .set('q', query)
            .set('scope', scope)
            .set('tab', 'Research')
            .set('offset', offset.toString())
            .set('limit', '10')
            .set('sort', sort)
            .set('lang', this.i18nService.getLanguage())
            .set('enableAsteriskSearch', enableAsteriskSearch);
        if (qInclude) {
            params = params.set('qInclude', qInclude);
        }
        if (qExclude) {
            params = params.set('qExclude', qExclude);
        }
        if (unit) {
            params = params.set('unit', unit);
        }
        for (const m of multiFacets) {
            params = params.append('multiFacets', m);
        }
        return params;
    }

    getDiscoverySearchURL(institutionCode: string, query: string) {
        let discoveryURL = Profile.DISCOVERY_SEARCH_URL;
        discoveryURL = discoveryURL.replace(
            '{{institution_code}}',
            institutionCode,
        );
        discoveryURL = discoveryURL.replace(
            '{{lang}}',
            this.i18nService.getLanguage(),
        );
        discoveryURL = discoveryURL.replace('{{query}}', query);
        return discoveryURL;
    }

    getRandomWords(count = 1) {
        const url = `https://random-word-api.herokuapp.com/word?number=${count}`;
        return this.http.get(url);
    }

    getCollectionByCode(code) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.get(RestApiBase.GET_COLLECTION_BY_CODE + '/' + code, {
            params,
        });
    }
}
