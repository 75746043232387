import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';

import { RestApiBase } from '../shared/configurations/rest-api-base.config';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { HomepageScope } from '../shared/interfaces/research-portal/portal-configuration/homepage-configuration.interface';
import { I18nService } from '../core/i18n.service';
import { PortalHomepageSections } from '../shared/configurations/portal-homepage.constant';
import { JwtUtilService } from '../core/jwt-util.service';
import { AssetsUsage } from '../shared/interfaces/assets-usage';
import { Subject } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class PortalService {
    private http = inject(HttpClient);
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private i18nService = inject(I18nService);
    private jwtUtilService = inject(JwtUtilService);

    homepageScopes: HomepageScope[] = [];
    institutionCode = UrlUtils.getParam('institution');

    openMobileAdvSearch = new Subject<void>();

    getDownloadsMapByCountry() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.get(RestApiBase.GET_USAGE_BY_COUNTRY, { params });
    }

    getAssetsUsage() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.get<AssetsUsage>(RestApiBase.GET_ASSETS_USAGE, {
            params,
        });
    }

    getTotalAssetsCount() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.get(RestApiBase.GET_TOTAL_ASSETS_COUNT, { params });
    }

    getHomepageImages() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.get(RestApiBase.GET_PORTAL_HOMEPAGE_IMAGES, {
            params,
        });
    }

    getHomepageLinkImage(linkNumber) {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode,
        );
        return this.http.get(
            RestApiBase.GET_PORTAL_HOMEPAGE_LINK_IMAGE + '/' + linkNumber,
            { params },
        );
    }

    getAssetsBriefByIdsList(mmsIdsList) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('language', this.i18nService.getLanguage());
        return this.http.post(
            RestApiBase.GET_PORTAL_ASSETS_BRIEF_BY_IDS_LIST,
            mmsIdsList,
            { params },
        );
    }

    getAssetsBriefBySetIds(setId) {
        const params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('language', this.i18nService.getLanguage());
        return this.http.get(
            RestApiBase.GET_PORTAL_ASSETS_BRIEF_BY_SET_ID + '/' + setId,
            { params },
        );
    }

    getFeaturedResearchers(idsList) {
        let params = new HttpParams()
            .set('institution', this.institutionCode)
            .set('isUserLoggedIn', String(this.jwtUtilService.isLoggedIn()));
        for (const id of idsList) {
            params = params.append('idsList', id);
        }
        return this.http.get(RestApiBase.GET_FEATURED_RESEARCHERS, { params });
    }

    getHomepageSectionByCode(code: string) {
        for (const section of this.configurationHandlerService.getPortalHomepageConfiguration()) {
            if (code === section.code) {
                return section;
            }
        }
        return null;
    }

    filterSearchScopesByCtrlCode(searchScopes = []) {
        if (this.homepageScopes?.length === 0) {
            this.homepageScopes =
                (this.getHomepageSectionByCode(PortalHomepageSections.SCOPES)
                    ?.homepageScopes as HomepageScope[]) || [];
        }
        let filteredSearchScopes = searchScopes.filter((searchScope) => {
            if (searchScope.ctrlCode) {
                const hScope = this.homepageScopes.find(
                    (homepageScope) =>
                        searchScope.ctrlCode === homepageScope.code,
                );
                return hScope === undefined || hScope.active;
            } else {
                return true;
            }
        });
        const homepageScopesCodes = ['research.portal.homepage.all'].concat(
            this.homepageScopes.map((scope) => scope.code),
        );
        filteredSearchScopes = filteredSearchScopes.sort((a, b) => {
            return (
                homepageScopesCodes.indexOf(a.ctrlCode) -
                homepageScopesCodes.indexOf(b.ctrlCode)
            );
        });
        return filteredSearchScopes;
    }
}
