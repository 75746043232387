import { Directive, ElementRef, OnInit, Input, HostBinding, Renderer2, NgModule, inject } from '@angular/core';
import { interval } from 'rxjs';

@Directive({
    selector: '[muloIntervaler]',
    standalone: true,
})
export class IntervalerDirective implements OnInit {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  /** Pass interval as a number in seconds*/
  @Input('muloIntervaler') interval: number;
  /** Pass optional class to add/remove to element on interval */
  @Input('muloIntervalerClass') dynamicClass: string;
  source;
  subscription;

  ngOnInit() {
    // define the interval (in full seconds)
    this.source = interval(this.interval * 1000);
    // Add class for the first time, to prevent an initial null period
    if (this.dynamicClass) this.applyClass();
    // Start the interval
    this.subscription = this.source.subscribe((val) => {
      // apply/remove the class cycle
      if (this.dynamicClass) {
        this.applyClass();
      }
    });
  }

  applyClass() {
    this.renderer.removeClass(this.el.nativeElement, this.dynamicClass);
    setTimeout(() => {
      /** The optional class gets added */
      this.renderer.addClass(this.el.nativeElement, this.dynamicClass);
    }, 500);
  }

  ngOnDestroy() {
    /** destroy the interval */
    this.subscription.unsubscribe();
  }
}


