import { Injectable, inject } from '@angular/core';

import { RestApiBase } from '../../../shared/configurations/rest-api-base.config';
import { HttpClient, HttpParams } from '@angular/common/http';


@Injectable({
    providedIn: 'root'
})
export class DepositLastStepService {
    private http = inject(HttpClient);


    summaryNotes = false;
    copyrightsNotes = false;

    getConfigTermsOfUse(configFileName, intst) {
        const params = new HttpParams().set('institution', intst).set('termsOfUse', configFileName);
        return this.http.get(RestApiBase.TERMS_OF_USE, { params: params });
    }
}
