import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewChild, inject } from '@angular/core';
import { MatBottomSheet } from '@angular/material/bottom-sheet';
import { MatMenu, MatMenuTrigger } from '@angular/material/menu';
import { UserMenuPanelComponent } from './user-menu-panel/user-menu-panel.component';
import { take } from 'rxjs';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';
import { AvatarComponent } from '@exl-ng/mulo-common';
import { MatButton } from '@angular/material/button';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'exl-user-menu',
    templateUrl: './user-menu.component.html',
    styleUrls: ['./user-menu.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'exl-user-menu' },
    standalone: true,
    imports: [
    MatButton,
    MatMenuTrigger,
    NgTemplateOutlet,
    AvatarComponent,
    MatIcon,
    MatMenu,
    UserMenuPanelComponent,
    TranslateModule
],
})
export class UserMenuComponent {
    private _bottomSheet = inject(MatBottomSheet);

    @Input() userName;
    @Input() userImage;
    @Input() userInitials;
    @Input() proxyName;
    @Input() proxyInitials;
    @Input() proxyImage;
    @Input() menuItems;
    @Input() showArrow = true;
    @Input() menuStyle: 'menu' | 'sheet' = 'menu';
    @Input('ariaLabel') menuA11yLabel: string;

    menuOpen = false;

    @Output() signOut: EventEmitter<any> = new EventEmitter();
    @Output() action: EventEmitter<string> = new EventEmitter();

    @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
    @ViewChild(UserMenuPanelComponent) userMenuPanel: UserMenuPanelComponent;
    @ViewChild(MatMenu) menu: MatMenu;

    @HostBinding('class.has-proxy') get hasProxy() {
        return this.proxyName ? this.proxyName.length : null;
    }

    ngAfterViewInit() {
        this.menu._allItems = this.userMenuPanel.matMenuItems;
        this.menu.ngAfterContentInit();

        if (!this.menuA11yLabel) {
            this.menuA11yLabel = this.hasProxy ? this.proxyName : this.userName;
        }
    }

    showBottomSheet(): void {
        const config = {
            panelClass: 'padding-zero',
            data: this.userMenuPanel,
        };
        this.menuOpen = true;

        this._bottomSheet
            .open(UserMenuPanelComponent, config)
            .afterDismissed()
            .pipe(take(1))
            .subscribe((action) => this.onMenuAction(action));
    }

    doSignOut() {
        this.signOut.emit();
    }

    onMenuAction(action) {
        this.menuOpen = false;
        switch (action) {
            case 'signout':
                this.signOut.emit(null);
                break;
            default:
                this.action.emit(action);
                break;
        }
    }
}
