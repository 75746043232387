<div class="wrapper">
  @for (entry of chat; track entry; let i = $index) {
    <mulo-chat-entry
      [content]="entry"
      [time]="entry.time"
      [index]="i"
    [@growInAnimation]="{
      value: ':enter',
      params: {
        startScale: 0,
        time: '.3s',
        origin: entry.origin == 'self'
          ? 'right 400%'
          : 'left 400%'
        }
      }"
    [align]="entry.origin == 'self' ? 'right' : 'left'"
    [sender]="entry.origin"
    [senderTitle]="entry.originTitle"
    [reasonPrefix]="reasonPrefix"
  ></mulo-chat-entry>
}
</div>
