<nav mat-tab-nav-bar [mat-stretch-tabs]="false">
  @for (crumb of breadcrumbs; track trackByFn(i, crumb); let i = $index) {
    @if (crumb?.children?.length > 1) {
      <div mat-tab-link [active]="getCrumbActive(i)">
        <button mat-button [matMenuTriggerFor]="menu" [matMenuTriggerData]="{ crumb: crumb, i: i }">
          <span class="crumb-btn-content">
            {{ breadcrumbs[i + 1]?.name === 'all' ? crumb.allLabel || allLabel : breadcrumbs[i + 1]?.label }}
            <mat-icon svgIcon="menu-down"></mat-icon>
          </span>
        </button>
      </div>
    }
  }
</nav>
<mat-menu #menu="matMenu">
  <ng-template matMenuContent let-crumb="crumb" let-i="i">
    @if (crumb?.name !== 'root') {
      <button mat-menu-item (click)="onChange(allLink, i)">
        {{ crumb.allLabel || allLabel }}
      </button>
    }
    @for (option of crumb.children; track option) {
      <button mat-menu-item (click)="onChange(option, i)">
        {{ option.label }}
      </button>
    }
  </ng-template>
</mat-menu>
