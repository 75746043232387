import {
  Component,
  EventEmitter,
  HostBinding,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  FormsModule,
  ReactiveFormsModule,
  UntypedFormControl,
} from '@angular/forms';
import { MatError } from '@angular/material/form-field';
import { KeyValuePipe } from '@angular/common';
import { RichTextA11yDirective } from './rich-text-a11y.directive';
import { QuillEditorComponent } from 'ngx-quill';

@Component({
  selector: 'mulo-rich-text-editor',
  templateUrl: './rich-text-editor.component.html',
  styleUrls: ['./rich-text-editor.component.scss'],
  host: { class: 'mulo-rich-text-editor' },
  standalone: true,
  imports: [
    QuillEditorComponent,
    RichTextA11yDirective,
    FormsModule,
    ReactiveFormsModule,
    MatError,
    KeyValuePipe
],
})
export class RichTextEditorComponent implements OnInit {
  @Input() ariaLabel: string;
  @Input() editorContent = new UntypedFormControl();
  @Input() autoPopulated = false;
  @Input() sneakyToolbar = false;
  @Input() placeholder = 'Enter text, or paste content';
  @Input() disabled = false;
  @Input() showOnlyH3Heading = false;
  @Input() required: boolean;
  @Input() requiredError = 'This field is required';
  @HostBinding('class.has-content') content;
  @Output() private changedContent: EventEmitter<any> = new EventEmitter();

  editorTheme = 'snow'; // "snow" (toolbar) or "bubble" (inline)
  defaultModule = {
    toolbar: [
      [{ header: [1, 2, 3, false] }],
      ['bold', 'italic', 'underline', { script: 'sub' }, { script: 'super' }],
      [{ list: 'ordered' }, { list: 'bullet' }],
      [{ align: [] }, { direction: 'rtl' }],
      ['link'],
      ['clean'],
    ],
    keyboard: {
      bindings: {
        tab: {
          key: 9,
          handler: () => true,
        },
      },
    },
  };

  constructor() {}

  ngOnInit() {
    if (this.showOnlyH3Heading) {
      this.defaultModule.toolbar[0] = [{ header: [3, false] }];
    }
  }

  emitChangedContent(event) {
    this.changedContent.emit(event);
  }
}
