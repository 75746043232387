import { inject, isDevMode } from '@angular/core';
import { ConfigurationHandlerService } from './configuration-handler.service';

export const internalDomain = 'exlibrisgroup.com';

export const isProduction = () => {
    const configSvc = inject(ConfigurationHandlerService);
    return !configSvc
        .getExternalFacingBaseUrl()
        .toLowerCase()
        .includes(internalDomain);
};
export const isNotProduction = () => {
    return isDevMode() || !isProduction();
};
