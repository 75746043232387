import { Component, OnInit, Input, ViewChild, Output, EventEmitter, ElementRef, inject } from '@angular/core';
import {mixinColor} from '../../shared/common-behavior/color';
import { MatMenuTrigger, MatMenu } from '@angular/material/menu';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

class OnboardingComponentBase {
    constructor (public _elementRef: ElementRef) { }
}

const _OnboardingComponentMixinBase = mixinColor(OnboardingComponentBase);

@Component({
    selector: 'exl-common-onboarding',
    templateUrl: './onboarding.component.html',
    styleUrls: ['./onboarding.component.scss'],
    standalone: true,
    imports: [MatMenuTrigger, MatMenu, MatButton, TranslateModule]
})
export class OnboardingComponent extends _OnboardingComponentMixinBase implements OnInit {

    guideShowDelay = 600;
    @Input() text: string;
    @Input() align: 'left' | 'right' | 'center' = 'left';
    @Input() textOffset: number;
    @Input() buttonText = 'research.header.proxy.menu.message.go.it';
    @Output() guideClosed: EventEmitter<null> = new EventEmitter();

    @ViewChild('guideTextTrigger', {static: true}) guideTextTrigger: MatMenuTrigger;

    constructor () {
        const elementRef = inject(ElementRef);

        super(elementRef);
    }

    ngOnInit(){
        setTimeout(() => {
            this.guideTextTrigger.openMenu();
        }, this.guideShowDelay);
    }

    onGuideTextClosed() {
        this.guideClosed.emit(null);
    }

    get offsetTextPosition(){
        const off = this.textOffset;
        return this.align === 'left' ? `-${off}rem` : `${off}rem`;
    }

}
