import { Component, ContentChildren, EventEmitter, Input, Output, QueryList, inject } from '@angular/core';
import {
  GrowInAnimation,
  GrowOutAnimation,
} from '../../animations/grow.animation';
import { PortionListService, PortionMethod } from './portion-list.service';
import { PortionListItemComponent } from './portion-list-item/portion-list-item.component';
import { MatList } from '@angular/material/list';
import { PortionListToolbarComponent } from './portion-list-toolbar/portion-list-toolbar.component';


export interface Portion {
  label: string;
  portion?: number;
}

@Component({
    selector: 'mulo-portion-list',
    templateUrl: './portion-list.component.html',
    providers: [PortionListService],
    styleUrls: ['./portion-list.component.scss'],
    host: { class: 'mulo-portion-list' },
    animations: [GrowInAnimation, GrowOutAnimation],
    standalone: true,
    imports: [
    PortionListToolbarComponent,
    MatList
],
})
export class PortionListComponent {
  service = inject(PortionListService);

  @Input() max = 100;
  @Input() min = 0;
  @Input() thumbLabel = true;
  /**
   * Array of labels to be used for the toolbar in order of appearance:
   * "Distribute", desc, equal, asc
   */
  @Input() toolbarLabels: string[];
  @Input() distribute: PortionMethod;

  @Output() distributePortion = new EventEmitter<PortionMethod>();

  @ContentChildren(PortionListItemComponent)
  contentItems: QueryList<PortionListItemComponent>;

  list: any = [];
  subscription;

  get listItems() {
    return this.contentItems.toArray();
  }

  distributePortions(method: PortionMethod) {
    this.distributePortion.emit(method);
  }
}
