<span class="mdc-button__label button-content"><ng-content></ng-content></span>
<span
  matRipple
  class="mat-mdc-button-ripple"
  [matRippleDisabled]="disableRipple"
  [matRippleTrigger]="_elementRef.nativeElement"
></span>
<span class="mat-button-focus-overlay"></span>
@if (gleam) {
  <div class="button-gleam" [muloIntervaler]="5" muloIntervalerClass="is-animating"></div>
}
