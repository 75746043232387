import { Component, ChangeDetectionStrategy, Input, ContentChildren, QueryList, SimpleChanges, ViewChild, ElementRef, Renderer2, AfterViewInit, AfterContentInit, OnChanges, inject } from '@angular/core';
import { FooterLinksSectionComponent } from '../footer-links-section/footer-links-section.component';
import { MatNavList } from '@angular/material/list';

@Component({
    selector: 'exl-footer-links',
    templateUrl: './footer-links.component.html',
    styleUrls: ['./footer-links.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-footer-links' },
    standalone: true,
    imports: [MatNavList],
})
export class FooterLinksComponent implements AfterViewInit, AfterContentInit, OnChanges {
  private renderer = inject(Renderer2);

  @Input() maxColumns: number | null = null;
  @Input() minColumnWidth: string | null = null;
  @Input() maxColumnWidth: string | null = null;
  @Input('fxLayout') fxLayout;
  @Input('aria-label') ariaLabel: string;
  @Input('role') role: 'list' | 'navigation' | 'none' = 'navigation';

  @ViewChild(MatNavList, { static: false, read: ElementRef })
  navList: ElementRef;

  private _sections: any[];
  @ContentChildren(FooterLinksSectionComponent) sections: QueryList<FooterLinksSectionComponent>;

  ngAfterViewInit() {
    // Change list role
    if (this.role !== 'navigation') {
      this.renderer.setAttribute(this.navList.nativeElement, 'role', this.role);
    }
  }

  ngAfterContentInit() {
    this._sections = this.sections.toArray();

    const noOfColumns =
      this._sections.length < this.maxColumns
        ? this._sections.length
        : this.maxColumns;

    this.updateFlexBasis(noOfColumns);

    if (this.minColumnWidth) {
      this.updateSectionsWidth(this.minColumnWidth);
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) { return null; }
    if (changes['maxColumns'] && changes['maxColumns'].currentValue) {
      this.updateFlexBasis(changes['maxColumns'].currentValue);
    }
  }

  updateFlexBasis(count: number) {
    if (!this._sections) { return null; }
    this._sections.forEach((section) => {
      section.updateFlexBasis(count);
    });
  }

  updateSectionsWidth(width: string) {
    this._sections.forEach((section) => {
      section.updateMinWidth(width);
    });
  }
}
