@for (item of navItems; track item) {
  <!-- Handle branch node buttons here -->
  @if (item.children && item.children.length > 0) {
    <button mat-mini-fab [matMenuTriggerFor]="menu.childMenu" (click)="onMenuItemClick(item)">
      <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="pickerIcon"></mat-icon>
    </button>
    <exl-picker-item #menu [items]="item.children" (selected)="onItemSelected(item)"></exl-picker-item>
  }
  <!-- Leaf node buttons here -->
  @if (!item.children || item.children.length === 0) {
    <button mat-button color="primary">
      {{ item.name }}
    </button>
  }
}
