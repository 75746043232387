import { Component, EventEmitter, Input, OnInit, Output, inject } from '@angular/core';
import { animate, style, transition, trigger } from '@angular/animations';
import { DepositFormDataService } from '../../../deposit/deposit-form-data.service';
import { FileUtils } from '@exl-ng/mulo-core';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatList, MatListSubheaderCssMatStyler, MatListItem } from '@angular/material/list';
import { MatButton } from '@angular/material/button';
import { NgClass } from '@angular/common';
import { FileUploadDirective } from './file-upload.directive';

@Component({
    selector: 'exl-file-upload',
    templateUrl: './file-upload.component.html',
    styleUrls: ['./file-upload.component.scss'],
    host: { class: 'exl-file-upload', tabIndex: '0' },
    animations: [
        trigger('scaleInOut', [
            transition(':enter', [
                style({
                    transform: 'scale(.8)',
                    opacity: 0,
                }),
                animate('600ms 100ms cubic-bezier(0.175, 0.885, 0.32, 1.275)', style({
                    transform: 'scale(1)',
                    opacity: 1,
                })),
            ]),
            transition(':leave', [
                style({
                    transform: 'scale(1)',
                    height: '*',
                    opacity: 1,
                }),
                animate('300ms cubic-bezier(0.895, 0.03, 0.685, 0.22)', style({
                    transform: 'scale(.8)',
                    height: 0,
                    opacity: 0,
                })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
    FileUploadDirective,
    NgClass,
    MatButton,
    MatList,
    MatListSubheaderCssMatStyler,
    MatListItem,
    TranslateModule,
    CustomTranslatePipe
],
})
export class FileUploadComponent implements OnInit {
    depositFormDataService = inject(DepositFormDataService);

    @Input() multiple;
    @Input() minimal = false;
    @Input() hidden = false;
    @Output() addNewFile: EventEmitter<any[]> = new EventEmitter();

    public filesDroppedEmitter: EventEmitter<number> = new EventEmitter();

    multiFilesDropLabel = 'Drop your files here';
    singleFileDropLabel = 'Drop your file here';
    multiDropActionLabel = "Drop 'em now!";
    singleDropActionLabel = 'Drop it now!';
    fileLimitWarningMsg = 'Multiple files are not allowed for this asset type.';
    dragOverActive = false;
    KB_IN_BYTES = 1024;
    MB_IN_BYTES = 1024 * this.KB_IN_BYTES;

    constructor() {
        this.filesDroppedEmitter.subscribe((data: any) => {
            this.onFilesDropped(data);
        });
    }

    ngOnInit() {}

    onFilesSelected(event) {
        const files = event.target.files;
        this.processFiles(files);
    }

    onFileUpload(file) {
        file.uploadProgress = 100;
    }

    onFilesDropped(files) {
        this.processFiles(files);
    }

    onFileValid(file) {
        this.addFile(file);
    }

    addFile(file) {
        this.dragOverActive = false;
        this.addNewFile.emit(file);
    }

    onDragOver(event) {
        this.dragOverActive = event;
    }

    processFiles(files) {
        for (let i = 0; i < files.length; i++) {
            const file = files[i];
            if (file.size > 500 * this.MB_IN_BYTES) {
                this.depositFormDataService.invalidFiles.push(file);
                continue;
            }
            //const reader = new FileReader();
            //reader.readAsDataURL(files[i]);
            //  reader.onload = (event: any) => {
            //const data = event.target.result;
            //  file['data'] = data;
            const extension = file.name
                .split('.')
                [file.name.split('.').length - 1].toLowerCase();
            this.enrichFile(file, extension);
            this.onFileValid(file);
            //  };
        }
    }

    enrichFile(file, extension) {
        file['fullName'] = file.name;
        file['baseName'] = file.name.replace(/\.[^/.]+$/, '');
        file['extension'] = file.name
            .split('.')
            [file.name.split('.').length - 1].toLowerCase();
        file['sizeFloat'] = FileUtils.getHumanFileSize(file.size, 2);
        file['profile'] = FileUtils.getFileProfile(extension, file.type);
    }
}
