import { AfterContentInit, Component, ElementRef, HostBinding, Input, ViewChild, inject } from '@angular/core';
import { BriefBaseComponent } from '../../briefs/brief-base/brief-base.component';
import { AltmetricService } from '../../altmetric/altmetric.service';
import { MediaService } from '@exl-ng/mulo-core';
import { DomSanitizer } from '@angular/platform-browser';
import { MatButton, MatIconButton } from '@angular/material/button';
import { HtmlStripPipe } from '@exl-ng/mulo-common';
import { TranslateModule } from '@ngx-translate/core';
import { SlicePipe } from '@angular/common';
import { MatDivider } from '@angular/material/divider';
import { MatMenuTrigger, MatMenu, MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatTooltip } from '@angular/material/tooltip';
import { BriefComponent } from '@exl-ng/mulo-views';

@Component({
    selector: 'exl-citation-brief',
    templateUrl: './citation-brief.component.html',
    styleUrls: ['./citation-brief.component.scss'],
    standalone: true,
    imports: [
        BriefComponent,
        MatIconButton,
        MatTooltip,
        MatIcon,
        MatButton,
        MatMenuTrigger,
        MatMenu,
        MatDivider,
        MatMenuItem,
        SlicePipe,
        TranslateModule,
        HtmlStripPipe,
    ],
})
export class CitationBriefComponent
    extends BriefBaseComponent
    implements AfterContentInit
{
    private altmetricService = inject(AltmetricService);
    media = inject(MediaService);
    private sanitizer = inject(DomSanitizer);

    @HostBinding('class.exl-citation-brief') hostClass = true;
    @ViewChild('exlSlotPreBrief', { static: false }) slotPreBrief: ElementRef;
    @ViewChild('menuBtn') menuBtn: MatButton;
    @Input() linkTarget = '_blank';
    @Input() titleHeaderLevel: number;
    @Input() useByForAuthors: boolean | string = true;

    maxVisibleAuthors = 10;
    @HostBinding('style.minHeight') hostMinHeight;

    @Input() id = Math.random().toString(36).substring(2);

    ngAfterContentInit() {
        setTimeout(() => {
            this.altmetricService.loadScript();
        }, 800);
    }

    onThumbLoad(height) {
        this.hostMinHeight = this.sanitizer.bypassSecurityTrustStyle(
            `calc(${height}px + 4rem)`,
        );
    }
}
