import { Injectable, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
import { SystemDateFormatService } from '@exl-ng/mulo-core';
import { map, Subject, take } from 'rxjs';
import { OverlayContainer } from '@angular/cdk/overlay';

const rtlLangs = ['ar', 'he', 'fa', 'ur', 'ks', 'ps', 'ug', 'ku', 'pa', 'sd'];

@Injectable({
    providedIn: 'root',
})
export class I18nService {
    private translate = inject(TranslateService);
    private systemDateFormatService = inject(SystemDateFormatService);
    private document = inject<Document>(DOCUMENT);
    private overlayContainer = inject(OverlayContainer);

    isLangRtl$ = new Subject<boolean>();

    constructor() {
        const translate = this.translate;

        translate.onLangChange.subscribe((event) => {
            localStorage.setItem(
                'rvXl8n_' + event.lang,
                JSON.stringify(event.translations)
            );
        });
    }

    setDefaultLanguage(defaultLang: string): void {
        this.translate.setDefaultLang(defaultLang);
    }

    getLanguage() {
        return this.translate.currentLang;
    }

    setLanguage(lang) {
        this.translate.use(lang);
        this.document.documentElement.setAttribute('lang', lang);
        const isLangRtl = rtlLangs.includes(lang);
        const dir = isLangRtl ? 'rtl' : 'ltr';
        this.document.dir = dir;
        this.overlayContainer.getContainerElement().dir = dir;
        this.isLangRtl$.next(isLangRtl);
        this.systemDateFormatService.setMomentLocale(lang);
    }

    isLangRtl() {
        return rtlLangs.includes(this.translate.currentLang);
    }

    translateWithDefaultLang(label: string, params: any) {
        return this.translate
            .getTranslation(this.translate.getDefaultLang())
            .pipe(
                take(1),
                map((lang) =>
                    this.translate.getParsedResult(lang, label, params)
                )
            );
    }

    defaultLangInstant(codeLabel: string, params: any) {
        let translateLabel = '';
        if (this.translate.getDefaultLang()) {
            const defaultTranslation =
                this.translate.translations[this.translate.getDefaultLang()];
            translateLabel = this.translate.getParsedResult(
                defaultTranslation,
                codeLabel,
                params
            );
        }
        return translateLabel || this.translate.instant(codeLabel, params);
    }
}
