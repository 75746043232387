import { Directive, NgModule, OnDestroy, Output, ElementRef, EventEmitter, PLATFORM_ID, Input, OnInit, AfterViewInit, ViewChild, Renderer2, inject } from '@angular/core';
import { numberOrNumberUnit } from '../utils/string-utils';

/** @dynamic */
@Directive({
    selector: '[muloSticky]',
    standalone: true,
})
export class StickyDirective implements AfterViewInit, OnDestroy {
  private el = inject(ElementRef);
  private renderer = inject(Renderer2);

  /**
   * Top marker class (this marker div is appended before the source element as sibling)
   */
  @Input('muloStickyTopClass') topClass: string = 'mulo-sticky-top';
  /**
   * Bottom marker class (this marker div is appended inside the source element as last child)
   */
  @Input('muloStickyBottomClass') bottomClass: string = 'mulo-sticky-bottom';
  /**
   * Z-index of the sticky element
   */
  @Input('muloStickyzIndex') zIndex: number = 1;
  /**
   * Emit the stuck state of the sticky element
   */
  @Output('muloStickyStuck') stuck = new EventEmitter<boolean>();
  /**
   * Emit the scrolled state of the sticky element (whether it is scrolled up after being stuck)
   */
  @Output('muloStickyScrolled') scrolled = new EventEmitter<boolean>();
  /**
   * Sticky offset from top of the screen
   */
  @Input('muloStickyOffsetTop')
  private _offsetTop: number | string = 0;
  set offsetTop(val) {
    this._offsetTop = val;
  }
  get offsetTop() {
    return numberOrNumberUnit(this._offsetTop);
  }

  /**
   * The intersection observer that watches whether elements interesect with the view
   */
  private observer: IntersectionObserver;

  ngAfterViewInit() {
    this.stuck.emit(false);

    const el = this.el.nativeElement;

    const top = this.renderer.createElement('div');
    const bottom = this.renderer.createElement('div');

    this.renderer.setStyle(el, 'position', 'sticky');
    this.renderer.setStyle(el, 'top', this.offsetTop);
    this.renderer.setStyle(el, 'zIndex', this.zIndex);

    this.renderer.addClass(top, this.topClass);
    this.setCommonStyles(top);
    this.renderer.setStyle(top, 'height', this.offsetTop);
    this.renderer.setStyle(top, 'marginTop', `-${this.offsetTop}`);

    this.renderer.addClass(bottom, this.bottomClass);
    this.setCommonStyles(bottom);
    this.renderer.setStyle(bottom, 'marginTop', `${el.offsetHeight}px`);

    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        const entry = entries[0];

        // if element has no root (i.e it was just added to the dom), exit early
        if (!entry.rootBounds) return null;

        const entryClass = entry.target.className;

        if (entryClass.includes(this.topClass)) {
          this.stuck.emit(this.getStuck(entry));
        }
        if (entryClass.includes(this.bottomClass)) {
          this.scrolled.emit(this.getScrolled(entry));
        }
      },
      { threshold: [0, 1] }
    );

    this.observer.observe(top);
    this.observer.observe(bottom);

    this.renderer.insertBefore(el.parentElement, top, el);
    this.renderer.appendChild(el, bottom);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }

  /**
   * Set styles common to both top and bottom markers
   */
  setCommonStyles(el) {
    this.renderer.setStyle(el, 'position', 'absolute');
    this.renderer.setStyle(el, 'width', '1px');
    this.renderer.setStyle(el, 'zIndex', -1);
  }

  /**
   * Find if the element is below the view, in the view or above the view
   */
  getBound(entry: IntersectionObserverEntry) {
    if (entry.boundingClientRect.top >= entry.rootBounds.bottom) {
      return 1;
    } else if (entry.boundingClientRect.top <= 0) {
      return 0;
    } else {
      return -1;
    }
  }

  /**
   * Find if the element should stick
   */
  getStuck(entry: IntersectionObserverEntry) {
    const ratio = entry.intersectionRatio;
    if (entry.isIntersecting) {
      if (ratio === 1) return false;
      if (ratio >= 0 && ratio < 1) return true;
    } else if (this.getBound(entry) < 1) {
      return true;
    } else {
      return false;
    }
  }

  /**
   * Find if the element has reached the bottom of it's parent container and is scrolled up
   */
  getScrolled(entry: IntersectionObserverEntry) {
    return this.getBound(entry) < 1 && !entry.isIntersecting;
  }
}


