<ng-content select="[slot='horizontaller-header']"></ng-content>
@if (headerContent) {
  <div class="horizontaller-header margin-bottom-md"></div>
}
<div #scrollerParent class="anchor">
  @if (!scrollLeftDisabled) {
    <button
      (click)="scroll('left')"
      @opacityOutAnimation
      [@slideInAnimation]="{ value: 'enter', params: { position: 'absolute', dX: '-100%', dY: '0' } }"
      [attr.aria-label]="scrollLeftBtnAriaLabel"
      [disabled]="scrollStarted || scrollLeftDisabled"
      class="scroll-button is-large"
      mat-icon-button
      >
      <mat-icon class="mat-icon-rtl-mirror" svgIcon="chevron-left"></mat-icon>
    </button>
  }
  <div class="outer-wrapper">
    <div #scrollerWrapper [tabIndex]="0" class="content">
      <div #scroller class="content-inner-wrapper">
        <div class="lead-spacer"></div>
        <div class="items-wrapper">
          @if (loading) {
            <div @opacityOutAnimation class="placeholders">
              @for (placeholder of _placeholders; track placeholder) {
                <div class="margin-right-sm">
                  <!-- animation-out-child -->
                  @if (placeholderTemplate) {
                    <ng-container
                      [ngTemplateOutlet]="placeholderTemplate"
                      >
                    </ng-container>
                  } @else {
                    <content-loader
                      [rtl]="rtl"
                      [style]="{ width: placeholderWidth, height: height }"
                      backgroundColor="#f1f1f1"
                      class="placeholder-item"
                      foregroundColor="#dddddd"
                      viewBox
                      >
                      <svg:rect [attr.height]="height" [attr.width]="placeholderWidth" x="0" y="0" />
                    </content-loader>
                  }
                </div>
              }
            </div>
          }
          @if (!loading) {
            <div @opacityInAnimation class="items">
              <ng-content select="mulo-horizontaller-item"></ng-content>
            </div>
          }
        </div>
        <div class="lead-spacer"></div>
      </div>
    </div>
  </div>
  @if (showPageBullets) {
    <div class="page-bullets">
      @for (item of items; track item; let i = $index) {
        <button
          (click)="scrollToItem(i)"
      [attr.aria-label]="
        pageBulletBtnLabel ? (pageBulletBtnLabel | translate : { num: i + 1 }) : 'Jump to item ' + (i + 1)
      "
          [class.active-bullet]="activeBullet === i"
          [disabled]="activeBullet === i"
          class="bullet"
        ></button>
      }
    </div>
  }
  @if (!scrollRightDisabled) {
    <button
      (click)="scroll('right')"
      @opacityOutAnimation
      [@slideInAnimation]="{ value: 'enter', params: { position: 'absolute', dX: '100%', dY: '0' } }"
      [attr.aria-label]="scrollRightBtnAriaLabel"
      [disabled]="scrollStarted || scrollRightDisabled"
      class="is-large scroll-button"
      mat-icon-button
      >
      <mat-icon class="mat-icon-rtl-mirror" muloSvgViewbox svgIcon="chevron-right"></mat-icon>
    </button>
  }
</div>
