<mat-menu #childMenu="matMenu" [overlapTrigger]="false">
  @for (child of items; track child) {
    <!-- Handle branch node menu items -->
    @if (child.children && child.children.length > 0) {
      <button mat-menu-item color="primary" [matMenuTriggerFor]="menu.childMenu" (click)="onMenuItemClick(child)">
        <span>{{child.text}}</span>
      </button>
      <exl-picker-item #menu [items]="child.children"></exl-picker-item>
    }
    <!-- Handle leaf node menu items -->
    @if (!child.children || child.children.length === 0) {
      <button mat-menu-item (click)="onMenuItemClick(child)">
        <span>{{child.text}}</span>
      </button>
    }
  }
</mat-menu>
