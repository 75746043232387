import { Component, OnInit, inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatDialogTitle, MatDialogContent, MatDialogActions, MatDialogClose } from '@angular/material/dialog';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';

import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix, MatError } from '@angular/material/form-field';
import { CdkScrollable } from '@angular/cdk/scrolling';

@Component({
    selector: 'exl-files-from-online-dialog',
    templateUrl: './files-from-online-dialog.component.html',
    styleUrls: ['./files-from-online-dialog.component.scss'],
    standalone: true,
    imports: [MatDialogTitle, CdkScrollable, MatDialogContent, MatFormField, MatLabel, MatInput, FormsModule, MatIcon, MatPrefix, MatError, MatDialogActions, MatButton, MatDialogClose, TranslateModule, CustomTranslatePipe]
})
export class FilesFromOnlineDialogComponent implements OnInit {
    data = inject(MAT_DIALOG_DATA);


    ngOnInit() {}

}
