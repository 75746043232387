import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DateUtils } from '../../../shared/utils/date.utils';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositDatesComponent } from '../deposit-dates/deposit-dates.component';
import { AutomationClassDirective } from '../../../shared/directives/tests/automation-tests-class.directive';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatTooltip } from '@angular/material/tooltip';
import { InputExpanderDirective } from '@exl-ng/mulo-common';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-event-information',
    templateUrl: './deposit-event-information.component.html',
    styleUrls: ['./deposit-event-information.component.scss'],
    standalone: true,
    imports: [
        MatFormField,
        InputExpanderDirective,
        MatTooltip,
        MatInput,
        FormsModule,
        AutomationClassDirective,
        ReactiveFormsModule,
        MatLabel,
        DepositDatesComponent,
        CustomTranslatePipe,
    ],
})
export class DepositEventInformationComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private startDateDestroy = new Subject<void>();
  private endDateDestroy = new Subject<void>();

  ngOnInit() {
    this.onChangesEventStartDate();
    this.onChangesEventEndDate();
  }

  onChangesEventStartDate(): void {
    this.depositFormDataService.eventStartDate.valueChanges
      .pipe(takeUntil(this.startDateDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.eventStartDate.value) {
          this.depositFormDataService.updateEventStartYear('');
          this.depositFormDataService.updateEventStartMonth('');
          this.depositFormDataService.updateEventStartDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.eventStartDate.value
          );
          this.depositFormDataService.updateEventStartYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateEventStartMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateEventStartDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  onChangesEventEndDate(): void {
    this.depositFormDataService.eventEndDate.valueChanges
      .pipe(takeUntil(this.endDateDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.eventEndDate.value) {
          this.depositFormDataService.updateEventEndYear('');
          this.depositFormDataService.updateEventEndMonth('');
          this.depositFormDataService.updateEventEndDay('');
        } else {
          const date = new Date(
            this.depositFormDataService.eventEndDate.value
          );
          this.depositFormDataService.updateEventEndYear(
            DateUtils.getYear(date)
          );
          this.depositFormDataService.updateEventEndMonth(
            DateUtils.getMonth(date)
          );
          this.depositFormDataService.updateEventEndDay(
            DateUtils.getDay(date)
          );
        }
      });
  }

  ngOnDestroy() {
    if (!this.startDateDestroy.isStopped) {
      this.startDateDestroy.next();
      this.startDateDestroy.unsubscribe();
    }
    if (!this.endDateDestroy.isStopped) {
      this.endDateDestroy.next();
      this.endDateDestroy.unsubscribe();
    }
  }
}
