import { AfterContentInit, AfterViewInit, ChangeDetectionStrategy, Component, Input, OnDestroy, TemplateRef, ViewChild, inject } from '@angular/core';
import { SideNavService } from './sidenav.service';
import { MatSidenav, MatSidenavContainer } from '@angular/material/sidenav';
import { filter, map } from 'rxjs';
import { MenuToggleButtonComponent } from '../menu-toggle/menu-toggle-button/menu-toggle-button.component';
import { NgTemplateOutlet, AsyncPipe } from '@angular/common';

@Component({
    selector: 'mulo-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
    changeDetection: ChangeDetectionStrategy.Default,
    host: {
        class: 'mulo-sidenav',
        '[class.is-fixed]': 'this.fixedInViewport',
    },
    standalone: true,
    imports: [
    MatSidenavContainer,
    MatSidenav,
    MenuToggleButtonComponent,
    NgTemplateOutlet,
    AsyncPipe
],
})
export class SidenavComponent
  implements AfterContentInit, AfterViewInit, OnDestroy
{
  service = inject(SideNavService);

  @Input() hasBackdrop = true;
  @Input() fixedInViewport = true;
  @Input() autoFocus = true;
  @Input() position: 'start' | 'end' = 'start';
  @Input() mode: 'over' | 'push' | 'side' = 'over';
  @Input() id: string;
  @Input() template: TemplateRef<any>;
  @Input() closeButtonText: string | null = 'Close';
  @Input() closeButtonIcon: string | null = 'close';
  @Input() closeButtonMode: 'absolute' | 'relative' | 'hidden' = 'relative';
  @Input() closeButtonSide: 'left' | 'right' = 'right';

  @ViewChild('sidenav', { static: true, read: MatSidenav })
  private sidenav: MatSidenav;
  public observer$ = this.service.content$.pipe(
    filter((_) => _?.id === this.id),
    map((_) => _.template)
  );

  ngAfterContentInit() {
    this.sidenav._animationEnd.subscribe((ev) => {
      this.service.onSidenavAnimationDone(ev.toState);
    });
  }

  ngAfterViewInit(): void {
    this.service.setSidenav(this.sidenav, this.id);
  }

  ngOnDestroy(): void {
    this.service.unloadSidenav(this.id);
  }

  public close() {
    this.service?.close(this.id);
  }
}
