import { Injectable, inject } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import {RestApiBase} from '../shared/configurations/rest-api-base.config';
import {UrlUtils} from '../shared/utils/url.utils';
import {DepositFileMetadata} from './deposit-file-metadata';
import {UntypedFormGroup, UntypedFormControl} from '@angular/forms';
import {ResourceTypeTemplateKeys} from '../shared/configurations/resource-type-template-keys.constant';
import {DepositFormDataService} from './deposit-form-data.service';
import {AmazonInfo} from '../shared/interfaces/amazon-info.interface';
import { Subscription } from 'rxjs';
import {JwtUtilService} from '../core/jwt-util.service';


@Injectable()
export class DepositFormService{
    private http = inject(HttpClient);
    private depositFormDataService = inject(DepositFormDataService);
    private jwtUtilService = inject(JwtUtilService);


    institutionCode: string;
    amazonInfo: AmazonInfo;
    draftInProcess = false;
    needToSaveDraftInOpen = false;
    mmsToDeleteInDiscard;
    disableCloseDialogDuringFirstSave = false;


    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getAmazonInfo() {
        const params = new HttpParams().set('institution', this.institutionCode);
        return this.http.get(RestApiBase.AMAZOM_INFO, { params: params });
    }

    getAdditionalDataOnDepositor() {
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode)
        .set('userId', this.jwtUtilService.getUserID());
        return this.http.get(RestApiBase.ADDITIONAL_DATA_ON_DEPOSITOR, { params: params });
    }

    submitNewAsset(depositFormData){
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.post(RestApiBase.SUBMIT_NEW_ASSET, depositFormData, {params:params, responseType: 'text'});
    }

    submitFilesAndLinks(filesData){
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode);
        return this.http.post(RestApiBase.SUBMIT_FILES_AND_LINKS, filesData, {params:params, responseType: 'text'});
    }

    saveDraft(depositFormData, fromAutoSave){
        let params = new HttpParams();
        params = params.set('institution', this.institutionCode).set('fromAutoSave', fromAutoSave);
        return this.http.post(RestApiBase.SAVE_DRAFT, depositFormData, {params:params, responseType: 'text'});
    }

    deleteDeposit(mmsId: string){
        const params = new HttpParams().set('mms', mmsId)
            .set('institution', this.institutionCode);
        return this.http.delete(RestApiBase.DELETE_DEPOSIT, {params:params});
    }

    buildDepositData(handleFiles = true): UntypedFormGroup{
        let filesMetadata: DepositFileMetadata[] = [];
        if (handleFiles){
            filesMetadata = this.getFilesMetadata();
        }
        const depositData = this.buildDepositDataToSubmit();
        depositData.value.filesMetadata = filesMetadata;
        depositData.value.deletedStorageFilesNames = {};
        if (handleFiles){
            depositData.value.deletedStorageFilesNames = this.depositFormDataService.deletedStorageFilesNames;
        }
        if (!handleFiles){
            depositData.value.filePath = '';
        }
        return depositData;
    }

    getFilesMetadata(): DepositFileMetadata[]{
        const filesMetadata: DepositFileMetadata[] = new Array();
        const accessRightsMap = {
            'public': 'Open',
            'embargoed': 'Fixed Embargo Date',
            'request': 'Restricted',
            'private': 'Closed',
        };
        this.depositFormDataService.filesForm.value.forEach(file => {
            const fileMetadata: DepositFileMetadata = new DepositFileMetadata();
            fileMetadata.description = file.description;
            fileMetadata.filename = file.fullName;
            fileMetadata.esploroLicenseCode = file.license;
            fileMetadata.esploroFileType = file.type;
            fileMetadata.accessRightsType = accessRightsMap[file.access];
            fileMetadata.accessRightsReason = file.accessRightsReason;
            fileMetadata.accessRightsFixedDate = file.accessRightsFixedDate;
            fileMetadata.accessRightsFixedYear = file.accessRightsFixedYear;
            fileMetadata.accessRightsFixedMonth = file.accessRightsFixedMonth;
            fileMetadata.accessRightsFixedDay = file.accessRightsFixedDay;

            filesMetadata.push(fileMetadata);
        });
        return filesMetadata;
    }

    buildDepositDataToSubmit(){
        const copy = new UntypedFormGroup({});

        ResourceTypeTemplateKeys.allDepositKeys.forEach(key => {
            const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
            copy.addControl(key, newControl);
        });

        if (this.depositFormDataService.isPublicationJournalArticle() || this.depositFormDataService.isPublicationBookReview()){
            ResourceTypeTemplateKeys.journalArticleKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else if (this.depositFormDataService.isPublicationBookChapter()){
            ResourceTypeTemplateKeys.bookChapterKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else if (this.depositFormDataService.isPublicationBook()){
            ResourceTypeTemplateKeys.bookKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else if (this.depositFormDataService.isPublicationConferenceProceedings()){
            ResourceTypeTemplateKeys.conferenceProceedingKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else if (this.depositFormDataService.isConference()){
            ResourceTypeTemplateKeys.conferenceKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else if (this.depositFormDataService.isPresentation()){
            ResourceTypeTemplateKeys.presentationKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else if (this.depositFormDataService.isDataset()){
            ResourceTypeTemplateKeys.datasetKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        } else {
            ResourceTypeTemplateKeys.defaultKeys.forEach(key => {
                const newControl = new UntypedFormControl(this.depositFormDataService.mainForm.get(key).value);
                copy.addControl(key, newControl);
            });
        }
        return copy;
    }





}
