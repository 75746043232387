import { Component, ElementRef, HostBinding, Input, inject } from '@angular/core';
import classNames from 'classnames';
import { TranslateModule } from '@ngx-translate/core';
import { MomentModule } from 'ngx-moment';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'mulo-chat-bubble',
    templateUrl: './chat-bubble.component.html',
    styleUrls: ['./chat-bubble.component.scss'],
    host: { class: 'mulo-chat-bubble' },
    standalone: true,
    imports: [
    MatIcon,
    MomentModule,
    TranslateModule
],
})
export class ChatBubbleComponent {
  private elRef = inject(ElementRef);

  @Input() color: 'accent' | 'primary' | string = 'accent';
  @Input() time = new Date();
  @Input() anchor: string = 'bottom left';
  @Input() content: any;
  @Input() align: 'left' | 'right' = 'left';
  @Input() icon: string | null = null;
  @Input() rtlMirrorIcon: boolean;
  @Input() reasonPrefix = 'Return reason:';
  classList;

  @HostBinding('class') get classes(): string {
    return classNames(
      this.classList,
      'anchor-' + this.anchor.replace(/\s+/g, '-').toLowerCase(),
      'mulo-' + this.color,
      'align-' + this.align
    );
  }

  constructor() {
    const elRef = this.elRef;

    this.classList = elRef.nativeElement.className;
  }
}
