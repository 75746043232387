<div class="files-list-header">
  <h3 class="list-title semibold">
    {{ listTitle }}
  </h3>
  <span class="spacer"></span>

  @if (expandable) {
    <button
      (click)="onExpandCollapseAll()"
      class="has-icon"
      color="primary"
      mat-button
      type="button"
      >
      <span>{{ isAllExpanded ? collapseAllLabel : expandAllLabel }}</span>
    </button>
  }
  @if (!minimal) {
    <button (click)="onRemoveAll($event)" class="has-icon" mat-button type="button">
      <mat-icon svgIcon="close"></mat-icon>
      <span>
        {{ 'research.linktable.remove' | customTranslate }}
      </span>
    </button>
  }
</div>
<cdk-accordion #fileList [multi]="multiOpen" role="grid">
  <ng-content></ng-content>
</cdk-accordion>

<!-- Content projection for slot "[slot-file-list-footer]" -->
<ng-content class="files-list-footer" select="[slot-file-list-footer]"></ng-content>
