@for (num of activeSdgs; track num) {
  <div class="icon-block">
    <img
      [alt]="labels[num - 1]"
      [fill]="true"
      [matTooltip]="labels[num - 1]"
      ngSrc="./assets/icons/sdg/{{ num | number : '2.0-0' }}.png"
      />
  </div>
}
