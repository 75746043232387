import { Directive, NgModule, Input, Output, EventEmitter, ElementRef, inject } from '@angular/core';

@Directive({
    selector: '[muloInView]',
    standalone: true,
})
export class InViewDirective {
  private el = inject(ElementRef);

  @Input('muloInViewThreshold') threshold: number | string = 1;
  @Input('muloInViewOffset') offset: string = '0px';

  @Output('muloInViewVisible') visible = new EventEmitter<
    boolean | boolean[]
  >();

  private observer: IntersectionObserver | undefined;

  ngAfterViewInit() {
    const thr =
      typeof this.threshold === 'string'
        ? parseFloat(this.threshold)
        : this.threshold;
    const options: IntersectionObserverInit = {
      rootMargin: this.offset,
      threshold: thr,
    };
    this.observer = new IntersectionObserver(
      (entries: IntersectionObserverEntry[]) => {
        this.visible.emit(
          entries.length === 1
            ? entries[0].intersectionRatio === 1
            : entries.map((entry) => entry.intersectionRatio === 1)
        );
      },
      options
    );

    this.observer.observe(this.el.nativeElement);
  }

  ngOnDestroy() {
    this.observer.disconnect();
  }
}


