<div class="deposit-form-section-wrapper">
  @if (sectionTitle) {
    <div class="deposit-form-section-header" @growInAnimation>
      <h3 class="deposit-form-section-title" [id]="titleId">
        {{sectionTitle}}
        @if (required) {
          <span class="mark-required">
            <mat-icon svgIcon="asterisk"></mat-icon>
            <span>{{'research.deposit.required' | translate}}</span>
          </span>
        }
      </h3>
      <span class="section-header-additional-content">
        <ng-content select="[section-header-additional-content]"></ng-content>
      </span>
    </div>
  }
  @if (description) {
    <div class="deposit-section-description" [id]="descriptionId">
      <span [innerHTML]="description"></span>
    </div>
  }
  <ng-content></ng-content>
</div>
