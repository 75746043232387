import { AriaDescriber } from '@angular/cdk/a11y';
import { Directive, ElementRef, Input, NgModule, OnInit, inject } from '@angular/core';

@Directive({
    selector: '[mulo-aria-describe]',
    standalone: true,
})
export class AriaDescriberDirective implements OnInit {
    private el = inject<ElementRef<HTMLElement>>(ElementRef);
    private ariaDescriber = inject(AriaDescriber);

    @Input('mulo-aria-describe') ariaDescribe: string;
  
    ngOnInit(): void {
      this.ariaDescriber.describe(this.el?.nativeElement, this.ariaDescribe);
    }
}


