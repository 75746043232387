import { Injectable, inject } from '@angular/core';
import {
    AbstractControl,
    FormGroup,
    UntypedFormArray,
    UntypedFormBuilder,
    UntypedFormControl,
    UntypedFormGroup,
} from '@angular/forms';
import { ResearcherDetails } from '../shared/interfaces/researcher-details.interface';
import { ResearcherName } from '../shared/interfaces/researcher-name.model';
import { Identifier } from '../shared/interfaces/identifier.model';
import {
    SelectedResearchTopic,
    TopicType,
} from '../shared/interfaces/selected-research-topic.model';
import { ResearcherEmail } from '../shared/interfaces/researcher-email.model';
import { ResearcherLink } from '../shared/interfaces/researcher-link.model';
import {
    ListType,
    ResearcherOrganization,
} from '../shared/interfaces/researcher-organization.interface';
import { ResearcherPhone } from '../shared/interfaces/researcher-phone.model';
import { ResearcherAddress } from '../shared/interfaces/researcher-address.model';
import { PersonOrganizationAffiliationData } from '../shared/interfaces/person-organization-affiliation-data.model';
import { PersonOrganizationAffiliationDataWrapper } from '../shared/interfaces/person-organization-affiliation-data-wrapper.model';
import { ResearcherDataService } from '../core/researcher-data.service';
import { DateUtils } from '../shared/utils/date.utils';
import { SortUtils } from '../shared/utils/sort.utils';
import { ResearcherEducation } from '../shared/interfaces/researcher-education.model';
import { DisableByConfigurationPipe } from '../shared/pipes/disable-by-configuration.pipe';
import { ConfigurationHandlerService } from '../core/configuration-handler.service';
import { MappingTable } from '../shared/interfaces/mapping-table.interface';
import { PersonHonorsData } from '../shared/interfaces/researcher-honors.interface';
import { ResearcherMetricDisplaySetting } from '../shared/interfaces/researcher-metric-display-setting';
import { ResearcherEngagement } from '../shared/interfaces/researcher-engagement.interface';
import { ProfileService } from '../profile/profile.service';

@Injectable({
    providedIn: 'root',
})
export class SettingsDataService {
    private formBuilder = inject(UntypedFormBuilder);
    private researcherDataService = inject(ResearcherDataService);
    private profileSvc = inject(ProfileService);
    private disableByConfiguration = inject(DisableByConfigurationPipe);
    configurationHandlerService = inject(ConfigurationHandlerService);

    settingsForm: UntypedFormGroup;
    _lastChosenOrganization: string;
    _lastChosenDepartment: string;
    showDescriptionFormButtons = false;
    proxiesPhotos = {};
    numberTypes = ['home', 'mobile', 'office', 'officeFax'];
    phoneTypeDisableMap = {
        home: 'activateCommunicationPhoneHome',
        mobile: 'activateCommunicationPhoneMobile',
        office: 'activateCommunicationPhoneOffice',
        officeFax: 'activateCommunicationPhoneFax',
    };
    numberTypesHiddenConfMap: { [key: string]: boolean } = {};
    globalIDsList;
    globalIDsTypeDisableMap = {
        arXiv: 'activateProfileGlobalIdArXiv',
        ERA: 'activateProfileGlobalIdERA',
        INSI: 'activateProfileGlobalIdINSI',
        LCNAF: 'activateProfileGlobalIdLCNAF',
        ORCID: 'activateProfileGlobalIdORCID',
        Pivot: 'activateProfileGlobalIdPivot',
        Pubmed: 'activateProfileGlobalIdPubmed',
        ResearcherID: 'activateProfileGlobalIdResearcherID',
        Scopus: 'activateProfileGlobalIdScopus',
        VIAF: 'activateProfileGlobalIdVIAF',
        Wikidata: 'activateProfileGlobalIdWikidata',
        GoogleScholarID: 'activateProfileGlobalIdGoogleScholarID',

    };

    /** Temp property to save origin title in case of change */
    private _researcherDisplayTitle: string;

    constructor() {
        this.createForm();
        this.numberTypes = this.numberTypes.filter(
            (type) =>
                !this.disableByConfiguration.transform(
                    this.phoneTypeDisableMap[type],
                ),
        );

        let userIdentifierDefinitionMT =
            this.configurationHandlerService.getMappingTable(
                'UserIdentifierDefinition',
            ) as MappingTable[];
        userIdentifierDefinitionMT = userIdentifierDefinitionMT.filter(
            (row) =>
                this.configurationHandlerService
                    .getResearcherIdentifiersOptions()
                    .indexOf(row.target) !== -1 && row.enabled === 'true',
        );
        this.globalIDsList = userIdentifierDefinitionMT.map((row) => {
            return row.target;
        });
        this.globalIDsList = this.globalIDsList.filter(
            (type) =>
                !this.disableByConfiguration.transform(
                    this.globalIDsTypeDisableMap[type],
                ),
        );
    }

    createForm() {
        this.settingsForm = this.formBuilder.group({
            names: this.formBuilder.array([]),
            globalIDs: this.formBuilder.array([]),
            topics: this.formBuilder.array([]),
            emails: this.formBuilder.array([]),
            description: '',
            restoredDescription: '',
            descList: this.formBuilder.array(['']),
            restoredDescList: this.formBuilder.array(['']),
            links: this.formBuilder.array([]),
            curAffiliations: this.formBuilder.array([]),
            prevAffiliations: this.formBuilder.array([]),
            mainInstitution: '',
            phones: this.formBuilder.array([]),
            addresses: this.formBuilder.array([]),
            proxies: this.formBuilder.array([]),
            numOfTotalAddresses: '',
            educations: this.formBuilder.array([]),
            displayTitle: '',
            honors: this.formBuilder.array([]),
            metricDisplaySettings: [[]],
            lettersSettings: [{}],
            engagements: this.formBuilder.array([]),
        });
    }

    addName(name) {
        this.names.push(this.formBuilder.group(name));
    }

    addAddress(address) {
        this.addresses.push(this.formBuilder.group(address));
    }

    addEngagement(engagement: ResearcherEngagement) {
        this.engagements.push(this.formBuilder.group(engagement));
    }

    addGlobalID(id) {
        this.globalIDs.push(this.formBuilder.group(id));
    }

    addTopic(topic) {
        this.topics.push(this.formBuilder.group(topic));
    }

    addEmail(email) {
        const group = this.formBuilder.group({
            email: email.email,
            isPreferred: email.isPreferred,
            esploroDisplayPhone: email.esploroDisplayEmail,
            isExternal: email.isExternal,
            type: email.type?.length ? email.type[0] : 'alternative',
        });
        this.emails.push(group);
    }

    addHonor(honor: PersonHonorsData) {
        const group = this.formBuilder.group({
            title: honor.title,
            organizationCode: honor.organizationCode,
            organizationName: honor.organizationName,
            timePeriod: honor.timePeriod,
            hiddenFromPublicProfile: honor.hiddenFromPublicProfile,
        });
        this.honors.push(group);
    }

    addCurrInternalAffiliation(
        affiliationData: PersonOrganizationAffiliationData,
    ) {
        const group = this.formBuilder.group({
            data: affiliationData,
            category: 'INTERNAL',
        });
        this.curAffiliations.push(group);
    }

    addCurrExternalAffiliation(
        affiliationData: PersonOrganizationAffiliationData,
    ) {
        const group = this.formBuilder.group({
            data: affiliationData,
            category: 'EXTERNAL',
        });
        this.curAffiliations.push(group);
    }

    addPrevInternalAffiliation(
        affiliationData: PersonOrganizationAffiliationData,
    ) {
        const group = this.formBuilder.group({
            data: affiliationData,
            category: 'INTERNAL',
        });
        this.prevAffiliations.push(group);
    }

    addPrevExternalAffiliation(
        affiliationData: PersonOrganizationAffiliationData,
    ) {
        const group = this.formBuilder.group({
            data: affiliationData,
            category: 'EXTERNAL',
        });
        this.prevAffiliations.push(group);
    }

    addLink(link) {
        link.setIcon(link.url);
        this.links.push(this.formBuilder.group(link));
    }

    addCurAffiliation(affiliation) {
        if (
            affiliation.data.startDate &&
            DateUtils.isEmptyDate(affiliation.data.startDate)
        ) {
            affiliation.data.startDate = '';
        }
        if (
            affiliation.data.endDate &&
            DateUtils.isEmptyDate(affiliation.data.endDate)
        ) {
            affiliation.data.endDate = '';
        }
        this.curAffiliations.push(this.formBuilder.group(affiliation));
    }

    addPrevAffiliation(affiliation) {
        if (
            affiliation.data.startDate &&
            DateUtils.isEmptyDate(affiliation.data.startDate)
        ) {
            affiliation.data.startDate = '';
        }
        if (
            affiliation.data.endDate &&
            DateUtils.isEmptyDate(affiliation.data.endDate)
        ) {
            affiliation.data.endDate = '';
        }
        this.prevAffiliations.push(this.formBuilder.group(affiliation));
    }

    addPhone(phone: ResearcherPhone) {
        const group = this.formBuilder.group({
            phone: phone.phone,
            isPreferred: phone.isPreferred,
            type: phone.type[0],
            esploroDisplayPhone: phone.esploroDisplayPhone,
            isExternal: phone.isExternal,
        });
        this.phones.push(group);
    }

    addProxy(proxies) {
        this.proxies.push(this.formBuilder.group(proxies));
    }

    addEducation(education) {
        const group = this.formBuilder.group({
            fieldOfStudy: education.fieldOfStudy,
            organizationCode: education.organizationCode,
            organizationName: education.organizationName,
            degree: education.degree,
            year: education.year,
            toDateYear: education.toDateYear,
            additionalDetails: education.additionalDetails,
            hiddenFromPublicPortal: education.hiddenFromPublicPortal,
        });
        this.educations.push(group);
    }

    updateName(index, first, middle, last, suffix, isPreferred) {
        const name = new ResearcherName(
            first,
            middle,
            last,
            suffix,
            isPreferred,
        );
        const nameGroup = this.formBuilder.group(name);
        this.names.removeAt(index);
        this.names.insert(index, nameGroup);
    }

    updateLink(index, url, title, description, hiddenFromPublicProfile) {
        const link = new ResearcherLink(url, title, description);
        link.setHiddenFromPublicProfile(hiddenFromPublicProfile);
        link.setIcon(url);
        const linkGroup = this.formBuilder.group(link);
        this.links.removeAt(index);
        this.links.insert(index, linkGroup);
    }

    updateLinksArray(linksArray: ResearcherLink[]) {
        const groupedLinks = linksArray.map((link) =>
            this.formBuilder.group(link),
        );
        const formArray = this.formBuilder.array(linksArray);
        this.settingsForm.patchValue({ links: linksArray });
    }

    updateGlobalID(index, type, value) {
        const identifier = new Identifier(type, value);
        const identifierGroup = this.formBuilder.group(identifier);
        this.globalIDs.removeAt(index);
        this.globalIDs.insert(index, identifierGroup);
    }

    updateDescription(description) {
        this.settingsForm.patchValue({ description: description });
    }

    updateMainInstitution(mainInstitution) {
        this.settingsForm.patchValue({ mainInstitution: mainInstitution });
    }

    updateDescriptionWithoutEmitEvent(description) {
        this.settingsForm.patchValue(
            { description: description },
            { emitEvent: false },
        );
    }

    updateRestoredDescription(restoredDescription) {
        this.settingsForm.patchValue({
            restoredDescription: restoredDescription,
        });
    }
    updateDescList(list: string[], emitEvent = true) {
        this.settingsForm.setControl('descList', this.formBuilder.array(list), {
            emitEvent,
        });
    }
    updateRestoredDescList(list: string[], emitEvent = true) {
        this.settingsForm.setControl(
            'restoredDescList',
            this.formBuilder.array(list),
            { emitEvent },
        );
    }

    updateNumOfTotalAddresses(numOfTotalAddresses) {
        this.settingsForm.patchValue({
            numOfTotalAddresses: numOfTotalAddresses,
        });
    }

    updateEmail(index, email, isPreferred) {
        const displayInProfile = this.emails
            .at(index)
            .get('esploroDisplayEmail');
        const researcherEmail = new ResearcherEmail(
            email,
            isPreferred,
            displayInProfile,
        );
        researcherEmail.type = email.type;
        const emailGroup = this.formBuilder.group(researcherEmail);
        this.emails.removeAt(index);
        this.emails.insert(index, emailGroup);
    }

    updatePhone(index, phone, type, isPreferred) {
        const displayInProfile = this.phones
            .at(index)
            .get('esploroDisplayPhone');
        const researcherPhone = new ResearcherPhone(
            phone,
            type,
            isPreferred,
            displayInProfile,
        );
        const phoneGroup = this.formBuilder.group(researcherPhone);
        this.phones.removeAt(index);
        this.phones.insert(index, phoneGroup);
    }

    updateAddress(index, address: ResearcherAddress) {
        const addressGroup = this.formBuilder.group(address);
        this.addresses.removeAt(index);
        this.addresses.insert(index, addressGroup);
    }

    updateEducation(index, education: ResearcherEducation) {
        const educationGroup = this.formBuilder.group(education);
        this.educations.removeAt(index);
        this.educations.insert(index, educationGroup);
    }

    updateEducationsArray(educationsArray: ResearcherEducation[]) {
        this.settingsForm.patchValue({ educations: educationsArray });
    }

    updateAffiliation(
        index,
        affiliation: PersonOrganizationAffiliationDataWrapper,
        cameFrom,
    ) {
        const affiliationGroup = this.formBuilder.group(affiliation);
        if (cameFrom === ListType.CURRENT) {
            this.curAffiliations.removeAt(index);
            this.curAffiliations.insert(index, affiliationGroup);
        } else {
            this.prevAffiliations.removeAt(index);
            this.prevAffiliations.insert(index, affiliationGroup);
        }
    }

    updateAffiliationAndMove(
        index,
        affiliation: PersonOrganizationAffiliationDataWrapper,
    ) {
        const affiliationGroup = this.formBuilder.group(affiliation);
        this.curAffiliations.removeAt(index);
        this.prevAffiliations.push(affiliationGroup);
    }

    updateDisplayTitle(displayTitle: string) {
        this.settingsForm.patchValue({ displayTitle: displayTitle });
        this._researcherDisplayTitle = displayTitle;
    }

    updateHonor(index: number, honor: PersonHonorsData) {
        const group = this.formBuilder.group(honor);
        this.removeHonor(index);
        this.honors.insert(index, group);
    }

    updateHonorsArray(honorsArray: ResearcherEducation[]) {
        this.settingsForm.patchValue({ honors: honorsArray });
    }

    removeName(index) {
        this.names.removeAt(index);
    }

    removeEmail(index) {
        this.emails.removeAt(index);
    }

    removeProxy(index) {
        this.proxies.removeAt(index);
    }

    removeAddress(index) {
        this.addresses.removeAt(index);
    }

    removeCurrentAffiliation(index) {
        this.curAffiliations.removeAt(index);
    }

    getCurrentAffiliationAtPosition(index) {
        return this.curAffiliations.at(index).value;
    }

    getPastAffiliationAtPosition(index) {
        return this.prevAffiliations.at(index).value;
    }

    removePrevAffiliation(index) {
        this.prevAffiliations.removeAt(index);
    }

    removePhone(index) {
        this.phones.removeAt(index);
    }

    removeGlobalID(index) {
        this.globalIDs.removeAt(index);
    }

    removeTopic(index) {
        this.topics.removeAt(index);
    }

    removeLink(index) {
        this.links.removeAt(index);
    }

    removeEducation(index) {
        this.educations.removeAt(index);
    }

    removeHonor(index: number) {
        this.honors.removeAt(index);
    }

    get names(): UntypedFormArray {
        return this.settingsForm.get('names') as UntypedFormArray;
    }

    get addresses(): UntypedFormArray {
        return this.settingsForm.get('addresses') as UntypedFormArray;
    }

    get proxies(): UntypedFormArray {
        return this.settingsForm.get('proxies') as UntypedFormArray;
    }

    get globalIDs(): UntypedFormArray {
        return this.settingsForm.get('globalIDs') as UntypedFormArray;
    }

    get topics(): UntypedFormArray {
        return this.settingsForm.get('topics') as UntypedFormArray;
    }

    get emails(): UntypedFormArray {
        return this.settingsForm.get('emails') as UntypedFormArray;
    }

    get description(): UntypedFormControl {
        return this.settingsForm.get('description') as UntypedFormControl;
    }

    get restoredDescription(): UntypedFormControl {
        return this.settingsForm.get(
            'restoredDescription',
        ) as UntypedFormControl;
    }

    get descList(): UntypedFormArray {
        return this.settingsForm.get('descList') as UntypedFormArray;
    }
    get restoredDescList(): UntypedFormArray {
        return this.settingsForm.get('restoredDescList') as UntypedFormArray;
    }

    get links(): UntypedFormArray {
        return this.settingsForm.get('links') as UntypedFormArray;
    }

    get phones(): UntypedFormArray {
        return this.settingsForm.get('phones') as UntypedFormArray;
    }

    get curAffiliations(): UntypedFormArray {
        return this.settingsForm.get('curAffiliations') as UntypedFormArray;
    }

    get prevAffiliations(): UntypedFormArray {
        return this.settingsForm.get('prevAffiliations') as UntypedFormArray;
    }

    get mainInstitution(): UntypedFormArray {
        return this.settingsForm.get('mainInstitution') as UntypedFormArray;
    }

    get numOfTotalAddresses(): UntypedFormControl {
        return this.settingsForm.get(
            'numOfTotalAddresses',
        ) as UntypedFormControl;
    }

    get educations(): UntypedFormArray {
        return this.settingsForm.get('educations') as UntypedFormArray;
    }

    get displayTitle(): UntypedFormControl {
        return this.settingsForm.get('displayTitle') as UntypedFormControl;
    }

    get honors(): UntypedFormArray {
        return this.settingsForm.get('honors') as UntypedFormArray;
    }

    get metricDisplaySettings(): UntypedFormArray {
        return this.settingsForm.get(
            'metricDisplaySettings',
        ) as UntypedFormArray;
    }

    get engagements(): UntypedFormArray {
        return this.settingsForm.get('engagements') as UntypedFormArray;
    }

    get lettersSettings() {
        return this.settingsForm.get('lettersSettings') as FormGroup<{
            [key: string]: AbstractControl<boolean>;
        }>;
    }

    setMetricDisplaySettings(
        metricDisplaySettings: ResearcherMetricDisplaySetting[],
    ) {
        this.settingsForm
            .get('metricDisplaySettings')
            .patchValue(metricDisplaySettings);
    }

    setEngagements(engagements: ResearcherEngagement[]) {
        this.settingsForm.get('engagements').patchValue(engagements);
    }

    setLettersSettings(lettersSettings) {
        this.settingsForm.get('lettersSettings').patchValue(lettersSettings);
    }

    getNameByIndex(index) {
        return this.names.at(index) as UntypedFormGroup;
    }

    getEmailByIndex(index) {
        return this.emails.at(index) as UntypedFormGroup;
    }

    getPhoneByIndex(index) {
        return this.phones.at(index) as UntypedFormGroup;
    }

    getHonorByIndex(index: number) {
        return this.honors.at(index) as UntypedFormGroup;
    }

    parseData(data: ResearcherDetails) {
        const preferredName = data.preferredName;
        preferredName.isDefaultName = true;
        this.addName(preferredName);
        const nameVariants = data.nameVariants;
        nameVariants.forEach((variant) => {
            variant.isDefaultName = false;
            this.addName(variant);
        });
        const identifiers = data.identifiers;
        identifiers.forEach((identifier) => {
            this.addGlobalID(identifier);
        });
        data.orderedTopics?.forEach((topic) => {
            const researchTopic = new SelectedResearchTopic(
                topic.code,
                topic.description,
                '',
                topic.code ? TopicType.SUBJECT : TopicType.KEYWORD,
            );
            this.addTopic(researchTopic);
        });
        data.emails.forEach((email) => {
            this.addEmail(email);
        });
        data.honors.forEach((honor) => this.addHonor(honor));
        //workaround because of this issue- https://github.com/KillerCodeMonkey/ngx-quill/issues/357
        const descList =
            data.descriptionList?.length > 0
                ? data.descriptionList.map((text) =>
                      text
                          .replace(/<p><br><\/p>/g, '<br>')
                          .replace(/<p>/g, '')
                          .replace(/<\/p>/g, '<br>'),
                  )
                : [''];
        this.updateDescList(descList, false);
        this.updateRestoredDescList(descList);
        const mainInstitution = data.mainInstitution;
        const mainInstitutionOrg =
            mainInstitution as unknown as ResearcherOrganization;
        this.updateMainInstitution(mainInstitutionOrg.organizationName);
        data.links.forEach((link) => {
            const title = link.title ? link.title : link.url;
            const description = link.description ? link.description : '';
            const newLink = new ResearcherLink(link.url, title, description);
            newLink.setHiddenFromPublicProfile(link.hiddenFromPublicProfile);
            this.addLink(newLink);
        });
        const curAffiliations = data.curAffiliations;
        curAffiliations.forEach((affiliation) => {
            this.addCurAffiliation(affiliation);
        });

        const prevAffiliations = data.prevAffiliations;
        prevAffiliations.forEach((affiliation) => {
            this.addPrevAffiliation(affiliation);
        });
        this.sortAffiliationByOrder();
        data.phones?.forEach((phone) => {
            this.addPhone(phone);
        });
        data.addresses?.forEach((address) => {
            this.addAddress(address);
        });
        data.engagements?.forEach((engagement) => {
            this.addEngagement(engagement);
        });
        // this.setEngagements(data.engagements);
        data.proxies.forEach((proxy) => {
            this.addProxy(proxy);
            const userId = proxy.userID;
            this.researcherDataService
                .getResearcherProfileImage(userId)
                .subscribe((imgBlob) => {
                    if (imgBlob) {
                        this.proxiesPhotos[userId] =
                            window.URL.createObjectURL(imgBlob);
                    } else {
                        this.profileSvc
                            .getResearcherDataForSEO(userId)
                            .subscribe((data) => {
                                this.proxiesPhotos[userId] =
                                    data.researcherImageUrl ?? '';
                            });
                    }
                });
        });
        this.updateNumOfTotalAddresses(data.numOfTotalAddresses);

        data.educations.forEach((education) => {
            this.addEducation(education);
        });

        this.updateDisplayTitle(data.displayTitle);
        this.numberTypesHiddenConfMap = data.contactInformationHiddenConfMap;
    }

    sortAffiliationByOrder() {
        const current = this.curAffiliations.value;
        current.sort(SortUtils.affiliationSortByOrder);
        this.curAffiliations.patchValue(current);

        const past = this.prevAffiliations.value;
        past.sort(SortUtils.affiliationSortByOrder);
        this.prevAffiliations.patchValue(past);
    }

    getIndexOfPreferredName() {
        let i;
        for (i = 0; i < this.names.value.length; i++) {
            if (this.names.value[i].isDefaultName) {
                return i;
            }
        }
        return -1;
    }

    getIndexOfPreferredEmail() {
        let i;
        for (i = 0; i < this.emails.value.length; i++) {
            if (this.emails.value[i].isPreferred) {
                return i;
            }
        }
        return -1;
    }

    getEmailListLength() {
        return this.emails.length;
    }

    getPhoneListLength() {
        return this.phones.length;
    }

    setProfileImageUrl(image) {
        this.researcherDataService.setProfileImageUrl(image);
    }

    removeProfileImage(userID) {
        return this.researcherDataService.removeProfileImage(userID);
    }

    get imageBlobUrl() {
        return this.researcherDataService.imageBlobUrl;
    }

    get lastChosenOrganization(): string {
        return this._lastChosenOrganization;
    }

    set lastChosenOrganization(value: string) {
        this._lastChosenOrganization = value;
    }

    get lastChosenDepartment(): string {
        return this._lastChosenDepartment;
    }

    set lastChosenDepartment(value: string) {
        this._lastChosenDepartment = value;
    }

    get researcherDisplayTitle(): string {
        return this._researcherDisplayTitle;
    }

    delay(fn, delay?) {
        const timeout = delay ? delay : 200;
        setTimeout(fn, timeout);
    }
}
