import { Component, OnInit, OnDestroy, Input, ViewChild, ElementRef, OnChanges, inject } from '@angular/core';
import {
    UntypedFormControl,
    UntypedFormBuilder,
    UntypedFormArray,
    UntypedFormGroup,
    FormsModule,
    ReactiveFormsModule,
} from '@angular/forms';
import {
    Subject,
    debounceTime,
    distinctUntilChanged,
    take,
    takeUntil,
} from 'rxjs';
import {
    GrowInAnimation,
    GrowOutAnimation,
    AriaProgressBarDirective,
    PortionListComponent,
    PortionListItemComponent,
} from '@exl-ng/mulo-common';
import { DepositANZfieldsService } from './deposit-anz-fields.service';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import {
    ResearchTopic,
    ANZTopic,
} from '../../../shared/interfaces/research-topic.intreface';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../../shared/pipes/highlight-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatOption } from '@angular/material/core';
import { EspMatAutocompleteA11yDirective } from '../../../shared/directives/a11y/mat-autocomplete-extension.directive';
import { MatProgressBar } from '@angular/material/progress-bar';
import {
    MatAutocompleteTrigger,
    MatAutocomplete,
} from '@angular/material/autocomplete';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-anz-fields',
    templateUrl: './deposit-anz-fields.component.html',
    styleUrls: ['./deposit-anz-fields.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
    standalone: true,
    imports: [
        MatFormField,
        MatLabel,
        MatInput,
        FormsModule,
        MatAutocompleteTrigger,
        ReactiveFormsModule,
        MatProgressBar,
        AriaProgressBarDirective,
        MatAutocomplete,
        EspMatAutocompleteA11yDirective,
        MatOption,
        PortionListComponent,
        PortionListItemComponent,
        TranslateModule,
        HighlightFilterPipe,
        CustomTranslatePipe,
    ],
})
export class DepositAnzFieldsComponent implements OnInit, OnDestroy {
    private depositANZfieldsService = inject(DepositANZfieldsService);
    depositFormDataService = inject(DepositFormDataService);
    private formBuilder = inject(UntypedFormBuilder);
    private el = inject(ElementRef);

    @Input() jump;
    @Input() placeholder;
    @Input() purpose: 'for' | 'seo';
    @Input() selectedFields: UntypedFormArray;
    anzFields;
    distribute;
    keywordCtrl: UntypedFormControl = new UntypedFormControl();
    filteredTopics: ResearchTopic[];
    private topicsDestroy = new Subject<void>();
    inProgress = false;

    haveOldValues: boolean;

    @ViewChild('topicInput', { static: false }) topicInput;

    ngOnInit() {
        this.listenToSearchInput();

        // TODO - Remove in Sprint Januar of Februar 2022
        this.selectedFields?.valueChanges.pipe(take(1)).subscribe((value) => {
            this.haveOldValues = value?.some((topic) => !topic.label);
            if (this.haveOldValues) {
                while (this.selectedFields.length !== 0) {
                    this.selectedFields.removeAt(0);
                }
            }
        });
        // TODO END #
    }

    listenToSearchInput() {
        this.keywordCtrl.valueChanges
            .pipe(
                debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntil(this.topicsDestroy),
            )
            .subscribe((value) => {
                if (value && value.length >= DepositForm.MIN_INPUT_LENGTH) {
                    this.inProgress = true;
                    this.getResearchTopics(value);
                }
            });
    }

    getResearchTopics(value) {
        this.depositANZfieldsService
            .getResearchTopics(value, this.purpose)
            .subscribe(
                (data) => {
                    this.inProgress = false;
                    if (data) {
                        this.filteredTopics = data as ResearchTopic[];
                    } else {
                        this.noDataFound();
                    }
                },
                (error) => {
                    this.inProgress = false;
                    this.noDataFound();
                },
            );
    }

    noDataFound() {
        this.filteredTopics = [];
    }

    onFieldSelected(item) {
        // reorganize precent
        if (!this.isFieldExists(item.code)) {
            this.selectedFields.push(
                this.formBuilder.group(this.createANZTopic(item)),
            );
            if (this.selectedFields.length === 1) {
                this.distribute = 'descending';
            }
            if (this.distribute) {
                this.distributePortions();
            }
            this.filteredTopics = null;
        }
    }

    isFieldExists(code) {
        const array = this.selectedFields.value.filter(
            (field) => field.code === code,
        );
        if (array.length > 0) {
            return true;
        }
        return false;
    }

    createANZTopic(item: ResearchTopic) {
        return new ANZTopic(item.actualCode, item.actualDescription, 0);
    }

    onRemove(index) {
        if (this.selectedFields.length === 2) {
            this.distribute = 'descending';
        }
        if (this.distribute) {
            this.selectedFields.removeAt(index);
            this.distributePortions();
        } else {
            this.onChange(0, index);
            this.selectedFields.removeAt(index);
        }
    }

    onChange(val, index) {
        this.distribute = '';
        const change = val - this.selectedFields.value[index].portion;
        if (this.selectedFields.length > 1) {
            const sumOther = this.roundToTwo(
                this.sum - this.selectedFields.value[index].portion,
            );
            let delta;
            if (sumOther === 0) {
                delta = change / (this.selectedFields.length - 1);
                for (
                    let i = 0, length = this.selectedFields.length;
                    i < length;
                    i++
                ) {
                    if (i !== index) {
                        this.setPortion(i, -delta);
                    }
                }
            } else {
                delta = change / sumOther;
                for (
                    let i = 0, length = this.selectedFields.length;
                    i < length;
                    i++
                ) {
                    if (i !== index) {
                        const newPortion =
                            this.selectedFields.value[i].portion -
                            delta * this.selectedFields.value[i].portion;
                        this.setPortion(i, newPortion);
                    }
                }
            }
            this.setPortion(index, val);
            this.roundTo100();
        }
    }

    onDistributePortions(method) {
        this.distribute = method;
        this.distributePortions();
    }

    distributePortions() {
        if (this.distribute === 'even') {
            for (let i = 0; i < this.selectedFields.length; i++) {
                this.setPortion(i, 100 / this.selectedFields.length);
            }
            // distribute portions in chosen order
        } else {
            const n = this.selectedFields.length;
            const singleVal = 200 / (n * (n + 1));
            if (this.distribute === 'descending') {
                for (let i = 0; i < n; i++) {
                    this.setPortion(i, singleVal * (n - i));
                }
            } else if (this.distribute === 'ascending') {
                for (let i = 0; i < n; i++) {
                    this.setPortion(i, singleVal * (i + 1));
                }
            }
        }
        this.roundTo100();
    }

    setPortion(index, newPortion) {
        const anzField = this.selectedFields.at(index) as UntypedFormGroup;
        anzField.setControl(
            'portion',
            this.formBuilder.control(this.roundToTwo(newPortion)),
        );
    }

    roundToTwo(num) {
        if (num) {
            return Number(num.toFixed(2));
        }
        return 0;
    }

    roundTo100() {
        const delta = 100 - this.sum;
        if (delta === 0 || delta === 100) {
            return;
        } else {
            this.setPortion(0, this.selectedFields.value[0].portion + delta);
        }
    }

    ngOnDestroy() {
        if (!this.topicsDestroy.isStopped) {
            this.topicsDestroy.next();
            //unsubscribe from the subject itself
            this.topicsDestroy.unsubscribe();
        }
    }

    get sum() {
        let sum = 0;
        for (let i = 0, length = this.selectedFields.length; i < length; i++) {
            sum += this.selectedFields.value[i].portion;
        }
        return this.roundToTwo(sum);
    }
}
