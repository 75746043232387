import { Component, OnInit, AfterViewInit, EventEmitter, Input, Output, OnChanges, SimpleChanges, inject } from '@angular/core';
import {
    trigger,
    style,
    transition,
    animate,
    query,
    group,
} from '@angular/animations';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { TranslateModule } from '@ngx-translate/core';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';
import { MatButton } from '@angular/material/button';

@Component({
    selector: 'esp-deposit-stepper-next-button',
    templateUrl: './deposit-stepper-next-button.component.html',
    styleUrls: ['./deposit-stepper-next-button.component.scss'],
    host: { class: 'esp-deposit-stepper-next-button' },
    animations: [
        trigger('slideInOut', [
            transition(':enter', group([
                style({
                    opacity: 0,
                    transform: 'translateX(40%)',
                }),
                animate('.5s .3s cubic-bezier(0.165, 0.84, 0.44, 1)', style({
                    opacity: 1,
                    transform: 'translateX(0)',
                })),
                query('.step-title', [
                    style({
                        opacity: 0,
                        transform: 'translateX(10%)',
                    }),
                    animate('.6s .6s ease', style({
                        opacity: 1,
                        transform: 'translateX(0)',
                    })),
                ]),
            ])),
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: 'translateX(0)',
                }),
                animate('.3s ease', style({
                    opacity: 0,
                    transform: 'translateX(-30%)',
                })),
            ]),
        ]),
        trigger('IconInOut', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateX(40%)',
                }),
                animate('300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1)', style({
                    opacity: 1,
                    transform: 'translateX(0)',
                })),
            ]),
            transition(':leave', [
                style({
                    opacity: 1,
                    transform: 'translateX(0)',
                }),
                animate('300ms 100ms ease', style({
                    opacity: 0,
                    transform: 'translateX(-30%)',
                })),
            ]),
        ]),
        trigger('SpinnerIn', [
            transition(':enter', [
                style({
                    opacity: 0,
                    transform: 'translateX(100%) scale(.5)',
                }),
                animate('300ms 300ms cubic-bezier(0.165, 0.84, 0.44, 1)', style({
                    opacity: 1,
                    transform: 'translateX(0) scale(1)',
                })),
            ]),
        ]),
    ],
    standalone: true,
    imports: [
    MatButton,
    NgClass,
    MatIcon,
    MatProgressSpinner,
    TranslateModule
],
})
export class DepositStepperNextButtonComponent
    implements OnInit, AfterViewInit, OnChanges
{
    depositFormDataService = inject(DepositFormDataService);

    @Input() steps;
    @Input() selectedStep;
    @Input() disabled;
    @Input() rounded = false;
    @Input() loading = false;
    @Output() advance: EventEmitter<any> = new EventEmitter();

    nextButtonWidth;
    nextButtonHidden;
    buttonPadding = 16 * 2 + 24;
    initButtonWidth = 110;
    LAST_STEP = 4;

    constructor() {
        this.nextButtonWidth = 0;
        this.nextButtonHidden = true;
    }

    ngOnInit() {}

    ngOnChanges(changes: SimpleChanges) {
        if (changes['loading']) {
            this.setButtonWidth(20);
        }
    }

    ngAfterViewInit() {
        setTimeout(() => {
            this.nextButtonHidden = false;
        }, 300);
    }

    onButtonClick() {
        this.advance.emit();
    }

    slideStart(event) {
        if (event.fromState === 'void') {
            this.setButtonWidth(event.element.clientWidth);
            setTimeout(() => this.setButtonWidth(event.element.clientWidth));
        }
    }

    setButtonWidth(width) {
        this.nextButtonWidth = width + this.buttonPadding + 'px';
    }

    getAriaLabel() {
        if (this.selectedStep === this.LAST_STEP) {
            return 'research.aria.deposit.submit';
        }
        return 'research.aria.deposit.next.step';
    }
}
