<button
  mat-button
  attr.aria-label="{{ 'research.aria.header.menu.open' | translate }}"
  [matMenuTriggerFor]="userMatMenu"
  #menuTrigger="matMenuTrigger"
  >
  @if (hasProxy) {
    <mulo-avatar
      class="proxy-avatar"
      [image]="proxyAvatar"
      [initials]="proxyInitials"
        [description]="
            'research.alt.profile.img.with.name'
                | translate: { value: proxyName }
        "
      size="30"
      [radius]="4"
    ></mulo-avatar>
  }

  <span [class.bracketed-avatar]="hasProxy">
    @if (hasProxy) {
      <span class="avatar-bracket">&#123;</span>
    }
    <mulo-avatar
      [image]="avatar"
      [initials]="userInitials"
            [description]="
                'research.alt.profile.img.with.name'
                    | translate: { value: userName }
            "
      [size]="hasProxy ? '24' : '30'"
      [radius]="4"
      [shape]="'square'"
    ></mulo-avatar>
    @if (hasProxy) {
      <span class="avatar-bracket">&#125;</span>
    }
  </span>

  <mat-icon svgIcon="menu-down" class="menu-icon"></mat-icon>
</button>
