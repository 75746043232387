import { Component, Input, ContentChildren, QueryList } from '@angular/core';
import { momentObjParse } from '../../utils';
import { DictionaryItemComponent, DictionaryItemContentDirective } from './dictionary-item/dictionary-item.component';


@Component({
    selector: 'mulo-dictionary',
    templateUrl: './dictionary.component.html',
    styleUrls: ['./dictionary.component.scss'],
    host: { class: 'mulo-dictionary' },
    standalone: true,
    imports: [
    DictionaryItemComponent,
    DictionaryItemContentDirective
],
})
export class DictionaryComponent {
  @Input() items: Map<string, any>;
  @Input() hideEmptyFields: boolean = true;
  @Input() alignLabels: 'left' | 'right' = 'left';
  @Input() display: 'column' | 'rows' | 'auto-rows' = 'rows';
  private _itemValueCache = new Map<string, any>();
  private _itemParseCache = new Map<string, string>();

  constructor() {}

  keys = (obj: object) => Object.keys(obj);

  parseValue(key, value: any = this.items[key]) {
    if (this._itemParseCache.has(key) && this._itemValueCache[key] === value) {
      // Angular executes template expressions after every change detection cycle
      // it's best to cache if possible, but check if actually changed
      return this._itemParseCache.get(key);
    }

    this._itemValueCache.set(key, value);
    let returnValue = value;

    if (Array.isArray(value)) {
      returnValue = value
        .map((valPart, idx) => this.parseValue(key + `[${idx}]`, valPart))
        .join(', ');
    } else if (typeof value === 'object' && value !== null) {
      // try to determine the object's type
      try {
        // start by trying to construct a valid date
        // (using try instead of if branching due to complexity)
        returnValue = momentObjParse(value).utc().format('LLL');
      } catch (error) {
        if (value.hasOwnProperty('type')) {
          // if we're lucky, the object will tell us what it is
          switch (value.type) {
            case 'person':
            default:
              returnValue = value.name?.trim();
              break;
          }
        } else if (value.hasOwnProperty('nativeName')) {
          // language objects
          returnValue = value.name || value.nativeName;
        } else if (value.hasOwnProperty('name')) {
          returnValue = value.name.trim();
        }
      }

      if (returnValue === value) {
        // if we haven't found the type, at least stringify the obj
        // so we don't get [object Object]
        returnValue = JSON.stringify(value);
      }
    }

    returnValue = returnValue?.toString();
    this._itemParseCache.set(key, returnValue);
    return returnValue;
  }
}
