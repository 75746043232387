import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ConfirmDialogComponent } from '@exl-ng/mulo-common';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable, map, shareReplay } from 'rxjs';
import { ConfigurationHandlerService } from '../../core/configuration-handler.service';
import { I18nService } from '../../core/i18n.service';
import { RestApiBase } from '../../shared/configurations/rest-api-base.config';
import { EsploroEntity } from '../../shared/interfaces/esploro-entity';
import { UrlUtils } from '../../shared/utils/url.utils';

@Injectable({
    providedIn: 'root',
})
export class MultiLanguageService {
    private i18Service = inject(I18nService);
    private http = inject(HttpClient);
    private translate = inject(TranslateService);
    private matDialog = inject(MatDialog);
    private cnfigurationHandlerService = inject(ConfigurationHandlerService);

    institutionCode: string;

    _languages: BehaviorSubject<EsploroEntity[]> = new BehaviorSubject([]);
    public readonly languages$: Observable<EsploroEntity[]> =
        this._languages.asObservable();

    _preferredsLanguages: BehaviorSubject<EsploroEntity[]> =
        new BehaviorSubject([]);
    public readonly preferredsLanguages$: Observable<EsploroEntity[]> =
        this._preferredsLanguages.asObservable();

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
        this.getPreferredsLanguages();
    }

    getLanguages() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );

        const t = (_) => this.translate.instant(_);
        const sortList$ = map((table: string[]) =>
            table
                .sort((a, b) => t(a)?.localeCompare(t(b)))
                .map(
                    (item) =>
                        ({
                            code: item,
                            active: true,
                            value: this.getCodeLang(item),
                        } as EsploroEntity)
                )
        );

        return this.http
            .get(RestApiBase.LANGUAGES_LOOKUP, { params })
            .pipe(shareReplay(), sortList$)
            .subscribe((languages) => this._languages.next(languages));
    }

    getPreferredsLanguages() {
        const params = new HttpParams().set(
            'institution',
            this.institutionCode
        );

        const t = (_) => this.translate.instant(_);
        const sortList$ = map((table: string[]) =>
            table
                .sort((a, b) => t(a)?.localeCompare(t(b)))
                .map(
                    (item) =>
                        ({
                            code: 'research.lang.' + item,
                            active: true,
                            value: item,
                        } as EsploroEntity)
                )
        );

        return this.http
            .get(RestApiBase.LANGUAGES_MULTI_LINGUAL, { params })
            .pipe(shareReplay(), sortList$)
            .subscribe((preferredsLanguages) => {
                if (preferredsLanguages?.length > 0) {
                    this._preferredsLanguages.next(preferredsLanguages);
                    this.getLanguages();
                }
            });
    }

    getCodeLang = (value) => {
        return value.split(/(research.lang.)/)[2];
    };

    defaultLangKey = () => {
        return this.cnfigurationHandlerService.isMultiLingualEnabled()
            ? this.get3CharLanguage()
            : this.cnfigurationHandlerService.getUndefinedLanguage();
    };

    showRemoveLanguageConfirmationDialog() {
        // using t for translate function to increase readability
        const t = (code, params?) => this.translate.instant(code, params);

        const dialogRef = this.matDialog.open(ConfirmDialogComponent, {
            data: {
                title: t(
                    'research.portal.multiLanguages.removeItem.confirmDialog.title'
                ),
                message: t(
                    'research.portal.multiLanguages.removeItem.confirmDialog.message'
                ),
                actions: {
                    cancel: t('research.aria.dialog.cancel'),
                    confirm: t('research.aria.icon.button.remove'),
                },
            },
        });
        return dialogRef.afterClosed();
    }

    get3CharLanguage(lang?: string) {
        switch (lang || this.i18Service.getLanguage()) {
            case 'da':
                return 'dan';
            case 'de':
                return 'ger';
            case 'en':
                return 'eng';
            case 'es':
                return 'spa';
            case 'fr':
                return 'fre';
            case 'it':
                return 'ita';
            case 'zh':
            case 'zh-tw':
                return 'chi';
            case 'tw':
                return 'twi';
            case 'ar':
                return 'ara';
            case 'sv':
                return 'swe';
            default:
                return 'eng';
        }
    }
}
