import { Component, OnInit, Input, ElementRef, inject } from '@angular/core';

@Component({
    selector: 'mulo-horizontaller-item',
    templateUrl: './horizontaller-item.component.html',
    styleUrls: ['./horizontaller-item.component.scss'],
    host: {
        class: 'mulo-horizontaller-item',
        '[style.maxWidth.px]': 'maxWidth',
        '[style.minWidth.px]': 'minWidth',
    },
    standalone: true,
})
export class HorizontallerItemComponent implements OnInit {
  elem = inject(ElementRef);

  @Input() maxWidth: number = 320;
  @Input() minWidth: number = 180;

  ngOnInit(): void {}
}
