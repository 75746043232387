import { Component, OnInit, Input, inject } from '@angular/core';
import { I18nService } from "../../../core/i18n.service";
import { ContentLoaderModule } from '@ngneat/content-loader';


@Component({
    selector: 'exl-citation-brief-placeholder',
    templateUrl: './citation-brief-placeholder.component.html',
    styleUrls: ['./citation-brief-placeholder.component.scss'],
    host: { 'class': 'exl-citation-brief-placeholder' },
    standalone: true,
    imports: [ContentLoaderModule]
})
export class CitationBriefPlaceholderComponent implements OnInit {
    i18nService = inject(I18nService);


    @Input() copies: number;
    repetitions: Array<number> = [];

    ngOnInit() {
        for (let i = 0; i < this.copies; i++) {
            this.repetitions.push(i);
        }
    }

}
