import { Component, HostBinding, Input, OnInit, inject } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';


export interface Position {
  x: number;
  y: number;
}

/**
 *  Avatars are used to show a visual representation of an individual
 */
@Component({
    selector: 'mulo-avatar',
    templateUrl: './avatar.component.html',
    styleUrls: ['./avatar.component.scss'],
    standalone: true,
    imports: [
    MatIcon,
    SvgViewboxDirective
],
})
export class AvatarComponent implements OnInit {
  private sanitizer = inject(DomSanitizer);

  /**
   * @internal
   */
  _image;

  /**
   * @internal
   */
  @HostBinding('class.mulo-avatar') get() {
    return true;
  }

  /**
   *  The url for the image, can be base64 too
   */
  @Input()
  get image() {
    return this._image;
  }
  set image(val: string) {
    if (val == null || val === '') {
      val = null;
      this.imageLoaded = false;
    }
    this._image = this.sanitizer.bypassSecurityTrustResourceUrl(val);
  }

  /**
   * square or round image
   */
  @Input() shape: 'square' | 'round' = 'square';
  /**
   * The description to go into the `alt` attribute
   */
  @Input() description: string;
  /**
   * Image size (1:1 ratio)
   */
  @Input() size: string | number;
  /**
   * Rounded border radius
   */
  @Input() radius: number = 4;
  /**
   * Image position override; helps when the image shouldn’t be centered
   */
  @Input() position: Position;
  /**
   * Inititals to show in the absence of image
   */
  @Input() initials: string;
  /**
   * Image overlay (optional) state
   * @internal
   */
  public overlayVisible: boolean = false;
  /**
   * Flag when avatar image loaded
   * @internal
   */
  imageLoaded: boolean = false;

  /**
   * @internal
   */
  ngOnInit() {}

  get sizeString() {
    // check if size input is a number, or a string (with or without a unit)
    if (typeof this.size === 'number' || /^\d+$/.test(this.size)) {
      return `${this.size}px`;
    } else {
      return this.size;
    }
  }

  /**
   * @internal
   */
  calcFontSize() {
    return this.sanitizer.bypassSecurityTrustStyle(
      `calc((${this.size} * 0.025) * 1rem)`
    );
  }

  /**
   * @internal
   */
  showOverlay() {
    this.overlayVisible = true;
  }

  /**
   * @internal
   */
  hideOverlay() {
    this.overlayVisible = false;
  }
}
