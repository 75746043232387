import { Component, ChangeDetectionStrategy, SimpleChange, Input, ElementRef, inject } from '@angular/core';
import { BrandService } from './brand.service';
import { map, tap } from 'rxjs';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'mulo-brand',
    templateUrl: './brand.component.html',
    styleUrls: ['./brand.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-brand' },
    standalone: true,
    imports: [AsyncPipe],
})
export class BrandComponent {
  service = inject(BrandService);
  private elementRef = inject(ElementRef);

  @Input() label: string = 'Logo image';
  @Input() set logoLight(url: string) {
    this.service.setLogoLight(url);
  }
  @Input() set logoDark(url: string) {
    this.service.setLogoDark(url);
  }
  @Input() set width(val: string | number) {
    this._width = typeof val === 'number' ? this.stringify(val) : val;
    this._minWidth = typeof val === 'number' ? val / 2 : null;
  }
  get width() {
    return this._width;
  }
  get minWidth() {
    return this._minWidth;
  }

  brandLogo$;
  public logoUrls;
  private _width;
  private _minWidth: number = 100;
  private _maxWidth: number = 200;
  opacity: number = 0;

  widthUnit: string = 'px';
  widthless: boolean = false;

  ngOnInit() {
    this.brandLogo$ = this.service.logoUrls$.pipe(
      map((logoUrls) => {
        // get css var for contextual contrast type
        const logoStyle = getComputedStyle(this.elementRef.nativeElement)
          .getPropertyValue('--contrast-type')
          .trim();
        this.logoUrls = logoUrls;
        return logoStyle === 'light' ? logoUrls.light : logoUrls.dark;
      }),
      tap(() => this.resetWidth())
    );
  }

  ngOnChanges(changes: SimpleChange) {
    if (changes['brandLogo'] && !changes['brandLogo'].firstChange) {
      this.resetWidth();
    }
  }

  onImageLoaded(ev) {
    this.calcWidth(ev.target.width);
    this.opacity = 1;
  }

  resetWidth() {
    this._width = 0;
    this._minWidth = 0;
  }

  calcWidth(width) {
    if (width === 0) {
      this.widthless = true;
      this.width = this._maxWidth;
    } else {
      if (width > this._maxWidth) {
        this.width = this._maxWidth;
      } else {
        this.width = width;
      }
    }
  }

  stringify(val) {
    return val.toString() + this.widthUnit.toString();
  }
}
