import { CdkTextareaAutosize, CdkAutofill } from '@angular/cdk/text-field';
import { Component, HostBinding, Output, EventEmitter, Input, ViewChild, NgZone, SimpleChanges, HostListener, inject } from '@angular/core';
import { ChatService } from '../chat.service';
import { take } from 'rxjs';
import { MatIconButton } from '@angular/material/button';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';
import { FormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { InputExpanderDirective } from '../../../directives/input-expander.directive';
import { NgClass } from '@angular/common';
import { MatFormField, MatLabel, MatPrefix, MatSuffix, MatHint, MatError } from '@angular/material/form-field';

@Component({
    selector: 'mulo-chat-input',
    templateUrl: './chat-input.component.html',
    styleUrls: ['./chat-input.component.scss'],
    host: { class: 'mulo-chat-input' },
    standalone: true,
    imports: [
    MatFormField,
    NgClass,
    InputExpanderDirective,
    MatLabel,
    MatInput,
    FormsModule,
    CdkTextareaAutosize,
    CdkAutofill,
    MatIcon,
    MatPrefix,
    MatTooltip,
    MatIconButton,
    MatSuffix,
    MatHint,
    MatError
],
})
export class ChatInputComponent {
  private service = inject(ChatService);
  private ngZone = inject(NgZone);

  @Input() input: string;
  @Input() inputLabel = 'Your reply';
  @Input() placeholder: string;
  @Input() autopopulateDisabled = false;
  @Input() autoPopulatedInfo =
    'The title was auto-populated from your selected asset';
  @Input() multipleLines = true;
  @Input() focus = false;
  @Input() lengthLimit = 1000;
  @Input() expanded = true;
  @Input() showClearButton = true;
  @Input() clearButtonLabel = 'Clear input';
  @Input() required = false;
  @Input() errorMsgHtml = 'This field is <strong>required</strong>';
  @Output() output: EventEmitter<string> = new EventEmitter();

  @ViewChild('autosize') autosize: CdkTextareaAutosize;
  @ViewChild('inputElement') inputElement;

  model;
  autoPopulated = false;
  valid;

  @Output() userEntry: EventEmitter<any> = new EventEmitter();
  @HostBinding('class.is-expanded') inputExpanded = true;
  reply;

  constructor() {
    this.service.userInput$.subscribe((input) => {
      this.input = input;
      this.model = this.input;
    });
  }

  onInput(val) {
    this.reply = val;
    this.service.input = val;
  }

  @HostListener('keyup.enter', ['$event'])
  onKeyup(event) {
    if (event.code === 'Enter' && !event.shiftKey) {
      event.preventDefault();
      if (this.reply) {
        this.userEntry.emit(this.reply);
      }
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.input && changes.input.currentValue) {
      const val = changes.input.currentValue;
      const firstChange = changes.input.firstChange;
      // autopopulate
      if (val !== this.model && !firstChange) {
        this.autoPopulated = val;
        if (!this.expanded) {
          this.triggerResize();
        }
      }
      this.model = this.input;
    } else if (changes.input) {
      this.model = this.input;
    }
    if (changes && changes.focus) {
      setTimeout(() => {
        this.focusOnInput();
      }, 0);
    }
  }

  onInputChange(val) {
    if (val != this.autoPopulated) {
      this.autoPopulated = null;
    }
    if (!this.expanded) {
      this.triggerResize();
    }
    this.onInput(this.model);
  }

  clearInput() {
    this.model = '';
    this.autoPopulated = null;
  }

  triggerResize() {
    // Wait for changes to be applied, then trigger textarea resize.
    this.ngZone.onStable.pipe(take(1)).subscribe(() => {
      setTimeout(() => {
        // this.autosize.reset()
        this.autosize.resizeToFitContent(true);
      }, 0);
    });
  }

  focusOnInput() {
    this.inputElement.nativeElement.focus();
    setTimeout(() => {
      this.focus = false;
    }, 0);
  }

  onAutoFill(event) {
    console.log(event);
  }
}
