<div
  class="altmetric-embed"
  [class.is-visible]="imageLoaded$ | async"
  [attr.data-badge-type]="flavor"
  [attr.data-hide-no-mentions]="true"
  [attr.data-badge-details]="'right'"
  [attr.data-doi]="doi"
  data-link-target="_blank"
  [matMenuTriggerFor]="menu"
  #menuTrigger="matMenuTrigger"
  (mouseenter)="onMouseEnterBadge()"
  (click)="onTriggerClick($event)"
  #altmetricEmbed
  (domChange)="handleDomChange($event)"
  muloDomChange
></div>
@if (score) {
  <div class="score">{{score}}</div>
}

<mat-menu #menu="matMenu" overlapTrigger="true" class="mulo-altmetric-menu">
  <span (mouseleave)="onMouseLeaveMenu()" (mouseenter)="onMouseEnterMenu()">
    @for (item of menuContent; track item) {
      <a
        mat-menu-item
        [attr.href]="item.href"
        target="_blank"
        [style.border-inline-start-color]="item.color"
        style="border-inline-start: 6px solid; height: 32px;"
        >
        <span [innerHTML]="item.html"></span>
      </a>
    }
  </span>
</mat-menu>
