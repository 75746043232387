import { Component, OnInit, ChangeDetectionStrategy, Input, inject } from '@angular/core';
import { LanguageService } from '../language.service';
import {SessionStorageUtilService} from '../../../core/session-storage-util.service';
import {I18nService} from '../../../core/i18n.service';
import {JwtUtilService} from '../../../core/jwt-util.service';
import { TranslateModule } from '@ngx-translate/core';
import { MultipleEventsDirective } from '@exl-ng/mulo-common';

import { FormsModule } from '@angular/forms';
import { MatRadioGroup, MatRadioButton } from '@angular/material/radio';

@Component({
    selector: 'exl-language-select',
    templateUrl: './language-select.component.html',
    styleUrls: ['./language-select.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [MatRadioGroup, FormsModule, MatRadioButton, MultipleEventsDirective, TranslateModule]
})
export class LanguageSelectComponent implements OnInit {
    private service = inject(LanguageService);
    private sessionStorageUtilService = inject(SessionStorageUtilService);
    private i18nService = inject(I18nService);
    private jwtUtilService = inject(JwtUtilService);


    @Input() languages;
    @Input() selectedLanguage;

    ngOnInit() {}


    onLanguageChange(language) {
        this.i18nService.setLanguage(language);
        if (this.isLoggedIn()){
            const langJson = {'language' : language};
            this.service.changeUserLanguage(langJson).subscribe(data =>{
                this.jwtUtilService.storeJwt(data);
            });
        } else {
            this.sessionStorageUtilService.setSessionLanguage(language);
        }
        setTimeout(() => {
            this.service.closeDialog();
        }, 500);

    }

    isLoggedIn(){
        return this.jwtUtilService.isResearcherLoggedIn()
            && !this.jwtUtilService.isUserTypeLoggedIn();
    }

}
