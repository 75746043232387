<nav
  mat-tab-nav-bar
  [muloVerticalTabs]="selectedIndex"
  class="is-scaling"
  [color]="color"
  #navbar
  >
  <ng-content select="[slot='pre-nav']"></ng-content>
  <ng-template #recursiveNavList let-items>
    @for (item of items; track trackItem(i, item); let i = $index) {
      <a
        mat-tab-link
        [routerLink]="item.url ? assessUrl(item.url) : null"
        [fragment]="item.url && containsHash(item.url) ? unHash(item.url) : null"
        [queryParamsHandling]="queryParamsHandling"
        [skipLocationChange]="skipLocationChange"
        [active]="disableActiveIndication ? false : selectedIndex === item.id"
        [class.has-children]="item.children && item.children.length > 0"
        [class.is-expanded]="item.expanded"
        [class.is-toggled]="item.children && item.children.length > expandMinimum && item.toggled"
        [style.margin-inline-start.rem]="item.level"
        (click)="changeItem(item, item.url && containsHash(item.url))"
        >
        @if (itemTemplate) {
          <ng-container
            [ngTemplateOutlet]="itemTemplate"
            [ngTemplateOutletContext]="{ $implicit: item, index: i }"
            >
          </ng-container>
        } @else {
          <span>{{ item.label }}&nbsp;@if (item.length) {
            <span class="counter">{{item.length}}</span>
          }</span>
        }
      </a>
      @if (item.expanded || item.children && item.children.length > expandMinimum && item.toggled) {
        <div
          class="mat-tab-link-children"
          >
          <ng-container
            *ngTemplateOutlet="recursiveNavList; context: { $implicit: item.children }"
            >
          </ng-container>
        </div>
      }
    }
  </ng-template>
  <ng-content select="[slot='post-nav']"></ng-content>
  <ng-container
    *ngTemplateOutlet="recursiveNavList; context: { $implicit: items }"
  ></ng-container>
</nav>
