<mat-drawer-container [hasBackdrop]="false" autosize>
  <mat-drawer #drawer [mode]="'side'" [(opened)]="drawerOpened" (closed)="onDrawerClosed()">
    <button mat-button class="has-icon drawer-button" (click)="drawer.toggle()">
      <mat-icon class="mat-icon-rtl-mirror" svgIcon="menu-open"></mat-icon>
      <span>{{ hideLinksLabel }}</span>
    </button>
    <div class="nav-wrapper">
      @for (link of anchorsArray; track link; let i = $index) {
        <a
          mat-button
          animation-in-child
          color="primary"
          class="is-wrapping"
          (click)="focusOnSection($event, i)"
          href=""
          >
          {{ link }}
        </a>
      }
    </div>
  </mat-drawer>
  <mat-drawer-content>
    <div [innerHTML]="html" class="mulo-html-formatted"></div>
  </mat-drawer-content>
</mat-drawer-container>
