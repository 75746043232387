<span class="overflow-ellipsis margin-right-md">
  <ng-content></ng-content>
</span>
<span class="spacer"></span>
<mat-form-field class="portion-amount no-subscript">
  <input
    [ngModel]="roundedPortion"
    (ngModelChange)="onInputChange($event)"
    matInput
    type="number"
    maxlength="3"
    [max]="max"
    [min]="min"
    [disabled]="pliDisabled"
    [attr.aria-label]="portionInputAriaLabel"
    />
  <span matSuffix>{{ unit }}</span>
</mat-form-field>
@if (hasSlider) {
  <mat-slider
    class="is-round-and-thick"
    color="primary"
    [max]="max"
    [min]="min"
    [discrete]="thumbLabel"
    @widthOutAnimation
    #ngSlider
    ><input
    matSliderThumb
    [value]="roundedPortion"
    (input)="sliderChange({ source: ngSliderThumb, parent: ngSlider, value: ngSliderThumb.value })"
    [attr.aria-label]="portionSliderAriaLabel"
    #ngSliderThumb="matSliderThumb"
    />
</mat-slider>
}
<button mat-icon-button (click)="removeField()" [attr.aria-label]="removeBtnAriaLabel">
  <mat-icon svgIcon="close"></mat-icon>
</button>
