<mat-radio-group [attr.aria-label]="'research.header.language.dialog.title' | translate" [ngModel]="selectedLanguage">
  @for (language of languages; track language) {
    <mat-radio-button
      (click)="onLanguageChange(language)"
      (muloMultipleEvents)="onLanguageChange(language)"
      [attr.cdkFocusInitial]="language === selectedLanguage || null"
      [value]="language"
      >
      <div>
        <span class="semibold">
          {{ language | translate }}
        </span>
        <span> · {{ language.toUpperCase() }} </span>
      </div>
    </mat-radio-button>
  }
</mat-radio-group>
