<div class="inner-wrapper">
  <h2 class="header">{{header}}</h2>
  @if (body) {
    <p>
      <ng-content select="[slot='empty-state-body']"></ng-content>
    </p>
  }
  <div class="extra-content">
    <ng-content></ng-content>
  </div>
</div>
