import { AfterViewInit, Directive, ElementRef, HostBinding, Input, Renderer2 } from '@angular/core';

/**
 * This directivebuild for supporting aoutomation tests only.
 * ! Should not change any logic in Esploro.
 *
 * Add unique classes for requested elements.
 * Add data attribute for requested elements.
 * Suffix should be given to let the addition class be always same for every single element.
 */

@Directive({
    selector: '[exlAutomationClass],[exlAutomationMmsid]',
    host: {
        '[class]': 'class',
        '[attr.data-mmsid]': 'dataMmsid'
    },
    standalone: true
})
export class AutomationClassDirective {
    @Input('exlAutomationClass')
    get class(): string { return this._classSuffix; }
    set class(value: string) {
        this._classSuffix = value ? `automation-${value}` : null;
    }
    private _classSuffix: string;

    @Input('exlAutomationMmsid')
    get dataMmsid() { return this._dataMmsid; }
    set dataMmsid(value: string) {
        this._dataMmsid = value ?? null;
    }
    private _dataMmsid: string;

}
