<div cdkAriaLive="assertive">
  @if (depositFormDataService.mainForm.status === 'INVALID') {
    <esp-deposit-form-section>
      <span class="semibold palette-warn-color" role="alert">
        {{ 'research.general.warning.summary' | customTranslate }}
      </span>
    </esp-deposit-form-section>
  }
</div>

@if (!hideTerms) {
  <esp-deposit-form-section>
    <mat-card appearance="outlined">
      <mat-checkbox [formControl]="depositFormDataService.termsOfUse" class="agree-checkbox is-large">
        <span>
          {{ 'research.terms.click' | customTranslate }}
          <a
            (click)="showTermsDialog($event)"
            (muloMultipleEvents)="showTermsDialog($event)"
            [attr.aria-label]="'research.aria.deposit.terms.and.conditions.open' | translate"
            eventOrigins="keydown.enter,keydown.space"
            target="_blank"
            >
            {{ 'research.terms.terms' | customTranslate }}
          </a>
          {{ 'research.terms.policy' | customTranslate }}
        </span>
      </mat-checkbox>
    </mat-card>
  </esp-deposit-form-section>
}

<esp-deposit-form-section [sectionTitle]="getNotesSectionTitle() | customTranslate">
  @if (!depositLastStepService.summaryNotes) {
    <button
      (click)="showSummaryNotes()"
      class="has-icon"
      color="primary"
      mat-button
      >
      <mat-icon svgIcon="plus"></mat-icon>
      <span>{{ 'research.notes.add.summary' | customTranslate }}</span>
    </button>
  }
  @if (depositLastStepService.summaryNotes) {
    <exl-plain-text-input
      @growInAnimation
      [focus]="notesFocus"
      [input]="depositFormDataService.note"
      [label]="'research.notes.label.summary' | customTranslate"
      [lengthLimit]="4000"
      [multipleLines]="true"
      >
    </exl-plain-text-input>
  }
</esp-deposit-form-section>

<esp-deposit-form-section [sectionTitle]="getCopyrightSectionTitle() | customTranslate" class="margin-top-zero">
  @if (!depositLastStepService.copyrightsNotes) {
    <button
      (click)="showCopyrightsNotes()"
      class="has-icon"
      color="primary"
      mat-button
      >
      <mat-icon svgIcon="plus"></mat-icon>
      <span>{{ 'research.copyright.add.summary' | customTranslate }}</span>
    </button>
  }
  @if (depositLastStepService.copyrightsNotes) {
    <div @growInAnimation class="copyright">
      <exl-date-input
        [autoPopulatedFromDate]="isDateCopyrightedAutoPopulated"
        [fromDate]="depositFormDataService.dateCopyrighted"
        cdkTrapFocus="false"
        cdkTrapFocusAutoCapture="true"
        class="margin-right-md"
        fromDateLabel="research.copyright.date.label.summary"
        >
      </exl-date-input>
      <exl-plain-text-input
        [input]="depositFormDataService.copyright"
        [label]="'research.copyright.label.summary' | customTranslate"
        [lengthLimit]="4000"
        [multipleLines]="true"
        style="inset-block-start: 2px"
        >
      </exl-plain-text-input>
    </div>
  }
</esp-deposit-form-section>

<esp-deposit-form-section [sectionTitle]="'research.asset.title.summary' | customTranslate">
  <esp-deposit-summary (jumpToSection)="jump($event)"></esp-deposit-summary>
</esp-deposit-form-section>
