import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'exl-centered-block-layout',
    templateUrl: './centered-block-layout.component.html',
    styleUrls: ['./centered-block-layout.component.scss'],
    host: { 'class': 'exl-centered-block-layout' },
    standalone: true
})
export class CenteredBlockLayoutComponent implements OnInit {

    @Input() width: 'auto' | 'full' | 'none' = 'auto';

    constructor() { }

    ngOnInit() {
    }

}
