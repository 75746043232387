<button
  mat-raised-button
  class="is-funky-cool"
  (click)="onButtonClick()"
  [style.width]="nextButtonWidth"
    [disabled]="
        disabled ||
        (selectedStep === LAST_STEP && depositFormDataService.mainForm.status === 'INVALID') ||
        (selectedStep === LAST_STEP && !depositFormDataService.termsOfUse.value)
    "
    [ngClass]="{
        'is-hidden': nextButtonHidden || disabled,
        'is-last': selectedStep + 1 == steps?.length - 1,
        'is-rounded': rounded,
        'is-loading': loading
    }"
  >
  @for (step of steps; track step; let i = $index) {
    @if (selectedStep + 1 == step.pos) {
      <span
        class="button-content"
        [@slideInOut]="selectedStep"
        (@slideInOut.start)="slideStart($event)"
        #nextButton
        >
        @if (step.pos < steps?.length - 2 && !loading) {
          <span>
            {{ 'research.top.step' | translate : { value0: step.pos + 1, value1: steps.length - 1 } }}&nbsp;
          </span>
        }
        @if (step.pos === steps?.length - 2 && !loading) {
          <span>
            {{ 'research.top.step4top' | translate }}&nbsp;
          </span>
        }
        @if (!loading) {
          <span class="step-title">{{ step.title | translate }}</span>
        }
      </span>
    }
  }
  @if (selectedStep + 1 < steps?.length - 1) {
    <mat-icon
      class="mat-icon-rtl-mirror"
      svgIcon="chevron-right"
      [@IconInOut]
      >
    </mat-icon>
  }
  @if (selectedStep + 1 == steps?.length - 1 && !loading) {
    <mat-icon
      class="mat-icon-rtl-mirror"
      svgIcon="send"
      [@IconInOut]
      >
    </mat-icon>
  }
  @if (loading) {
    <mat-spinner diameter="30" [@SpinnerIn]></mat-spinner>
  }
</button>
