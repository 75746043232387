import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { TabComponentModel } from '../../../shared/interfaces/tab-component.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DateUtils } from '../../../shared/utils/date.utils';
import { DepositStepThreeService } from './deposit-step-three.service';
import { Status } from '../../../shared/configurations/status.constant';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositTechnicalInformationComponent } from '../../deposit-sections/deposit-technical-information/deposit-technical-information.component';
import { DepositCoverageComponent } from '../../deposit-sections/deposit-coverage/deposit-coverage.component';
import { DepositMethodsComponent } from '../../deposit-sections/deposit-methods/deposit-methods.component';
import { CdkTrapFocus } from '@angular/cdk/a11y';
import { DepositSpatialComponent } from '../../deposit-sections/deposit-spatial/deposit-spatial.component';
import { MatButton } from '@angular/material/button';
import { DepositLanguageComponent } from '../../deposit-sections/deposit-language/deposit-language.component';
import { DepositDatesComponent } from '../../deposit-sections/deposit-dates/deposit-dates.component';
import { DepositPublishedDateComponent } from '../../deposit-sections/deposit-published-date/deposit-published-date.component';
import { DepositPublicationDetailsJournalArticleComponent } from '../../deposit-sections/deposit-status-details/deposit-publication-details-journal-article/deposit-publication-details-journal-article.component';
import { DepositDoiComponent } from '../../deposit-sections/deposit-doi/deposit-doi.component';
import { DepositPublisherComponent } from '../../deposit-sections/deposit-publisher/deposit-publisher.component';
import { DepositStatusComponent } from '../../deposit-sections/deposit-status/deposit-status.component';
import { DepositPresentationInformationComponent } from '../../deposit-sections/deposit-presentation-information/deposit-presentation-information.component';
import { DepositEventInformationComponent } from '../../deposit-sections/deposit-event-information/deposit-event-information.component';
import { DepositConferenceInformationComponent } from '../../deposit-sections/deposit-conference-information/deposit-conference-information.component';
import { DepositPeerReviewedComponent } from '../../deposit-sections/deposit-peer-reviewed/deposit-peer-reviewed.component';
import { DepositFormSectionComponent } from '../../deposit-form-section/deposit-form-section.component';


@Component({
    selector: 'esp-deposit-step-three',
    templateUrl: './deposit-step-three.component.html',
    styleUrls: ['./deposit-step-three.component.scss'],
    standalone: true,
    imports: [
    DepositFormSectionComponent,
    DepositPeerReviewedComponent,
    DepositConferenceInformationComponent,
    DepositEventInformationComponent,
    DepositPresentationInformationComponent,
    DepositStatusComponent,
    DepositPublisherComponent,
    DepositDoiComponent,
    DepositPublicationDetailsJournalArticleComponent,
    DepositPublishedDateComponent,
    DepositDatesComponent,
    DepositLanguageComponent,
    MatButton,
    DepositSpatialComponent,
    CdkTrapFocus,
    DepositMethodsComponent,
    DepositCoverageComponent,
    DepositTechnicalInformationComponent,
    CustomTranslatePipe
],
})
export class DepositStepThreeComponent implements OnInit, OnDestroy {
    depositFormDataService = inject(DepositFormDataService);
    depositStepThreeService = inject(DepositStepThreeService);

    @Input() tab3;

    compDestroy;

    private datePublishedDestroy = new Subject<void>();
    private datePresentedDestroy = new Subject<void>();
    private dateCreatedStartDestroy = new Subject<void>();
    private dateCreatedEndDestroy = new Subject<void>();
    private dateCollectedStartDestroy = new Subject<void>();
    private dateCollectedEndDestroy = new Subject<void>();

    status = Status;

    ngOnInit() {
        this.onChangesDateCreatedStart();
        this.onChangesDateCreatedEnd();
        this.onChangesDateCollectedStart();
        this.onChangesDateCollectedEnd();
        this.onChangesDatePresented();
    }

    displayPeerReviewed(comp: TabComponentModel) {
        return comp.visible && comp.field === 'peer_reviewed';
    }

    displayStatus(comp: TabComponentModel) {
        return comp.visible && comp.field === 'status';
    }

    displayLanguages(comp: TabComponentModel) {
        return comp.visible && comp.field === 'languages';
    }

    displayConferenceInformation(comp: TabComponentModel) {
        return comp.visible && comp.field === 'conference_information';
    }

    displayEventInformation(comp: TabComponentModel) {
        return comp.visible && comp.field === 'event_information';
    }

    displayPresentationInformation(comp: TabComponentModel) {
        return comp.visible && comp.field === 'presentation_information';
    }

    displayDoi(comp: TabComponentModel) {
        return comp.visible && comp.field === 'doi';
    }

    displayDatePublished(comp: TabComponentModel) {
        return comp.visible && comp.field === 'date_published';
    }

    displayDateCreated(comp: TabComponentModel) {
        return comp.visible && comp.field === 'date_created';
    }

    displayDateCollected(comp: TabComponentModel) {
        return comp.visible && comp.field === 'date_collected';
    }

    displayDatePresented(comp: TabComponentModel) {
        return comp.visible && comp.field === 'date_presented';
    }

    displayPublisher(comp: TabComponentModel) {
        return comp.visible && comp.field === 'publisher_name';
    }

    displaySpatial(comp: TabComponentModel) {
        return comp.visible && comp.field === 'spatial';
    }

    displayMethods(comp: TabComponentModel) {
        return comp.visible && comp.field === 'methods';
    }

    displayCoverage(comp: TabComponentModel) {
        return comp.visible && comp.field === 'coverage';
    }

    displayTechnicalInfo(comp: TabComponentModel) {
        return comp.visible && comp.field === 'technical_information';
    }

    dispalyPublicationInformation(comp: TabComponentModel) {
        return comp.visible && comp.field === 'publication_information';
    }

    isRequired(comp) {
        return comp.mandatory;
    }

    onChangesDatePresented(): void {
        this.depositFormDataService.datePresented.valueChanges
            .pipe(takeUntil(this.datePresentedDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.datePresented.value) {
                    this.depositFormDataService.updateYearPresented('');
                    this.depositFormDataService.updateMonthPresented('');
                    this.depositFormDataService.updateDayPresented('');
                } else {
                    const date = new Date(
                        this.depositFormDataService.datePresented.value
                    );
                    this.depositFormDataService.updateYearPresented(
                        DateUtils.getYear(date)
                    );
                    this.depositFormDataService.updateMonthPresented(
                        DateUtils.getMonth(date)
                    );
                    this.depositFormDataService.updateDayPresented(
                        DateUtils.getDay(date)
                    );
                }
            });
    }

    onChangesDateCreatedStart(): void {
        this.depositFormDataService.dateCreatedStart.valueChanges
            .pipe(takeUntil(this.dateCreatedStartDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.dateCreatedStart.value) {
                    this.depositFormDataService.updateYearCreatedStart('');
                    this.depositFormDataService.updateMonthCreatedStart('');
                    this.depositFormDataService.updateDayCreatedStart('');
                } else {
                    const date = new Date(
                        this.depositFormDataService.dateCreatedStart.value
                    );
                    this.depositFormDataService.updateYearCreatedStart(
                        DateUtils.getYear(date)
                    );
                    this.depositFormDataService.updateMonthCreatedStart(
                        DateUtils.getMonth(date)
                    );
                    this.depositFormDataService.updateDayCreatedStart(
                        DateUtils.getDay(date)
                    );
                }
            });
    }

    onChangesDateCreatedEnd(): void {
        this.depositFormDataService.dateCreatedEnd.valueChanges
            .pipe(takeUntil(this.dateCreatedEndDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.dateCreatedEnd.value) {
                    this.depositFormDataService.updateYearCreatedEnd('');
                    this.depositFormDataService.updateMonthCreatedEnd('');
                    this.depositFormDataService.updateDayCreatedEnd('');
                } else {
                    const date = new Date(
                        this.depositFormDataService.dateCreatedEnd.value
                    );
                    this.depositFormDataService.updateYearCreatedEnd(
                        DateUtils.getYear(date)
                    );
                    this.depositFormDataService.updateMonthCreatedEnd(
                        DateUtils.getMonth(date)
                    );
                    this.depositFormDataService.updateDayCreatedEnd(
                        DateUtils.getDay(date)
                    );
                }
            });
    }

    onChangesDateCollectedStart(): void {
        this.depositFormDataService.dateCollectedStart.valueChanges
            .pipe(takeUntil(this.dateCollectedStartDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.dateCollectedStart.value) {
                    this.depositFormDataService.updateYearCollectedStart('');
                    this.depositFormDataService.updateMonthCollectedStart('');
                    this.depositFormDataService.updateDayCollectedStart('');
                } else {
                    const date = new Date(
                        this.depositFormDataService.dateCollectedStart.value
                    );
                    this.depositFormDataService.updateYearCollectedStart(
                        DateUtils.getYear(date)
                    );
                    this.depositFormDataService.updateMonthCollectedStart(
                        DateUtils.getMonth(date)
                    );
                    this.depositFormDataService.updateDayCollectedStart(
                        DateUtils.getDay(date)
                    );
                }
            });
    }

    onChangesDateCollectedEnd(): void {
        this.depositFormDataService.dateCollectedEnd.valueChanges
            .pipe(takeUntil(this.dateCollectedEndDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.dateCollectedEnd.value) {
                    this.depositFormDataService.updateYearCollectedEnd('');
                    this.depositFormDataService.updateMonthCollectedEnd('');
                    this.depositFormDataService.updateDayCollectedEnd('');
                } else {
                    const date = new Date(
                        this.depositFormDataService.dateCollectedEnd.value
                    );
                    this.depositFormDataService.updateYearCollectedEnd(
                        DateUtils.getYear(date)
                    );
                    this.depositFormDataService.updateMonthCollectedEnd(
                        DateUtils.getMonth(date)
                    );
                    this.depositFormDataService.updateDayCollectedEnd(
                        DateUtils.getDay(date)
                    );
                }
            });
    }

    firstCompUnderPublishedQuestion() {
        const tab3Comp = this.getTab3CompFields();
        if (tab3Comp.indexOf('publisher_name') !== -1) {
            return 'publisher_name';
        }
        if (tab3Comp.indexOf('doi') !== -1) {
            return 'doi';
        }
        if (tab3Comp.indexOf('date_published') !== -1) {
            return 'date_published';
        }
        return '';
    }

    firstCompUnderAdditionalData() {
        const tab3Comp = this.getTab3CompFields();
        if (tab3Comp.indexOf('geospatial_info') !== -1) {
            return 'geospatial_info';
        }
        if (tab3Comp.indexOf('methods') !== -1) {
            return 'methods';
        }
        if (tab3Comp.indexOf('coverage') !== -1) {
            return 'coverage';
        }
        if (tab3Comp.indexOf('technical_information') !== -1) {
            return 'technical_information';
        }
        return '';
    }

    getTab3CompFields() {
        return this.tab3
            .filter((comp) => comp.visible)
            .map((comp) => {
                return comp.field;
            });
    }

    matchFirstCompUnderPublishedQuestion(comp) {
        const first = this.firstCompUnderPublishedQuestion();
        if (first && comp.field === first) {
            return true;
        }
        return false;
    }

    matchFirstCompUnderAdditionalData(comp) {
        const first = this.firstCompUnderAdditionalData();
        if (first && comp.field === first) {
            return true;
        }
        return false;
    }

    isDataset() {
        return this.depositFormDataService.isDataset();
    }

    isPublicationMoreInformation() {
        return this.depositFormDataService.isPublicationMoreInformation();
    }

    ngOnDestroy() {
        if (!this.datePublishedDestroy.isStopped) {
            this.datePublishedDestroy.next();
            //unsubscribe from the subject itself
            this.datePublishedDestroy.unsubscribe();
        }
        if (!this.datePresentedDestroy.isStopped) {
            this.datePresentedDestroy.next();
            this.datePresentedDestroy.unsubscribe();
        }
        if (!this.dateCreatedStartDestroy.isStopped) {
            this.dateCreatedStartDestroy.next();
            this.dateCreatedStartDestroy.unsubscribe();
        }
        if (!this.dateCreatedEndDestroy.isStopped) {
            this.dateCreatedEndDestroy.next();
            this.dateCreatedEndDestroy.unsubscribe();
        }
        if (!this.dateCollectedStartDestroy.isStopped) {
            this.dateCollectedStartDestroy.next();
            this.dateCollectedStartDestroy.unsubscribe();
        }
        if (!this.dateCollectedEndDestroy.isStopped) {
            this.dateCollectedEndDestroy.next();
            this.dateCollectedEndDestroy.unsubscribe();
        }
    }
}
