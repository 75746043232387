import {
  Component,
  computed,
  EventEmitter,
  inject,
  input,
  Output,
} from '@angular/core';
import { MatMenuItem } from '@angular/material/menu';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton } from '@angular/material/button';
import { MatSlideToggle } from '@angular/material/slide-toggle';

import { MediaService } from '@exl-ng/mulo-core';
import { TranslateModule } from '@ngx-translate/core';

import {
  ActionListItemComponent,
  MULO_ACTION_LIST_ITEM,
} from '../action-list-item.component';
import { VisibilitySwitchComponent } from '../../visibility-switch';
import { MultipleEventsDirective } from '../../../directives';

@Component({
  selector: 'mulo-action-list-item-action',
  templateUrl: './action-list-item-action.component.html',
  styleUrls: ['./action-list-item-action.component.scss'],
  standalone: true,
  imports: [
    MatSlideToggle,
    VisibilitySwitchComponent,
    MatIconButton,
    MatIcon,
    MatMenuItem,
    TranslateModule,
    MultipleEventsDirective,
  ],
})
export class ActionListItemActionComponent {
  media = inject(MediaService);

  labels = input<string[]>([]);
  type = input<'visibility' | 'icon-btn' | 'toggle'>();
  data = input<any>();
  icon = input<string>();
  rtlMirrorIcon = input<boolean>();
  @Output() actionEvent = new EventEmitter();
  disabled = input(false);
  private readonly actionItem = inject(MULO_ACTION_LIST_ITEM, {
    optional: true,
  }) as ActionListItemComponent;
  isDisabled = computed(() => {
    // Disable action if parent item disabled or if set to be disabled
    return this.disabled() || !!(this.actionItem && this.actionItem.disabled);
  });

  onActionClick(event) {
    if (event?.stopPropagation) {
      event?.stopPropagation();
    }
    if (event?.preventDefault) {
      event?.preventDefault();
    }
    this.actionEvent.emit(event);
  }
}
