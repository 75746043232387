import { Component, EventEmitter, HostBinding, Input, Output, QueryList, ViewChildren, inject } from '@angular/core';
import { UrlUtils } from '../../../shared/utils/url.utils';
import { I18nService } from '../../../core/i18n.service';
import { MatMenuItem } from '@angular/material/menu';
import { Router } from '@angular/router';
import {
    MAT_BOTTOM_SHEET_DATA,
    MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { TranslateModule } from '@ngx-translate/core';
import { MatDivider } from '@angular/material/divider';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'exl-user-menu-panel',
    templateUrl: './user-menu-panel.component.html',
    styleUrls: ['./user-menu-panel.component.scss'],
    exportAs: 'menu-panel',
    standalone: true,
    imports: [MatMenuItem, MatIcon, MatDivider, TranslateModule],
})
export class UserMenuPanelComponent {
    data = inject(MAT_BOTTOM_SHEET_DATA);
    private bottomSheetRef = inject<MatBottomSheetRef<UserMenuPanelComponent>>(MatBottomSheetRef);
    private i18nService = inject(I18nService);
    private router = inject(Router);

    institutionCode = UrlUtils.getParam('institution');

    @Input() userName;
    @Input() proxyName;
    @Input() queryParams = '';
    @Input() menuItems;
    @Output() signOut: EventEmitter<any> = new EventEmitter();
    @Output() action: EventEmitter<string> = new EventEmitter();

    @ViewChildren(MatMenuItem) matMenuItems: QueryList<MatMenuItem>;

    @HostBinding('class') class = 'exl-user-menu-panel';
    @HostBinding('role') role;
    constructor() {
        const bottomSheetRef = this.bottomSheetRef;

        if (bottomSheetRef.containerInstance) {
            this.role = 'menu';
            this.populateValuesFromKeys();
        }
    }
    populateValuesFromKeys() {
        for (const [key, value] of Object.entries(this.data)) {
            if (key === 'menuItems') {
                this.menuItems = value;
            }
            if (key === 'userName') {
                this.userName = value as string;
            }
            if (key === 'proxyName') {
                this.proxyName = value;
            }
        }
        this.signOut = this.data.signOut;
    }

    doSignOut() {
        this.signOut.emit();
    }

    onItemClick(item) {
        if (item.action) {
            this.onItemAction(item.action);
        } else if (item.link) {
            this.onItemRoute(item.link);
        }
    }

    onItemRoute(link) {
        this.router.navigate([link], {
            queryParams: { institution: this.institutionCode },
        });
        if (this.bottomSheetRef?.dismiss) {
            this.bottomSheetRef?.dismiss();
        }
    }

    onItemAction(action) {
        const sheet = this.bottomSheetRef;
        sheet.containerInstance
            ? sheet.dismiss(action)
            : this.action.emit(action);
    }

    getCurrentLanguage() {
        return this.i18nService.getLanguage();
    }

    getLabel(item) {
        if (item.label) {
            return item.label;
        }
        return 'research.aria.header.language.menu';
    }
}
