import { Injectable, inject } from '@angular/core';
import { UrlUtils } from '../shared/utils/url.utils';
import { ConfigurationHandlerService } from './configuration-handler.service';
import {
    MuloMatCssVarsService,
    MatCssAdditionalPaletteContrastNames,
} from '@exl-ng/mulo-common';

@Injectable({
    providedIn: 'root',
})
export class CustomizationService {
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private cssVarsExtraService = inject(MuloMatCssVarsService);

    institutionCode;

    constructor() {
        this.institutionCode = UrlUtils.getParam('institution');
    }

    getThemeByConfig(elem: string) {
        const customization =
            this.configurationHandlerService.getCustomizationThemeing();
        const palette = customization?.elements[elem];
        const colorHex = customization?.colors[palette];
        if (!colorHex) {
            //dark text for default white bacground
            return MatCssAdditionalPaletteContrastNames.dark;
        }
        const contrast = this.cssVarsExtraService.assessContrast(colorHex);
        return contrast;
    }
}
