import { Component, ChangeDetectionStrategy, Input } from '@angular/core';
import { OrcidLogoComponent } from '../orcid-logo/orcid-logo.component';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconAnchor } from '@angular/material/button';


@Component({
    selector: 'mulo-orcid-link',
    templateUrl: './orcid-link.component.html',
    styleUrls: ['./orcid-link.component.scss'],
    host: { class: 'mulo-orcid-link' },
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [
    MatIconAnchor,
    MatTooltip,
    OrcidLogoComponent
],
})
export class OrcidLinkComponent {
  /** The orcid identifier string */
  @Input() orcidId: string;
  /** Show as logo only, identifier only, or logo + identifier */
  @Input() show: 'logo' | 'id' | 'both' = 'both';
  /** Tooltip for the logo (link to the orcid website) */
  @Input() logoTooltip: string = 'ORCID website';
  /** Description for the URL to the researcher page on the ORCID website */
  @Input() idDescription: string = 'ORCID page';
  /** Whether to show the ID as link or not */
  @Input() showAsLink: boolean = true;
}
