import { Component, OnInit, ChangeDetectionStrategy, Input, ElementRef, inject } from '@angular/core';
import { ColorComponentMixinBase } from '../../../common-behavior/color';

import { OrcidLogoComponent } from '../orcid-logo/orcid-logo.component';
import { MatAnchor } from '@angular/material/button';

@Component({
    selector: 'mulo-orcid-connect',
    templateUrl: './orcid-connect.component.html',
    styleUrls: ['./orcid-connect.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-orcid-connect' },
    inputs: ['color'],
    standalone: true,
    imports: [
    MatAnchor,
    OrcidLogoComponent
],
})
export class OrcidConnectComponent extends ColorComponentMixinBase
  implements OnInit {
  @Input() linkText: string;
  @Input() linkUrl: string = '#';
  @Input() description: string;

  constructor() {
    const elementRef = inject(ElementRef);

    super(elementRef);
  }

  ngOnInit(): void {}
}
