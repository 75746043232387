import {
    Component,
    OnInit,
    Input,
    Output,
    ViewChild,
    EventEmitter,
} from '@angular/core';

import { MatMenu, MatMenuItem, MatMenuTrigger } from '@angular/material/menu';

@Component({
    selector: 'exl-picker-item',
    templateUrl: './picker-item.component.html',
    styleUrls: ['./picker-item.component.scss'],
    standalone: true,
    imports: [MatMenu, MatMenuItem, MatMenuTrigger],
})
export class PickerItemComponent implements OnInit {
    @Input() items;
    @Output() selected: EventEmitter<any> = new EventEmitter();
    @ViewChild('childMenu', { static: false }) public childMenu;

    constructor() {}

    ngOnInit() {}

    onMenuItemClick(item) {
        this.selected.emit(item);
    }
}
