import { Component, OnInit, Input } from '@angular/core';

@Component({
    selector: 'exl-labeled-row',
    templateUrl: './labeled-row.component.html',
    styleUrls: ['./labeled-row.component.scss'],
    standalone: true
})
export class LabeledRowComponent implements OnInit {

    @Input() label;

    constructor() { }

    ngOnInit() {
    }

}
