import {
  Component,
  Input,
  Directive,
  HostBinding,
  ContentChildren,
  QueryList,
  ContentChild,
  ViewChild,
  ElementRef,
  AfterViewInit,
} from '@angular/core';
import { MatAccordionDisplayMode, MatAccordion } from '@angular/material/expansion';
import { FileListItemComponent } from './file-list-item/file-list-item.component';
import { MatList } from '@angular/material/list';
import { NgTemplateOutlet } from '@angular/common';

@Component({
    selector: 'mulo-file-list',
    templateUrl: './file-list.component.html',
    styleUrls: ['./file-list.component.scss'],
    host: { class: 'mulo-file-list' },
    standalone: true,
    imports: [
    MatAccordion,
    NgTemplateOutlet,
    MatList
],
})
export class FileListComponent implements AfterViewInit {
  @Input() expandAllButton: string;
  @Input() multiOpen = true;
  @Input() displayMode: MatAccordionDisplayMode = 'default';
  @Input() listStyle: 'accordion' | 'list' = 'accordion';

  @ContentChildren(FileListItemComponent) items: QueryList<
    FileListItemComponent
  >;

  @ViewChild('listTitle', { static: true }) listTitle: ElementRef;
  title = false;

  ngAfterViewInit() {
    this.title = this.listTitle?.nativeElement?.children?.length > 0;
  }

  expandAll() {
    this.items.toArray().forEach((item) => item.expand());
  }
  collapseAll() {
    this.items.toArray().forEach((item) => item.expand());
  }
}

@Directive({
    selector: '[mulo-file-list-action]',
    standalone: true,
})
export class FileListActionDirective {
  @HostBinding('class.mulo-file-list-action') class = true;
}
