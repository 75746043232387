import { Component, Input } from '@angular/core';
import { PickerItemComponent } from './picker-item/picker-item.component';
import { MatIcon } from '@angular/material/icon';
import { MatMenuTrigger } from '@angular/material/menu';
import { MatMiniFabButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'exl-picker',
    templateUrl: './picker.component.html',
    styleUrls: ['./picker.component.scss'],
    standalone: true,
    imports: [
    MatMiniFabButton,
    MatMenuTrigger,
    MatIcon,
    PickerItemComponent,
    MatButton
],
})
export class PickerComponent {
    @Input() items;
    @Input() pickerIcon;
    @Input() rtlMirrorIcon: boolean;
    public navItems = [];

    constructor() {
        const list = {};
        setTimeout(() => {
            list['name'] = 'All';
            list['children'] = this.items;
            this.navItems.push(list);
        }, 1000);
    }
}
