import { Component, ViewChild, ElementRef, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogClose, MatDialogTitle, MatDialogContent, MatDialogActions } from '@angular/material/dialog';
import { CdkScrollable } from '@angular/cdk/scrolling';
import { MatIcon } from '@angular/material/icon';
import { MatIconButton, MatButton } from '@angular/material/button';


@Component({
    selector: 'mulo-confirm-dialog',
    templateUrl: './confirm-dialog.component.html',
    styleUrls: ['./confirm-dialog.component.scss'],
    standalone: true,
    imports: [
    MatIconButton,
    MatDialogClose,
    MatIcon,
    MatDialogTitle,
    CdkScrollable,
    MatDialogContent,
    MatDialogActions,
    MatButton
],
})
export class ConfirmDialogComponent {
  data = inject(MAT_DIALOG_DATA);

  defaults = {
    confirm: 'OK',
    cancel: 'Cancel',
  };
  actionFocus: string = 'confirm';
  accentColor: string = 'accent';
  @ViewChild('confirm') confirm: ElementRef;
  @ViewChild('cancel') cancel: ElementRef;

  constructor() {
    const data = this.data;

    if (data.actionFocus) {
      this.actionFocus = this.data.actionFocus;
    }
  }

  get confirmText() {
    return this.actions ? this.data.actions.confirm : this.defaults.confirm;
  }
  get cancelText() {
    return this.actions ? this.data.actions.cancel : this.defaults.cancel;
  }
  get actions() {
    return this.data && this.data.actions;
  }
}
