<div (keydown.space)="preventDrag($event)" class="header">
  <ng-content select="[slot='file-list-prefix']"></ng-content>
  @if (!minimal) {
    <span class="item-index semibold">{{ index + 1 }}.</span>
  }
  <button
    (click)="toggle()"
    [attr.aria-controls]="body.id"
    [attr.aria-expanded]="expanded"
    class="expand-button"
    disableRipple
    mat-flat-button
    type="button"
    >
    <div [class.header-text--text-only]="!hasBodyContent" class="header-text">
      <span [id]="'expansion-header-title-' + _id" class="title">
        <span class="item-name">
          <!-- files -->
          @if (!item.url) {
            <span class="item-basename semibold">
              @if (!minimal) {
                {{ item.baseName }}
              }
              @if (minimal) {
                {{ item.fullName }}
              }
            </span>
            @if (!minimal) {
              <span class="item-ext">
                &nbsp;
                @if (item.profile) {
                  <mat-icon svgIcon="file-{{ item.profile }}"></mat-icon>
                }
                <span>{{ item.extension }}</span>
              </span>
            }
            <span class="item-size">&nbsp;{{ item.sizeFloat }}</span>
          }

          <!-- links -->
          @if (item.url) {
            <span class="link-url">{{ item.url }}</span>
          }
        </span>

        @if (item.uploadProgress && item.uploadProgress < 100 && !minimal) {
          <span class="progress-count">
            <mat-icon svgIcon="upload-arrow"></mat-icon>
            <span class="semibold">{{ item.uploadProgress }}%</span>
          </span>
        }

        @if (item.uploadProgress === 100) {
          <span class="item-status">
            <mat-icon class="color-positive" svgIcon="check"></mat-icon>
          </span>
        }
        @if (hasBodyContent) {
          <mat-icon class="expand-icon" muloSvgViewbox svgIcon="chevron-down"></mat-icon>
        }
      </span>

      @if (item.uploadProgress < 100) {
        <mat-progress-bar
          [bufferValue]="0"
          [value]="item.uploadProgress"
          mode="determinate"
          >
        </mat-progress-bar>
      }
    </div>
  </button>
  @if (!minimal && item.url) {
    <button
      (click)="onEdit($event, item)"
      [attr.aria-label]="'research.aria.deposit.links.list.edit.link' | translate"
      mat-icon-button
      type="button"
      >
      <mat-icon svgIcon="pencil"></mat-icon>
    </button>
  }
  @if (!minimal || item.url) {
    <button
      (click)="onRemove($event, item, index)"
      [attr.aria-label]="'research.aria.deposit.files.and.links.remove.item' | translate"
      mat-icon-button
      style="z-index: 1; position: relative"
      type="button"
      >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  }
</div>
<div
  #body
  [attr.aria-labelledby]="'expansion-header-title-' + _id"
  [id]="'expansion-body-' + _id"
  [style.display]="expanded ? '' : 'none'"
  class="content"
  role="region"
  >
  <ng-content></ng-content>
</div>
