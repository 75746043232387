import { Component, HostBinding, Input, OnInit } from '@angular/core';
import { BriefBaseComponent } from '../../briefs/brief-base/brief-base.component';
import { TranslateModule } from '@ngx-translate/core';
import { MatIcon } from '@angular/material/icon';


@Component({
    selector: 'exl-super-brief',
    templateUrl: './super-brief.component.html',
    styleUrls: ['./super-brief.component.scss'],
    host: { class: 'exl-super-brief' },
    standalone: true,
    imports: [
    MatIcon,
    TranslateModule
],
})
export class SuperBriefComponent extends BriefBaseComponent implements OnInit {
    icon: string;
    rtlMirrorIcon: boolean;
    @Input() alertType;
    @Input() action;
    @HostBinding('class.exl-super-brief') true;

    constructor() {
        super();
    }

    ngOnInit(): void {
        this.assesIcon();
    }

    assesIcon() {
        if (this.alertType) {
            const type = this.alertType;
            if (type === 'status') {
                this.icon = 'approval';
            } else if (type === 'capture') {
                this.icon = 'magnify';
                this.rtlMirrorIcon = true;
            } else if (type === 'analytics') {
                this.icon = 'pulse';
            } else if (type === 'funding') {
                this.icon = 'trophy';
            } else {
                this.icon = undefined;
            }
        }
    }
}
