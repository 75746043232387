import { Component, Input, OnDestroy, OnInit, ViewChild, inject } from '@angular/core';
import { UntypedFormArray, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteTrigger, MatAutocomplete } from '@angular/material/autocomplete';
import { DepositAuthorsService } from './deposit-authors.service';
import { User } from '../../../shared/interfaces/user.model';
import { DepositForm } from '../../../shared/configurations/deposit-form.constant';
import { GrowInAnimation, GrowOutAnimation, SlideInAnimation, SlideOutAnimation, ContextFrameComponent, ContextFrameClosedViewDirective, AriaProgressBarDirective } from '@exl-ng/mulo-common';
import {
    animate,
    group,
    query,
    style,
    transition,
    trigger,
} from '@angular/animations';
import { debounceTime, distinctUntilChanged, Subject, takeUntil } from 'rxjs';
import { EsploroCreatorStatus } from '../../../shared/interfaces/esploro-author.interface';
import { MatButton } from '@angular/material/button';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { HighlightFilterPipe } from '../../../shared/pipes/highlight-filter.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { AuthorsListComponent } from './authors-list/authors-list.component';
import { MatOption } from '@angular/material/core';
import { MatProgressBar } from '@angular/material/progress-bar';

import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel } from '@angular/material/form-field';
import { EditAuthorComponent } from './edit-author/edit-author.component';
import { NewAuthorComponent } from './new-author/new-author.component';

@Component({
    selector: 'esp-deposit-authors',
    templateUrl: './deposit-authors.component.html',
    styleUrls: ['./deposit-authors.component.scss'],
    animations: [
        SlideInAnimation,
        SlideOutAnimation,
        GrowInAnimation,
        GrowOutAnimation,
        trigger('valueAnimation', [
            transition(':increment', group([
                query(':enter', [
                    style({ transform: 'scale(0)' }),
                    animate('0.8s ease-out', style({
                        transform: 'scale(1)',
                    })),
                ]),
            ])),
            transition(':decrement', group([
                query(':enter', [
                    style({ transform: 'scale(0)' }),
                    animate('0.8s ease-out', style({
                        transform: 'scale(1)',
                    })),
                ]),
            ])),
        ]),
    ],
    standalone: true,
    imports: [
    ContextFrameComponent,
    NewAuthorComponent,
    EditAuthorComponent,
    ContextFrameClosedViewDirective,
    FormsModule,
    MatFormField,
    MatLabel,
    MatInput,
    MatAutocompleteTrigger,
    ReactiveFormsModule,
    MatProgressBar,
    AriaProgressBarDirective,
    MatAutocomplete,
    MatOption,
    MatButton,
    AuthorsListComponent,
    TranslateModule,
    HighlightFilterPipe,
    CustomTranslatePipe
],
})
export class DepositAuthorsComponent implements OnInit, OnDestroy {
    private depositAuthorsService = inject(DepositAuthorsService);
    private formBuilder = inject(UntypedFormBuilder);

    @Input() isOrganizationButtonDisplay = true;
    @Input() authors: UntypedFormArray;
    @ViewChild('searchInput', { static: false, read: MatAutocompleteTrigger })
    searchInput: MatAutocompleteTrigger;

    editMode: 'new' | 'edit' | null = null;
    authorEdit: User;
    noValuesFound = false;
    searchInProgress = false;
    creatorsAndContributors: User[] = [];
    newAuthorType: 'person' | 'organization' = undefined;

    private authorDestroy = new Subject<void>();
    authorCtrl = new UntypedFormControl();
    private _formOpenedBy: MatButton;

    ngOnInit() {
        this.listenToSearchInput();
    }

    listenToSearchInput() {
        this.authorCtrl.valueChanges
            .pipe(
                debounceTime(DepositForm.SEARCH_DEBOUNCE_TIME),
                distinctUntilChanged(),
                takeUntil(this.authorDestroy),
            )
            .subscribe((value) => {
                this.noValuesFound = false;
                if (value && value.length >= DepositForm.MIN_INPUT_LENGTH) {
                    this.searchInProgress = true;
                    this.getCreatorsContributors(value);
                }
            });
    }

    getCreatorsContributors(value) {
        this.depositAuthorsService.getCreatorsContributors(value).subscribe(
            (data) => {
                this.searchInProgress = false;
                if (data) {
                    this.creatorsAndContributors = data as User[];
                } else {
                    this.noDataFound();
                }
            },
            (error) => {
                this.searchInProgress = false;
                this.noDataFound();
            },
        );
    }

    noDataFound() {
        this.noValuesFound = true;
        this.creatorsAndContributors = [];
    }

    clearInput() {
        setTimeout(() => {
            this.authorCtrl.reset();
        }, 0);
        this.searchInput.closePanel();
    }

    onAuthorSelect(author) {
        this.creatorsAndContributors = [];
        if (!this.isAuthorExists(author.id)) {
            this.addAuthor(author);
            this.depositAuthorsService.addUserId(author.id);
        }
        this.clearInput();
    }

    isAuthorExists(id) {
        const authorExist = this.authors.value.some(
            (author: User) => author.id === id && author.role === '',
        );
        const exist = this.depositAuthorsService.isUserExist(id);
        return authorExist || exist;
    }

    addAuthor(author: User) {
        const addedAuthor = this.formBuilder.group({
            name: author.actualName ?? author.name,
            firstName: author.firstName,
            middleName: author.middleName,
            lastName: author.lastName,
            nameSuffix: author.nameSuffix,
            id: author.id,
            orcid: author.orcid,
            affiliationName: author.affiliationName,
            affiliationCode: author.affiliationCode,
            affiliationNameList: [author.affiliationNameList],
            affiliationCodeList: [author.affiliationCodeList],
            role: author.role ?? '',
            owner: author.owner ?? false,
            isAffiliated: author.isAffiliated,
            isDisplayInPublicProfile:
                author.isDisplayInPublicProfile ?? author.isAffiliated,
            status: author.status ?? EsploroCreatorStatus.MANUAL,
            source: author.source ?? 'person',
        });
        this.authors?.push(addedAuthor);
        this.editMode = null;
    }

    onSearchInputFocus() {
        setTimeout(() => {
            this.searchInput.openPanel();
        }, 0);
    }

    toggleNewAuthorForm(type, button: MatButton) {
        this.editMode = 'new';
        this.newAuthorType = type;
        this._formOpenedBy = button;
    }

    ngOnDestroy() {
        this.depositAuthorsService.existsUsersList = [];
        if (!this.authorDestroy.isStopped) {
            this.authorDestroy.next();
            //unsubscribe from the subject itself
            this.authorDestroy.unsubscribe();
        }
    }

    handleFormClose() {
        this.editMode = null;
        this.authorEdit = null;
        setTimeout(() => {
            if (this._formOpenedBy !== undefined) {
                this._formOpenedBy.focus();
                this._formOpenedBy = undefined;
            }
        });
    }

    handleEditAuthorClick(author: User) {
        this.editMode = 'edit';
        this.authorEdit = author;
    }

    handleAuthorEdit(editedAuthor: Partial<User>) {
        const index = this.authors.value.findIndex(
            (_) => _.id === editedAuthor.id && _.role === editedAuthor.role,
        );
        if (index > -1) {
            const listItem = this.authors.at(index) as UntypedFormGroup;

            // make sure the controls exist before patching them
            if (listItem.get('affiliationNameList') == null) {
                listItem.addControl(
                    'affiliationNameList',
                    this.formBuilder.control([]),
                );
            }
            if (listItem.get('affiliationCodeList') == null) {
                listItem.addControl(
                    'affiliationCodeList',
                    this.formBuilder.control([]),
                );
            }

            listItem.patchValue({
                affiliationNameList: [...editedAuthor.affiliationNameList],
                affiliationCodeList: [...editedAuthor.affiliationCodeList],
            });
            this.authors.updateValueAndValidity();
        }
        this.editMode = null;
    }
}
