import { Directive, HostListener, EventEmitter, Output, inject } from '@angular/core';
import { DepositFormOverlayService } from '../deposit/deposit-form-overlay/deposit-form-overlay.service';
import {ProfileService} from '../profile/profile.service';
import {DepositKeywordsSuggestionsService} from './deposit-sections/deposit-keywords/deposit-keywords-suggestions.service';

@Directive({
    selector: '[espDepositFormTrigger]',
    standalone: true
})
export class DepositFormTriggerDirective {
    private depositFormOverlay = inject(DepositFormOverlayService);
    private profileService = inject(ProfileService);
    private suggestionsService = inject(DepositKeywordsSuggestionsService);


    @Output() overlayClosed = new EventEmitter<null>();

    @HostListener('click') onClick() {
        this.launchDepositForm();
    }

    launchDepositForm(){
        this.depositFormOverlay
            .open()
            .afterClosed()
            .subscribe(res => {
                this.overlayClosed.emit(null);
            });
        this.suggestionsService.clearSuggestions();
        this.profileService.retrieveProfileData();
    }

}
