@for (component of tab2; track component) {
  <ng-container required="true">
    @if (displayTitle(component)) {
      <esp-deposit-form-section sectionTitle="{{'research.assetitle.title' | customTranslate}}" [required]="true">
        <esp-deposit-title [jump]="jumpToTitle"></esp-deposit-title>
      </esp-deposit-form-section>
    }
    @if (displaySubtitle(component)) {
      <esp-deposit-form-section sectionTitle="{{'research.assetsubtitle.title' | customTranslate}}">
        <esp-deposit-subtitle></esp-deposit-subtitle>
      </esp-deposit-form-section>
    }
    @if (displayInvolved(component)) {
      <esp-deposit-form-section sectionTitle="{{'research.people.title' | customTranslate}}" [required]="isRequired(component)">
        <esp-deposit-authors [authors]="depositFormDataService.authors"></esp-deposit-authors>
      </esp-deposit-form-section>
    }
    @if (displayKeywords(component) && !anzInstitution()) {
      <esp-deposit-form-section sectionTitle="{{'research.topics.title'| customTranslate}}" [required]="isRequired(component)">
        <esp-deposit-keywords [jump]="jumpToTopics"></esp-deposit-keywords>
      </esp-deposit-form-section>
    }
    @if (displayKeywords(component) && anzInstitution()) {
      <esp-deposit-form-section sectionTitle="{{'research.topics.title'| customTranslate}}" description="{{'research.topics.anz.description'| customTranslate}}"
        [required]="isRequired(component)" >
        <!-- ANZ FoR -->
        <esp-deposit-form-section sectionTitle="{{'research.topics.for.title'| customTranslate}}" class="margin-neg-bottom-sm">
          <esp-deposit-anz-fields purpose="for" placeholder = "research.topics.for.search" [selectedFields] = "depositFormDataService.forSubject"></esp-deposit-anz-fields>
        </esp-deposit-form-section>
        <!-- ANZ SEO -->
        <esp-deposit-form-section sectionTitle="{{'research.topics.seo.title'| customTranslate}}" class="margin-neg-bottom-sm">
          <esp-deposit-anz-fields purpose="seo" placeholder = "research.topics.seo.search" [selectedFields] = "depositFormDataService.seoSubject"></esp-deposit-anz-fields>
        </esp-deposit-form-section>
        <!-- Keywords -->
        <esp-deposit-form-section  sectionTitle="{{'research.topics.keywords.title'| customTranslate}}">
          <esp-deposit-freetext-keywords></esp-deposit-freetext-keywords>
        </esp-deposit-form-section>
        <!-- ANZ TOA -->
        <esp-deposit-form-section sectionTitle="{{'research.topics.toa.title'| customTranslate}}" class="margin-neg-bottom-sm">
          <esp-deposit-anz-toa></esp-deposit-anz-toa>
        </esp-deposit-form-section>
      </esp-deposit-form-section>
    }
    @if (displayDescription(component)) {
      <esp-deposit-form-section sectionTitle="{{'research.abstract.title'| customTranslate}}"
        [required]="isRequired(component)">
        <esp-deposit-description></esp-deposit-description>
      </esp-deposit-form-section>
    }
  </ng-container>
}
