import { Component, Input, OnInit } from '@angular/core';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgClass } from '@angular/common';

@Component({
    selector: 'exl-badge',
    templateUrl: './badge.component.html',
    styleUrls: ['./badge.component.scss'],
    host: {
        class: 'exl-badge',
        '[class.is-small]': 'size === "small"',
        '[class.is-medium]': 'size === "medium"',
        '[class.is-large]': 'size === "large"',
    },
    standalone: true,
    imports: [
    MatIcon,
    SvgViewboxDirective,
    NgClass
],
})
export class BadgeComponent implements OnInit {
    @Input() icon: string;
    @Input() rtlMirrorIcon: boolean;
    @Input() color = 'accent';
    @Input() size: 'small' | 'medium' | 'large' = 'medium';
    @Input() tooltip: string;
    @Input() type: 'dot' | 'icon' = 'icon';
    @Input() count: string;

    hexColor: string; // HEX color
    hexColorRegex = '^#(?:[0-9a-fA-F]{3}){1,2}$';

    constructor() {}

    ngOnInit() {
        this.hexColor = this.color.match(this.hexColorRegex)
            ? this.color
            : null;
    }
}
