import { Component, Input, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatPrefix } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-publisher',
    templateUrl: './deposit-publisher.component.html',
    styleUrls: ['./deposit-publisher.component.scss'],
    standalone: true,
    imports: [
    MatFormField,
    MatLabel,
    MatInput,
    FormsModule,
    ReactiveFormsModule,
    MatIcon,
    MatPrefix,
    MatTooltip,
    CustomTranslatePipe
],
})
export class DepositPublisherComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  @Input() placeholder: string;

  private publisherDestroy = new Subject<void>();

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.publisher.valueChanges
      .pipe(takeUntil(this.publisherDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.PUBLISHER
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.PUBLISHER
    );
  }

  ngOnDestroy() {
    if (!this.publisherDestroy.isStopped) {
      this.publisherDestroy.next();
      //unsubscribe from the subject itself
      this.publisherDestroy.unsubscribe();
    }
  }
}
