<mulo-centered-block-layout
  class="placeholder-wrapper"
  [@opacityOutAnimation]="{ params: { time: '.1s' } }"
  [width]="containerWidth"
  >
  @if (loading) {
    <mulo-page-placeholder [rtl]="rtl"></mulo-page-placeholder>
  }
</mulo-centered-block-layout>

@if (!loading) {
  <ng-template #mast>
    <ng-content select="mulo-page-mast, [slot='main-page-content']"></ng-content>
  </ng-template>
  <ng-template #content>
    <ng-content></ng-content>
  </ng-template>
  @if (navWidth === 0) {
    <mulo-centered-block-layout>
      <ng-container *ngTemplateOutlet="mast"></ng-container>
      <ng-container *ngTemplateOutlet="content"></ng-container>
    </mulo-centered-block-layout>
  }
  @if (navWidth !== 0) {
    <mulo-centered-block-layout [width]="containerWidth">
      <ng-container *ngTemplateOutlet="mast"></ng-container>
      <span muloInView muloInViewOffset="-100px" (muloInViewVisible)="showBackToTop = $event ? false : true"></span>
    </mulo-centered-block-layout>
    <!-- <div> -->
    <!-- <mulo-vertical-nav slot="nav" [items]="anchors$ | async" style="float: left"></mulo-vertical-nav> -->
    <mulo-centered-block-layout
      [width]="containerWidth"
      [style.backgroundColor]=""
      [class.margin-neg-top-md]="media.is(hNavMediaCondition)"
      >
      <mulo-nav-main-layout
        [navPosition]="navPosition || media.is(hNavMediaCondition) ? 'top' : 'left'"
        [navBehavior]="navBehavior"
        [stickyOffset]="stickyOffset || media.is(hNavMediaCondition) ? 0 : 30"
        [stickyZIndex]="10"
        [navSize]="media.is(hNavMediaCondition) ? 48 : navWidth"
        [navShifted]="media.is(hNavMediaCondition) ? null : 'out'"
        >
        @if (media.is(hNavMediaCondition) === false) {
          <mulo-vertical-nav
            slot="nav"
            [items]="anchors$ | async"
            [skipLocationChange]="true"
            (itemSelected)="onAnchorNav($event)"
            [disableInkbar]="true"
            [disableActiveIndication]="true"
            hideOnPrint
            >
            @if (showBackToTop) {
              <div slot="pre-nav" @growInAnimation @growOutAnimation>
                <a
                  [routerLink]="'.'"
                  [skipLocationChange]="true"
                  [queryParamsHandling]="'preserve'"
                  class="mdc-tab mat-mdc-tab-link mat-mdc-focus-indicator mdc-tab--active"
                  (click)="handleBackToTop()"
                  slot="pre-nav"
                  mat-tab-link
                  >
                  <span class="mdc-tab__text-label btt-label"
                    >{{ backToTopLabel }} <mat-icon svgIcon="arrow-up"></mat-icon
                  ></span>
                </a>
              </div>
            }
          </mulo-vertical-nav>
        }
        @if (media.is(hNavMediaCondition)) {
          <mulo-horizontal-nav
            slot="nav"
            [items]="anchors$ | async"
            [skipLocationChange]="true"
            (itemSelected)="onAnchorNav($event)"
            [disableInkbar]="true"
            [disableActiveIndication]="true"
            hideOnPrint
            >
            @if (showBackToTop) {
              <a
                @growInAnimation
                @growOutAnimation
                [routerLink]="'.'"
                [skipLocationChange]="true"
                [queryParamsHandling]="'preserve'"
                class="back-to-top"
                (click)="handleBackToTop()"
                slot="pre-nav"
                mat-tab-link
                [attr.aria-label]="backToTopLabel"
                >
                <mat-icon svgIcon="arrow-up" color="primary"></mat-icon>
              </a>
            }
          </mulo-horizontal-nav>
        }
        <div slot="main" [class.margin-top-md]="media.is(hNavMediaCondition)">
          <ng-container *ngTemplateOutlet="content"></ng-container>
        </div>
      </mulo-nav-main-layout>
    </mulo-centered-block-layout>
  <!-- </div> -->
}
}
