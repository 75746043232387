import { Component, OnInit, ViewChild, Output, EventEmitter, OnDestroy, inject } from '@angular/core';
import { DepositFormService } from '../../deposit-form.service';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { SortUtils } from '../../../shared/utils/sort.utils';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { ConfigurationHandlerService } from '../../../core/configuration-handler.service';
import { Subject, takeUntil } from 'rxjs';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { MatOption } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIcon } from '@angular/material/icon';

import { MatFormField, MatPrefix } from '@angular/material/form-field';

@Component({
    selector: 'esp-deposit-publication-status',
    templateUrl: './deposit-publication-status.component.html',
    styleUrls: ['./deposit-publication-status.component.scss'],
    standalone: true,
    imports: [
    MatFormField,
    MatIcon,
    MatPrefix,
    MatTooltip,
    MatSelect,
    FormsModule,
    ReactiveFormsModule,
    MatOption,
    CustomTranslatePipe
],
})
export class DepositPublicationStatusComponent implements OnInit, OnDestroy {
    private depositFormService = inject(DepositFormService);
    depositFormDataService = inject(DepositFormDataService);
    private configurationHandlerService = inject(ConfigurationHandlerService);

    @ViewChild('statusSelect', { static: false }) subtypeSelect;
    @Output() private selectedStatus: EventEmitter<any> = new EventEmitter();

    placeholder = 'research.status.overline';
    statusOptions: string[] = [];

    private statusDestroy = new Subject<void>();

    ngOnInit() {
        const resourceTypeTemplate =
            this.depositFormDataService.resourceTypeTemplate;
        this.statusOptions =
            this.configurationHandlerService.depositConfig?.deposit?.[
                resourceTypeTemplate
            ].statuses
                .filter((status) => status.mandatory)
                .map((status) => {
                    return status.status;
                }) || [];
        this.statusOptions = this.statusOptions.sort(
            SortUtils.alphabeticallySort
        );
        this.onChanges();
    }

    onChanges(): void {
        this.depositFormDataService.status.valueChanges
            .pipe(takeUntil(this.statusDestroy))
            .subscribe((value) => {
                if (!this.depositFormDataService.startPopulating) {
                    this.depositFormDataService.removeKeyFromAutoPopulatedArray(
                        AutoPopulateKeys.STATUS
                    );
                }
            });
    }

    get isAutoPopulated() {
        return this.depositFormDataService.isKeyAutoPopulated(
            AutoPopulateKeys.STATUS
        );
    }

    ngOnDestroy() {
        if (!this.statusDestroy.isStopped) {
            this.statusDestroy.next();
            //unsubscribe from the subject itself
            this.statusDestroy.unsubscribe();
        }
    }
}
