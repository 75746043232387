@if (fromDate) {
  <mat-form-field
    #fromDateFormfield
    [class.is-autopopulated]="autoPopulatedFromDate"
    [class.mat-form-field-invalid]="error"
    class="exl-date-picker-input"
    >
    @if (dateStyle === 'full') {
      <input
        (dateChange)="onDateChange()"
        [formControl]="fromDate"
        [matDatepicker]="fromDatePicker"
        autocomplete="off"
        exlAutomationClass="from-date"
        matInput
        />
    }
    @if (!fromDateTranslation) {
      <mat-label>{{ fromDateLabel | customTranslate }}</mat-label>
    }
    @if (fromDateTranslation) {
      <mat-label>{{ fromDateTranslation }}</mat-label>
    }
    @if (dateStyle === 'year') {
      <mat-select [formControl]="fromDate" exlAutomationClass="from-date">
        @for (year of years; track year) {
          <mat-option [value]="year">
            {{ year }}
          </mat-option>
        }
      </mat-select>
    }
    @if (showHint && !error) {
      <mat-hint
        [translateParams]="{ value0: currentDate }"
        [translate]="'research.status.date.hint'"
        align="start"
      ></mat-hint>
    }
    @if (autoPopulatedFromDate) {
      <mat-icon
        [matTooltip]="'research.auto.populate.tooltip' | customTranslate"
        matPrefix
        svgIcon="auto-populate"
      ></mat-icon>
    }
    @if (usePicker) {
      <mat-datepicker-toggle
        #fromDateToggle
        [for]="usePicker ? fromDatePicker : null"
        exlAutomationClass="from-datepicker"
        matSuffix
      ></mat-datepicker-toggle>
    }
    <mat-datepicker #fromDatePicker></mat-datepicker>
    @if (error) {
      <div
        class="mat-mdc-form-field-subscript-wrapper"
        style="inset-block-start: 2rem; width: calc(100% + 21px)"
        >
        <span class="mat-error" role="alert">
          {{ 'research.status.error' | customTranslate }}
        </span>
      </div>
    }
    @if (fromDate.invalid) {
      <mat-error>{{ 'research.status.error' | customTranslate }}</mat-error>
    }
    @if (rangeError) {
      <mat-error>{{ 'research.settings.date.range.error' | translate }}</mat-error>
    }
  </mat-form-field>
}

@if (range) {
  <span class="daterange-arrow">
    <mat-icon class="mat-icon-rtl-mirror" svgIcon="arrow-right"></mat-icon>
  </span>
}
@if (range) {
  <mat-form-field #toDateFormfield class="exl-date-picker-input">
    @if (dateStyle === 'full') {
      <input
        [formControl]="toDate"
        [matDatepicker]="toDatePicker"
        autocomplete="off"
        exlAutomationClass="to-date"
        matInput
        />
    }
    @if (!toDateTranslation) {
      <mat-label>{{ toDateLabel | customTranslate }}</mat-label>
    }
    @if (toDateTranslation) {
      <mat-label>{{ toDateTranslation }}</mat-label>
    }
    @if (dateStyle === 'year') {
      <mat-select [formControl]="toDate" exlAutomationClass="to-date">
        @for (year of years; track year) {
          <mat-option [value]="year">
            {{ year }}
          </mat-option>
        }
      </mat-select>
    }
    @if (usePicker) {
      <mat-datepicker-toggle
        #toDateToggle
        [for]="usePicker ? toDatePicker : null"
        exlAutomationClass="to-datepicker"
        matSuffix
      ></mat-datepicker-toggle>
    }
    <mat-datepicker #toDatePicker></mat-datepicker>
    @if (toDate.invalid) {
      <mat-error>{{ 'research.status.error' | customTranslate }}</mat-error>
    }
  </mat-form-field>
}
