<mat-form-field
  floatLabel="auto"
  class="is-comfortably-readable"
  [muloInputExpander]="depositFormDataService.topics.value | collectByField : 'description'"
  >
  <mat-label>{{ 'research.topics.overline' | customTranslate }}</mat-label>
  <mat-chip-grid #chipGrid>
    @for (
      chip of depositFormDataService.topics.value; track trackByTopic(i,
      chip); let isFirst = $first; let i = $index) {
      <mat-chip-row
        cdkMonitorSubtreeFocus
        [attr.aria-label]="chip.description"
        [removable]="removable"
        (removed)="removeChip(chip, i)"
        matTooltipPosition="above"
        disableRipple="true"
        @widthInAnimation
        @widthOutAnimation
        >
        @if (isAutoPopulated(chip)) {
          <exl-badge-auto-populated
            tooltip="{{ 'research.auto.populate.tooltip' | customTranslate }}"
            color="primary"
            >
          </exl-badge-auto-populated>
        }
        <span class="semibold chip-content">
          {{ chip.description }}
        </span>
        @if (removable) {
          <button
            mat-icon-button
            matChipRemove
            [attr.aria-label]="('research.aria.deposit.keywords.remove' | translate) + ' ' + chip.description"
            >
            <mat-icon svgIcon="close"></mat-icon>
          </button>
        }
      </mat-chip-row>
    }
  </mat-chip-grid>

  <input
    matInput
    [formControl]="autoCompleteChipList"
    #chipInput
    [matAutocomplete]="auto"
    (keyup.enter)="onInputEnterKey(chipInput)"
    [matChipInputFor]="chipGrid"
    [exlFocus]="jump"
    />
  <mat-autocomplete #auto="matAutocomplete">
    @if (filteredSuggestions.length > 0) {
      <mat-optgroup
        label="{{ 'research.topics.suggestions' | customTranslate }}"
        >
        @for (suggestion of filteredSuggestions; track suggestion) {
          <mat-option
            [value]="suggestion.description"
            (onSelectionChange)="addSuggestionChip(suggestion, chipInput)"
            >
            <div class="autocomplete-option-multiline">
              <span
                class="semibold"
                [innerHTML]="suggestion.description | highlight : autoCompleteChipList.value"
              ></span>
            </div>
          </mat-option>
        }
      </mat-optgroup>
    }
    @if (filteredSuggestions.length == 0 && chipInput.value.length > 0) {
      <mat-option
        value="autoCompleteChipList.value"
        (onSelectionChange)="onInputEnterKey(chipInput)"
        >
        {{ 'research.topics.add.new.topic' | customTranslate }} "<span class="bold">{{
        autoCompleteChipList.value
        }}</span
        >"
      </mat-option>
    }
  </mat-autocomplete>
</mat-form-field>
