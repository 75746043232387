<nav mat-tab-nav-bar class="is-scaling is-scrollable" [color]="color" #navbar [mat-stretch-tabs]="false">
  <ng-content select="[slot='pre-nav']"></ng-content>
  @for (item of items; track item; let i = $index) {
    <a
      mat-tab-link
      [routerLink]="item.url ? assessUrl(item.url) : null"
      [fragment]="item.url && containsHash(item.url) ? unHash(item.url) : null"
      [queryParamsHandling]="queryParamsHandling"
      [skipLocationChange]="skipLocationChange"
      [active]="disableActiveIndication ? false : selectedIndex === item.id"
      (click)="changeItem(item, item.url && containsHash(item.url))"
      >
      @if (itemTemplate) {
        <ng-container
          [ngTemplateOutlet]="itemTemplate"
          [ngTemplateOutletContext]="{ $implicit: item, index: i }"
          >
        </ng-container>
      } @else {
        <span>
          {{ item.label }}@if (item.parent) {
          s
          }&nbsp;@if (item.length) {
          <span
            class="counter"
            >{{ item.length }}</span
            >
          }
        </span>
      }
    </a>
  }
</nav>
