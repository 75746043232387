import { Component, OnDestroy, ViewChild, inject } from '@angular/core';
import { Subject } from 'rxjs';
import { MatAutocompleteTrigger } from '@angular/material/autocomplete';
import { GrowInAnimation, GrowOutAnimation, ContextFrameComponent, ContextFrameClosedViewDirective } from '@exl-ng/mulo-common';
import { DepositGrantsService } from './deposit-grants.service';
import { Grant } from '../../../shared/interfaces/grant.model';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { DepositGrantsSearchComponent } from './deposit-grants-search/deposit-grants-search.component';
import { DefaultTranslatePipe } from '../../../shared/pipes/default-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatIconButton } from '@angular/material/button';
import { DescriptionDirective } from '../../../shared/directives/a11y/description/exl-description.directive';
import { MatIcon } from '@angular/material/icon';
import { ListitemDescriptionA11yDirective } from '../../../shared/directives/a11y/description/listitem-description-a11y.directive';
import { MatList, MatListItem, MatListItemIcon, MatListItemTitle, MatListItemLine, MatListItemMeta } from '@angular/material/list';
import { NewGrantComponent } from './new-grant/new-grant.component';


@Component({
    selector: 'esp-deposit-grants',
    templateUrl: './deposit-grants.component.html',
    styleUrls: ['./deposit-grants.component.scss'],
    providers: [DepositGrantsService],
    animations: [GrowOutAnimation, GrowInAnimation],
    standalone: true,
    imports: [
    ContextFrameComponent,
    NewGrantComponent,
    ContextFrameClosedViewDirective,
    DepositGrantsSearchComponent,
    MatList,
    MatListItem,
    ListitemDescriptionA11yDirective,
    MatIcon,
    MatListItemIcon,
    DescriptionDirective,
    MatListItemTitle,
    MatListItemLine,
    MatListItemMeta,
    MatIconButton,
    TranslateModule,
    DefaultTranslatePipe
],
})
export class DepositGrantsComponent implements OnDestroy {
    depositFormDataService = inject(DepositFormDataService);

    @ViewChild('grantInput', { static: false, read: MatAutocompleteTrigger })
    grantInput: MatAutocompleteTrigger;
    @ViewChild('grantsSearch') grantsSearch: DepositGrantsSearchComponent;

    public dataSection = 'grants';

    allGrants: Grant[] = [];
    enterNewGrant = false;
    private grantDestroy = new Subject<void>();

    onGrantRemove(index) {
        this.depositFormDataService.removeGrant(index);
    }

    addGrant(item) {
        if (!this.depositFormDataService.isGrantExist(item)) {
            this.depositFormDataService.updateGrants(item);
        }
    }

    ngOnDestroy() {
        if (!this.grantDestroy.isStopped) {
            this.grantDestroy.next();
            this.grantDestroy.unsubscribe();
        }
    }

    handleFormClose() {
        this.enterNewGrant = false;
        setTimeout(() => this.grantsSearch?.addNewBtn?.focus());
    }
}
