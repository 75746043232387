<div class="wrapper">
  @if (icon) {
    <div class="icon">
      <mat-icon [class.mat-icon-rtl-mirror]="rtlMirrorIcon" [svgIcon]="icon"></mat-icon>
    </div>
  }
  <div class="content">
    @if (content) {
      @if (content.contentTitle) {
        <div class="semibold">{{ content.contentTitle | translate }}</div>
      }
      @if (content.subject) {
        <div class="semibold">{{ content.subject }}</div>
      }
      @if (content.reason) {
        <div class="semibold">{{ reasonPrefix }} {{ content.reason | translate }}.</div>
      }
      <div [innerHTML]="content.body"></div>
      @if (time) {
        <p class="time">{{ time | amTimeAgo }}</p>
      }
    }
    <ng-content select="[slot-chat-content]"></ng-content>
  </div>
  <div class="actions">
    <ng-content select="[slot-chat-bubble-actions],[slot='chat-bubble-actions']"></ng-content>
  </div>
</div>
