import {
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  TemplateRef,
} from '@angular/core';
import { MuloNavLink } from '../../models/nav-link.model';
import { addObjectDepthLevel, getArrayDepth } from '../../utils/array-utils';
import { QueryParamsHandling } from '@angular/router';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'mulo-nav',
  template: '',
  standalone: true,
})
export class NavComponent implements OnInit, OnChanges {
  /**
   * Array of nav items
   */
  @Input() items: MuloNavLink[];
  /**
   * Optional template to override the default nav display
   */
  @Input() itemTemplate: TemplateRef<any>;
  /**
   * The selected nav item as index number
   */
  @Input() selectedIndex = 0;
  /**
   * The selected nav/path item as array of string
   */
  @Input() selectedPath: string[] = [];
  /**
   * Optionally set a different color theme
   */
  @Input() color: ThemePalette = 'primary';
  /**
   * The travel distance to be used on correponding content panels
   */
  @Input() tabTravelDistance = '50px';
  /**
   * Minimum number of item to children to expand when active
   */
  @Input() expandMinimum = 1;
  /**
   * Keep URL query parameters when handling links
   */
  @Input() queryParamsHandling: QueryParamsHandling = 'preserve';
  /**
   * Skip writing route events to browser history
   */
  @Input() skipLocationChange = true;
  /**
   * Disable the inkbar
   */
  @Input() disableInkbar = false;
  /**
   * Disable the nav-focus indication
   */
  @Input() disableActiveIndication = false;
  /**
   * Event fired when an item gets selected
   */
  @Output() itemSelected = new EventEmitter<MuloNavLink>();
  /**
   * Emit the nav direction so that corresponding content panels can use for animation
   */
  @Output() navDirection = new EventEmitter<string>();
  /**
   * Used as base to track how deep the items array is
   */
  levelsDepth = 0;

  constructor() {}

  ngOnInit(): void {
    this.emitNavDirection(this.tabTravelDistance);
  }

  ngOnChanges(changes: SimpleChanges) {
    if (!changes) return null;
    if (changes.items) {
      addObjectDepthLevel(this.items);
      this.levelsDepth = getArrayDepth(this.items);
    }
  }

  emitNavDirection(prev, next?) {
    this.navDirection.emit(this.setTabDir(prev, next));
  }

  setTabDir(prev, next) {
    return next >= prev ? this.tabTravelDistance : '-' + this.tabTravelDistance;
  }

  assessUrl(url: string) {
    return this.containsHash(url) ? '.' : url;
  }

  unHash(url) {
    return url.replace('#', '');
  }

  containsHash(url: string) {
    return url.toString().startsWith('#');
  }

  findNavLink(value, key = 'id', reverse = false) {
    const stack = [...this.items];
    while (stack.length) {
      const node = stack[reverse ? 'pop' : 'shift']();
      if (node[key] === value) return node;
      if (node.children) stack.push(...node.children);
    }
    return null;
  }
}
