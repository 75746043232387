<!-- TODO - Remove in Sprint Januar or Februar 2022 -->
@if (haveOldValues) {
    <p class="deposit-section-description palette-warn-color">
        The fields of Research topics has been updated. Please select new topics for your deposit.
    </p>
}
<!-- TODO END # -->

<div class="field-wrap">
    <mat-form-field floatLabel="auto" class="is-comfortably-readable margin-right-sm">
        <mat-label>{{ placeholder | customTranslate }}</mat-label>
        <input matInput [formControl]="keywordCtrl" [matAutocomplete]="auto" #topicInput />
        @if (inProgress) {
            <mat-progress-bar mode="indeterminate"></mat-progress-bar>
        }
        <mat-autocomplete #auto="matAutocomplete">
            @if (filteredTopics) {
                @for (topic of filteredTopics; track topic) {
                    <mat-option [value]="topic.title" (onSelectionChange)="onFieldSelected(topic)">
                        <div class="autocomplete-option-multiline">
                            <span>
                                <span
                                    class="semibold"
                                    [innerHTML]="topic.actualDescription | highlight: keywordCtrl.value"
                                ></span
                                >&nbsp;
                                <span class="monospace" [innerHTML]="topic.code | highlight: keywordCtrl.value"></span>
                            </span>
                            <span
                                class="italics"
                                [innerHTML]="topic.hierarchy.join('/ ') | highlight: keywordCtrl.value"
                            ></span>
                        </div>
                    </mat-option>
                }
                @if (filteredTopics.length < 1) {
                    <mat-option [disabled]="true">{{
                        'research.topics.search.noresults' | customTranslate
                    }}</mat-option>
                }
            }
        </mat-autocomplete>
    </mat-form-field>
</div>

<mulo-portion-list
    (distributePortion)="onDistributePortions($event)"
    [toolbarLabels]="[
        'research.settings.research.distribute' | customTranslate,
        'research.settings.research.distribute.descending' | customTranslate,
        'research.settings.research.distribute.equaly' | customTranslate,
        'research.settings.research.distribute.ascending' | customTranslate
    ]"
>
    @for (item of selectedFields.value; track item.label; let i = $index) {
        <mulo-portion-list-item
            @growInAnimation
            @growOutAnimation
            (changeEnd)="onChange($event, i)"
            [pliDisabled]="selectedFields.value.length <= 1"
            [hasSlider]="selectedFields.length > 1"
            [item]="item"
            (remove)="onRemove(i)"
            [id]="i"
            [removeBtnAriaLabel]="'research.aria.deposit.portion.remove' | translate: { value: item.label }"
            [portionInputAriaLabel]="'research.aria.deposit.portion.input'"
            [portionSliderAriaLabel]="'research.aria.deposit.portion.slider'"
        >
            {{ item.label }}
        </mulo-portion-list-item>
    }
</mulo-portion-list>
