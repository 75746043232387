import {
  Component,
  ChangeDetectionStrategy,
  Directive,
  ContentChild,
  Input,
} from '@angular/core';


@Directive({
    selector: '[slot="label"]',
    standalone: true
})
export class DictionaryItemLabelDirective {}

@Directive({
    selector: '[slot="content"]',
    standalone: true
})
export class DictionaryItemContentDirective {}

@Component({
    selector: '[mulo-dictionary-item]',
    templateUrl: './dictionary-item.component.html',
    styleUrls: ['./dictionary-item.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    host: { class: 'mulo-dictionary-item' },
    standalone: true,
    imports: [],
})
export class DictionaryItemComponent {
  @ContentChild(DictionaryItemLabelDirective) labelView;
  @ContentChild(DictionaryItemContentDirective) contentView;

  @Input() label: string;
  @Input() content: any;
  @Input() labelAlign: 'left' | 'right' = 'right';

  constructor() {}

  changeAlignment(dir) {
    this.labelAlign = dir;
  }
}
