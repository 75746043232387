import { Component, ElementRef, EventEmitter, HostListener, Input, OnDestroy, OnInit, Output, ViewChild, inject } from '@angular/core';
import { User } from '../../../../shared/interfaces/user.model';
import { DepositAuthorsService } from '../deposit-authors.service';
import { FormGroupDirective, UntypedFormBuilder, UntypedFormGroup, ValidationErrors, Validators, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { Subject } from 'rxjs';
import { StringUtils } from '../../../../shared/utils/string.utils';
import { GrowInAnimation, GrowOutAnimation, InputExpanderDirective } from '@exl-ng/mulo-common';
import { AnnouncerService } from '../../../../core/announcer.service';
import { EsploroCreatorStatus } from '../../../../shared/interfaces/esploro-author.interface';
import { MatDialog } from '@angular/material/dialog';
import { CustomTranslatePipe } from '../../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatButton } from '@angular/material/button';
import { MultipleAffiliationsComponent } from '../../../../parts/multiple-affiliations/multiple-affiliations.component';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

@Component({
    selector: 'esp-new-author',
    templateUrl: './new-author.component.html',
    styleUrls: ['./new-author.component.scss'],
    host: { class: 'esp-new-author' },
    animations: [GrowInAnimation, GrowOutAnimation],
    standalone: true,
    imports: [
        FormsModule,
        ReactiveFormsModule,
        MatFormField,
        InputExpanderDirective,
        MatInput,
        MatLabel,
        MatError,
        MultipleAffiliationsComponent,
        MatButton,
        TranslateModule,
        CustomTranslatePipe,
    ],
})
export class NewAuthorComponent implements OnInit, OnDestroy {
    private depositAuthorsService = inject(DepositAuthorsService);
    confirmDialog = inject(MatDialog);
    private formBuilder = inject(UntypedFormBuilder);
    private announcer = inject(AnnouncerService);

    @ViewChild('last', { static: false }) last: ElementRef;
    @ViewChild('first', { static: false }) first: ElementRef;
    @ViewChild('formDirective', { static: false })
    formDirective: FormGroupDirective;

    @Input() editMode: 'new' | 'edit' | null;
    @Output() editModeChange = new EventEmitter();
    @Input() newAuthorType;
    @Output() newPerson = new EventEmitter<User>();

    @Input()
    get person() {
        return this._person;
    }
    set person(p: UntypedFormGroup) {
        this._person = p;
    }
    private _person: UntypedFormGroup;
    private affiliationDestroy = new Subject<void>();

    @HostListener('keydown.Escape', ['$event']) private keyFunc(event) {
        event.stopPropagation();
        this.close();
    }

    constructor() {
        this.focusOnNameField();
        this.person = this.formBuilder.group({
            lastName: ['', Validators.required],
            firstName: ['', Validators.required],
            middleName: '',
            nameSuffix: '',
            affiliationCode: '',
            affiliationName: '',
            affiliationNameList: [[]],
            affiliationCodeList: [[]],
        });
    }

    ngOnInit() {}

    close() {
        this.resetForm();
        this.editMode = null;
        this.editModeChange.emit(this.editMode);
    }

    resetForm() {
        this.depositAuthorsService.lastChosenAffiliation = '';
        this.formDirective.resetForm();
        this.person.reset();
    }

    onSubmitNewPerson() {
        this.announcer.annonuceAdd(this.firstName, this.lastName);

        if (this.newAuthorType === 'person') {
            this.addNonAffiliatedResearcherUser();
        } else {
            this.addCreatorGroupAuthor();
        }
    }

    addCreatorGroupAuthor() {
        const addedAuthor = new User();
        addedAuthor.setName(this.firstName);
        addedAuthor.setSource('groupAuthor');
        addedAuthor.setRole('Institution');
        this.newPerson.emit(addedAuthor);
        this.resetForm();
        this.focusOnNameField();
    }

    addNonAffiliatedResearcherUser() {
        this.depositAuthorsService
            .addNonAffiliatedResearcherUser(
                this.lastName,
                this.firstName,
                this.middleName,
                this.nameSuffix,
                this.affiliationCodeList,
            )
            .subscribe({
                next: (id) => {
                    this.successAfterCreateNewResearcherUser(id);
                },
                error: (error) => {},
            });
    }

    successAfterCreateNewResearcherUser(id: string) {
        const name = [
            this.firstName,
            this.middleName,
            this.lastName,
            this.nameSuffix,
        ]
            .filter(Boolean)
            .join(' ');
        const addedAuthor = new User();
        addedAuthor.setId(id);
        addedAuthor.setName(StringUtils.capitalizeName(name));
        addedAuthor.setFirstName(this.firstName);
        addedAuthor.middleName = this.middleName;
        addedAuthor.setLastName(this.lastName);
        addedAuthor.nameSuffix = this.nameSuffix;
        addedAuthor.setAffiliationCode(
            this.affiliationCode ? this.affiliationCode : '',
        );
        addedAuthor.setAffiliationName(
            this.affiliationName ? this.affiliationName : '',
        );
        addedAuthor.setAffiliationCodeList(
            this.affiliationCodeList ? this.affiliationCodeList : [],
        );
        addedAuthor.setAffiliationNameList(
            this.affiliationNameList ? this.affiliationNameList : [],
        );

        addedAuthor.setOwner(false);
        addedAuthor.setRole('');
        addedAuthor.setIsAffiliated(false);
        addedAuthor.setIsDisplayInPublicProfile(false);
        addedAuthor.setStatus(EsploroCreatorStatus.MANUAL);
        this.newPerson.emit(addedAuthor);
        this.resetForm();
        this.focusOnNameField();
    }

    focusOnNameField() {
        setTimeout(() => {
            this.first.nativeElement.focus();
        }, 250);
    }

    isValid() {
        if (this.newAuthorType === 'person' && this.person.status === 'VALID') {
            return true;
        } else if (
            this.newAuthorType !== 'person' &&
            this.person.get('firstName').status === 'VALID'
        ) {
            return true;
        }
        return false;
    }

    validateInstitution: ValidationErrors | null = () => {
        const institutionValidator =
            !this.affiliationName || this.affiliationCode;
        return institutionValidator
            ? null
            : {
                  validateInstitution: {
                      valid: false,
                  },
              };
    };

    get lastName() {
        return this.person?.get('lastName').value;
    }

    get firstName() {
        return this.person?.get('firstName').value;
    }

    get middleName() {
        return this.person?.get('middleName').value;
    }

    get nameSuffix() {
        return this.person?.get('nameSuffix').value;
    }

    get affiliationName() {
        return this.person?.get('affiliationName').value;
    }

    get affiliationCode() {
        return this.person?.get('affiliationCode').value;
    }
    get affiliationCodeList() {
        return this.person?.get('affiliationCodeList').value;
    }
    get affiliationNameList() {
        return this.person?.get('affiliationNameList').value;
    }

    ngOnDestroy() {
        if (!this.affiliationDestroy.isStopped) {
            this.affiliationDestroy.next();
            //unsubscribe from the subject itself
            this.affiliationDestroy.unsubscribe();
        }
    }
}
