import { Component, EventEmitter, Input, OnChanges, Output, SimpleChanges, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { MatError, MatFormField, MatLabel } from '@angular/material/form-field';
import { MatInput } from '@angular/material/input';
import { MultipleAffiliationsComponent } from '../../../../parts/multiple-affiliations/multiple-affiliations.component';
import {
    ReactiveFormsModule,
    UntypedFormBuilder,
    UntypedFormGroup,
} from '@angular/forms';
import { SharedModule } from '../../../../shared/shared.module';
import { TranslateModule } from '@ngx-translate/core';
import { User } from '../../../../shared/interfaces/user.model';

@Component({
    selector: 'esp-edit-author',
    standalone: true,
    imports: [
        MatButton,
        MatError,
        MatFormField,
        MatInput,
        MatLabel,
        MultipleAffiliationsComponent,
        ReactiveFormsModule,
        SharedModule,
        TranslateModule,
    ],
    templateUrl: './edit-author.component.html',
    styleUrl: './edit-author.component.scss',
})
export class EditAuthorComponent implements OnChanges {
    private formBuilder = inject(UntypedFormBuilder);

    @Input() author: User;
    @Output() edited = new EventEmitter<Partial<User>>();

    person: UntypedFormGroup;
    constructor() {
        this.person = this.formBuilder.group({
            name: '',
            id: '',
            role: '',
            affiliationCode: '',
            affiliationName: '',
            affiliationNameList: [[]],
            affiliationCodeList: [[]],
        });
        this.person.get('name').disable();
    }

    ngOnChanges(changes: SimpleChanges) {
        if (changes?.['author']) {
            const author = changes.author.currentValue;
            this.person.patchValue({
                ...author,
                affiliationNameList:
                    author.affiliationNameList ??
                    (author.affiliationName ? [author.affiliationName] : []),
                affiliationCodeList:
                    author.affiliationCodeList ??
                    (author.affiliationCode ? [author.affiliationCode] : []),
            });
        }
    }

    handleSubmit() {
        this.edited.emit(this.person.getRawValue());
    }
}
