import { Component, OnInit, Input, HostBinding, ElementRef, Output, EventEmitter, AfterViewInit, HostListener, inject } from '@angular/core';
import { DOCUMENT } from '@angular/common';

export interface CountryColor {
  code: string;
  color: string;
}

@Component({
    selector: 'mulo-world-map',
    templateUrl: './world-map.component.svg',
    styleUrls: ['./world-map.component.scss'],
    standalone: true,
})
export class WorldMapComponent implements OnInit, AfterViewInit {
  private document = inject(DOCUMENT);
  private mapEl = inject(ElementRef);

  /**
   * The focus css class of a country
   */
  @HostBinding('class.is-focusing') countryFocus: boolean;

  /**
   * The base color
   */
  @Input() baseColor = '#cccccc';
  /**
   * The hover color
   */
  @Input() hoverColor = '#aaaaaa';
  /**
   * The active color
   */
  @Input() activeColor = 'royalblue';
  @Input() static = false;
  @Input() svgAttributes = { viewBox: '0 0 1400 600' };

  @Output() countrySelected = new EventEmitter<string>();
  @Output() svgReady = new EventEmitter<string>();

  /**
   * @internal
   */
  mapSVG;
  /**
   * @internal
   */
  activeClass = 'is-active';

  /**
   * Set the countries colors
   */
  @Input() set countriesColors(val: CountryColor[]) {
    this._countriesColors = val;
    this.applyCountryColoring(val);
  }
  get countriesColors() {
    return this._countriesColors;
  }
  /**
   * @internal
   */
  private _countriesColors: CountryColor[];
  /**
   * Set focus on country
   */
  @Input() set focusCountry(val: string) {
    if (this.mapSVG) this.setCountryFocus(val ? val : null);
  }

  /**
   * @internal
   */
  @HostListener('click', ['$event'])
  onClick(event) {
    const countryCode = event.target?.parentElement?.id;
    if (countryCode) {
      const el = this.mapSVG.querySelector('g#' + countryCode);
      el.classList.toggle(this.activeClass);
      this.countrySelected.emit(countryCode);
    }
  }

  /**
   * @internal
   */
  ngOnInit() {
    this.applyBaseColoring();
  }

  /**
   * @internal
   */
  ngAfterViewInit() {
    this.mapSVG = this.mapEl.nativeElement;
    if (this.mapSVG) {
      this.applyCountryColoring(this._countriesColors);
      this.svgReady.emit();
    }
  }

  /**
   * @internal
   */
  applyBaseColoring() {
    const css = `
      mulo-world-map svg g:hover {
        fill: ${!this.static ? this.hoverColor : ''}
      }
      mulo-world-map svg g.${this.activeClass} {
        fill: ${!this.static ? this.activeColor : ''}
      }
      `;
    const head = this.document.getElementsByTagName('head')[0];
    const style = this.document.createElement('style');
    style.type = 'text/css';
    style.appendChild(this.document.createTextNode(css));
    head.appendChild(style);
  }

  /**
   * @internal
   */
  setCountryFocus(val) {
    if (val) {
      this.mapSVG.querySelector('g#' + val).classList.add('is-in-focus');
      this.countryFocus = true;
    } else {
      this.mapSVG.querySelectorAll('g.is-in-focus').forEach((g) => {
        g.classList.remove('is-in-focus');
      });
      this.countryFocus = false;
    }
  }

  /**
   * @internal
   */
  applyCountryColoring(_colors?) {
    const colors = _colors ? _colors : this._countriesColors;

    for (const country of colors) {
      const el = this.mapSVG.querySelector('g#' + country.code);
      if (el) {
        el.setAttribute('style', `fill: ${country.color}`);
      }
    }
  }
}
