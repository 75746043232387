<dt class="label is-{{labelAlign}}-aligned">
  <ng-content select="[slot='label']"></ng-content>
  @if (!labelView) {
    {{label}}
  }
</dt>
<dd class="value">
  <ng-content select="[slot='content']"></ng-content>
  @if (!contentView) {
    {{content}}
  }
</dd>
