import { Component, OnInit, inject } from '@angular/core';
import {CodeTableService} from '../../../core/code-table.service';
import {CodeLabelEntry} from '../../../shared/interfaces/code-label-entry.interface';
import {DepositFormDataService} from '../../deposit-form-data.service';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';

import { MatOption } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatSelect } from '@angular/material/select';
import { MatFormField } from '@angular/material/form-field';


@Component({
    selector: 'esp-deposit-anz-toa',
    templateUrl: './deposit-anz-toa.component.html',
    styleUrls: ['./deposit-anz-toa.component.scss'],
    standalone: true,
    imports: [MatFormField, MatSelect, FormsModule, ReactiveFormsModule, MatOption, TranslateModule, CustomTranslatePipe]
})
export class DepositAnzToaComponent implements OnInit {
    private codetableService = inject(CodeTableService);
    depositFormDataService = inject(DepositFormDataService);


    activities: String[];

    ngOnInit() {
        this.codetableService.getCodeTable('ANZTypesOfActivityCodes').subscribe((data) => {
            this.activities =  (data as CodeLabelEntry[]).map(entry => entry.code);
        });
    }
}
