<div class="spinner" [ngStyle]="{ height: height }">
  @if (!projectedSpinner) {
    @if (spinnerStyle === 'material') {
      <mat-spinner [diameter]="spinnerDiameter"></mat-spinner>
    }
    @if (spinnerStyle === 'mulo') {
      <mulo-spinner [diameter]="spinnerDiameter"></mulo-spinner>
    }
  }
  <ng-content select="mulo-spinner, [mulo-spinner]"></ng-content>
</div>
<ng-content></ng-content>
