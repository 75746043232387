import { Component, ChangeDetectionStrategy, Input, ViewChild, ElementRef, HostBinding, ContentChildren, QueryList, AfterViewInit, SimpleChanges, OnChanges, Output, EventEmitter, inject } from '@angular/core';
import { MatRipple } from '@angular/material/core';
import { BehaviorSubject } from 'rxjs';
import { ExpandableComponent, MuloNavLink } from '@exl-ng/mulo-common';
import { AsyncPipe } from '@angular/common';

@Component({
    selector: 'mulo-page-section',
    templateUrl: './page-section.component.html',
    styleUrls: ['./page-section.component.scss'],
    host: { class: 'mulo-page-section' },
    standalone: true,
    imports: [
    MatRipple,
    AsyncPipe
],
})
export class PageSectionComponent implements AfterViewInit, OnChanges {
  private el = inject(ElementRef);

  @Output() navLinkChanges = new EventEmitter<MuloNavLink>();

  @HostBinding('class.is-overflow-hidden')
  @Input()
  hideOverflow = false;
  @Input() title: string;
  @Input() imageSrc: string;
  @Input() imageAlt: string;
  @Input() imageOnRight = false;
  @Input() imageFullWidth = false;

  @ContentChildren(ExpandableComponent, { descendants: true })
  expandableCmps: QueryList<ExpandableComponent>;

  @ViewChild(MatRipple) ripple: MatRipple;

  highlightActive$ = new BehaviorSubject<boolean>(false);

  public navLink: MuloNavLink;

  ngAfterViewInit() {
    if (this.expandableCmps.length > 0) {
      this.hideOverflow = true;
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.title && changes.title.currentValue) {
      const name = this.underscoreStr(changes.title.currentValue);

      this.navLink = {
        ...this.navLink,
        label: changes.title.currentValue,
        name,
        url: `#${name}`,
      };
      this.navLinkChanges.emit(this.navLink);
    }
  }

  underscoreStr(str) {
    return str.toLowerCase().replace(/ /g, '_');
  }

  public highlight() {
    this.focusSectionOnHighlight();

    const el = this.el.nativeElement.getBoundingClientRect();
    this._launchRipple(el.left, el.top + el.height / 2);
  }

  private focusSectionOnHighlight() {
    this.el.nativeElement.tabIndex = 0;
    this.el.nativeElement.focus();
    this.el.nativeElement.tabIndex = -1;
  }

  private _launchRipple(x: number = 0, y: number = 0) {
    this.highlightActive$.next(true);
    this.ripple.fadeOutAll();
    const rippleRef = this.ripple.launch(x, y, {
      persistent: true,
      centered: false,
      animation: { exitDuration: 600 },
    });
    setTimeout(() => {
      rippleRef.fadeOut();
      setTimeout(() => {
        this.highlightActive$.next(false);
      }, 400);
    }, 600);
  }
}
