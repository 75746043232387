import { Component, OnDestroy, OnInit, inject } from '@angular/core';
import { DepositFormDataService } from '../../deposit-form-data.service';
import { Subject, takeUntil } from 'rxjs';
import { AutoPopulateKeys } from '../../../shared/configurations/auto-populate-keys.config';
import { CustomTranslatePipe } from '../../../shared/pipes/custom-translate.pipe';
import { DepositFreeTextComponent } from '../deposit-free-text/deposit-free-text.component';

@Component({
    selector: 'esp-deposit-description',
    templateUrl: './deposit-description.component.html',
    styleUrls: ['./deposit-description.component.scss'],
    standalone: true,
    imports: [DepositFreeTextComponent, CustomTranslatePipe],
})
export class DepositDescriptionComponent implements OnInit, OnDestroy {
  depositFormDataService = inject(DepositFormDataService);

  private descriptionDestroy = new Subject<void>();

  ngOnInit() {
    this.onChanges();
  }

  onChanges(): void {
    this.depositFormDataService.description.valueChanges
      .pipe(takeUntil(this.descriptionDestroy))
      .subscribe((value) => {
        if (!this.depositFormDataService.startPopulating) {
          this.depositFormDataService.removeKeyFromAutoPopulatedArray(
            AutoPopulateKeys.DESCRIPTION
          );
        }
      });
  }

  get isAutoPopulated() {
    return this.depositFormDataService.isKeyAutoPopulated(
      AutoPopulateKeys.DESCRIPTION
    );
  }

  ngOnDestroy() {
    if (!this.descriptionDestroy.isStopped) {
      this.descriptionDestroy.next();
      //unsubscribe from the subject itself
      this.descriptionDestroy.unsubscribe();
    }
  }
}
