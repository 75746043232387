import { CdkAccordion, CdkAccordionItem } from '@angular/cdk/accordion';
import { UniqueSelectionDispatcher } from '@angular/cdk/collections';
import { ChangeDetectorRef, Component, Input, ViewChild, inject } from '@angular/core';
import { MatButton } from '@angular/material/button';
import { SvgViewboxDirective } from '@exl-ng/mulo-core';
import { MatIcon } from '@angular/material/icon';
import { NgTemplateOutlet } from '@angular/common';

let uniqueId = 0;

@Component({
    selector: 'mulo-expansion-panel',
    templateUrl: './expansion-panel.component.html',
    styleUrls: ['./expansion-panel.component.scss'],
    host: {
        '[class.is-expanded]': 'expanded',
        role: 'row',
    },
    standalone: true,
    imports: [
    MatIcon,
    SvgViewboxDirective,
    MatButton,
    NgTemplateOutlet
],
})
export class ExpansionPanelComponent extends CdkAccordionItem {
  @Input() headerLabel;
  @ViewChild('body') bodyContent;

  readonly _id: string;

  constructor() {
    const accordion = inject(CdkAccordion, { optional: true, skipSelf: true });
    const _changeDetectorRef = inject(ChangeDetectorRef);
    const _uniqueSelectionDispatcher = inject(UniqueSelectionDispatcher);

    super(accordion, _changeDetectorRef, _uniqueSelectionDispatcher);
    this._id = `${uniqueId++}`;
  }

  get hasBodyContent() {
    return this.bodyContent?.nativeElement?.children?.length > 0;
  }

  preventDrag(ev) {
    ev.preventDefault();
    ev.stopPropagation();
    ev.stopImmediatePropagation();
    ev.target.dispatchEvent(new MouseEvent('click', { ...ev }));
  }
}
