<div class="wrapper" role="gridcell">
  <div class="header" (keydown.space)="preventDrag($event)">
    <ng-content select="[slot='header-pre-label']"></ng-content>
    <ng-template #headerText>
      <div class="header-text" [class.header-text--text-only]="!hasBodyContent">
        <span class="title" [id]="'expansion-header-title-' + _id">{{ headerLabel }}</span>
        @if (hasBodyContent) {
          <mat-icon class="expand-icon" svgIcon="chevron-down" muloSvgViewbox></mat-icon>
        }
      </div>
    </ng-template>
    @if (hasBodyContent) {
      <button
        mat-flat-button
        class="expand-button"
        (keydown.enter)="toggle()"
        (click)="toggle()"
        [attr.aria-expanded]="expanded"
        [attr.aria-controls]="body.id"
        disableRipple
        >
        <ng-container *ngTemplateOutlet="headerText"></ng-container>
      </button>
    } @else {
      <div class="header-text" [class.header-text--text-only]="!hasBodyContent">
        <span class="title" [id]="'expansion-header-title-' + _id">{{ headerLabel }}</span>
        @if (hasBodyContent) {
          <mat-icon class="expand-icon" svgIcon="chevron-down" muloSvgViewbox></mat-icon>
        }
      </div>
    }
  </div>
  <div
    [id]="'expansion-body-' + _id"
    class="content"
    role="region"
    [style.display]="expanded ? '' : 'none'"
    [attr.aria-labelledby]="'expansion-header-title-' + _id"
    #body
    >
    <ng-content></ng-content>
  </div>
</div>
