<mat-card appearance="outlined">
  @if (error && error.length) {
    <div class="error-message">
      {{error}}
    </div>
  }
  <form [formGroup]="loginForm" (ngSubmit)="doSubmit()">
    <div class="login-form">
      <mat-form-field class="is-auto-filling">
        <mat-label>{{loginLabel}}</mat-label>
        <input matInput type="text" formControlName="username" required />
        @if (submitted && f.username.errors && f.username.errors.required) {
          <mat-error
            >
            {{usernameRequiredError}}
          </mat-error>
        }
      </mat-form-field>
      <mat-form-field class="is-auto-filling">
        <mat-label>{{passwordLabel}}</mat-label>
        <input matInput type="password" formControlName="password" required />
        @if (submitted && f.password.errors && f.password.errors.required) {
          <mat-error
            >
            {{passwordRequiredError}}
          </mat-error>
        }
      </mat-form-field>
      <button
        mat-raised-button
        type="submit"
        color="primary"
        class="submit-button is-large"
        [class.is-processing]="loading"
        >
        @if (!loading) {
          {{signInCta}}
        }
        @if (loading) {
          <mat-spinner diameter="30"></mat-spinner>
        }
      </button>
      @if (helpText && helpText.length) {
        <a mat-button class="is-tiny" href="" color="accent">{{helpText}}</a>
      }
    </div>
  </form>
</mat-card>
