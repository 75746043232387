import { Component, OnInit, ViewChild, inject } from '@angular/core';
import { Router } from '@angular/router';
import { LoginService } from '../../../core/login.service';
import { AuthenticationInfo } from '../../../shared/interfaces/authentication-info.model';
import { JwtUtilService } from '../../../core/jwt-util.service';
import { AuthService } from '../../../core/auth.service';
import { UrlUtils } from '../../../shared/utils/url.utils';
import { ConfigurationHandlerService } from '../../../core/configuration-handler.service';
import { TranslateService, TranslateModule } from '@ngx-translate/core';
import { SettingsService } from '../../../settings/settings.service';
import { ResearcherDataService } from '../../../core/researcher-data.service';
import { SEOService } from '../../../core/seo.service';
import { State } from '../../../shared/configurations/state.constant';
import { I18nService } from '../../../core/i18n.service';
import { OnetrustPendoService } from '../../../core/onetrust-pendo.service';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { MatButton, MatAnchor } from '@angular/material/button';
import { MatInput } from '@angular/material/input';
import { MatFormField, MatLabel, MatError } from '@angular/material/form-field';

import { FormsModule } from '@angular/forms';
import { MatCard } from '@angular/material/card';

@Component({
    selector: 'exl-login-box',
    templateUrl: './login-box.component.html',
    styleUrls: ['./login-box.component.scss'],
    standalone: true,
    imports: [
    MatCard,
    FormsModule,
    MatFormField,
    MatInput,
    MatLabel,
    MatError,
    MatButton,
    MatProgressSpinner,
    MatAnchor,
    TranslateModule
],
})
export class LoginBoxComponent implements OnInit {
    private router = inject(Router);
    private loginService = inject(LoginService);
    private jwtUtilService = inject(JwtUtilService);
    private authService = inject(AuthService);
    private SEOService = inject(SEOService);
    private configurationHandlerService = inject(ConfigurationHandlerService);
    private translate = inject(TranslateService);
    private settingsService = inject(SettingsService);
    private researcherDataService = inject(ResearcherDataService);
    private i18nService = inject(I18nService);
    private otpSvc = inject(OnetrustPendoService);

    @ViewChild('loginForm', { static: false }) loginForm: any;

    institutionCode: string;
    user = '';
    password = '';
    submitInProgress = false;
    error = false;
    errorMessage = '';
    resetPwUrl = '';
    showResetPwUrl: boolean;

    ngOnInit() {
        this.changeDocumentTitle();
        this.institutionCode = UrlUtils.getParam('institution');
        this.resetPwUrl = this.configurationHandlerService.getResetPwUrl();
        this.showResetPwUrl =
            !this.configurationHandlerService.isExternalLogin() &&
            this.configurationHandlerService.isCustomerParameterEnabled(
                'active_directory_for_internal_pw',
            );
    }

    changeDocumentTitle() {
        this.translate
            .get('research.login.document.title', {
                value: this.configurationHandlerService.getMainInstitutionName(),
            })
            .subscribe((res) => {
                this.SEOService.updateTitle(res);
            });
    }

    doSignIn() {
        if (this.user && this.password) {
            this.submitInProgress = true;
            this.loginService
                .handleInternalLogin(this.user, this.password)
                .subscribe(
                    (data) => {
                        this.error = false;
                        this.errorMessage = '';
                        this.submitInProgress = false;
                        const authenticationInfo = data as AuthenticationInfo;
                        const jwt = authenticationInfo.jwtData;
                        let redirectUrl = authenticationInfo.redirectUrl;
                        if (jwt) {
                            this.onSuccessLogin(jwt);
                        }
                        if (redirectUrl) {
                            const proxyFor = this.jwtUtilService.getProxyFor();
                            if (proxyFor) {
                                redirectUrl =
                                    this.loginService.handleDecodeEncode(
                                        redirectUrl,
                                        this.authService.getLoginAsPage(),
                                    );
                            } else {
                                redirectUrl =
                                    this.loginService.handleDecodeEncode(
                                        redirectUrl,
                                        this.authService.redirectUrl,
                                    );
                            }
                            window.location.href = redirectUrl;
                        } else {
                            const proxyFor = this.jwtUtilService.getProxyFor();
                            if (proxyFor) {
                                this.router.navigate([State.LOGIN_AS], {
                                    queryParams: {
                                        institution: this.institutionCode,
                                    },
                                });
                            } else {
                                this.router.navigate(
                                    [this.authService.redirectRoute],
                                    {
                                        queryParams: {
                                            institution: this.institutionCode,
                                        },
                                    },
                                );
                            }
                        }
                    },
                    (error) => {
                        this.handleError(error);
                    },
                );
        }
    }

    onSuccessLogin(jwt) {
        this.jwtUtilService.storeJwt(jwt);
        if (
            this.jwtUtilService.getLanguage() &&
            this.i18nService.getLanguage() !== this.jwtUtilService.getLanguage()
        ) {
            this.i18nService.setLanguage(this.jwtUtilService.getLanguage());
        } else if (
            !this.jwtUtilService.getLanguage() &&
            this.i18nService.getLanguage() !==
                this.configurationHandlerService.getDefaultDisplayLanguage()
        ) {
            this.i18nService.setLanguage(
                this.configurationHandlerService.getDefaultDisplayLanguage(),
            );
        }
        this.loadProfileImage();
        this.otpSvc.updateLoggedIn();
    }

    handleError(error) {
        this.submitInProgress = false;
        this.error = true;
        console.error(error);
        if (
            error.status === 401 &&
            error.error.indexOf('researcher role is missing') !== -1
        ) {
            this.errorMessage = 'research.login.error.lack.privileges';
        } else {
            this.errorMessage =
                'research.login.error.invalid.username.or.password';
        }
    }

    loadProfileImage() {
        this.settingsService
            .getResearcherProfileImage(this.jwtUtilService.getUserID())
            .subscribe((data) => {
                if (data) {
                    this.researcherDataService.setProfileImageUrl(data);
                }
            });
    }
}
