import { Component, OnInit, inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialogTitle, MatDialogClose } from '@angular/material/dialog';
import { DepositFormDataService } from '../deposit-form-data.service';
import { DepositFilesLinksService } from './deposit-files-links.service';
import { CustomTranslatePipe } from '../../shared/pipes/custom-translate.pipe';
import { TranslateModule } from '@ngx-translate/core';
import { MatTooltip } from '@angular/material/tooltip';
import { MatIconButton } from '@angular/material/button';
import { MatIcon } from '@angular/material/icon';
import { FunkyButtonComponent } from '@exl-ng/mulo-common';
import { DepositFilesComponent } from '../deposit-sections/deposit-files/deposit-files.component';
import { DepositFormSectionComponent } from '../deposit-form-section/deposit-form-section.component';

@Component({
    templateUrl: './deposit-files-links-dialog.component.html',
    styleUrls: ['./deposit-files-links-dialog.component.scss'],
    standalone: true,
    imports: [
        MatDialogTitle,
        DepositFormSectionComponent,
        DepositFilesComponent,
        FunkyButtonComponent,
        MatIcon,
        MatIconButton,
        MatDialogClose,
        MatTooltip,
        TranslateModule,
        CustomTranslatePipe,
    ],
})
export class DepositFilesLinksDialogComponent implements OnInit {
    private dialogRef = inject<MatDialogRef<DepositFilesLinksDialogComponent>>(MatDialogRef);
    private filesAndLinksService = inject(DepositFilesLinksService);
    private depositFormDataService = inject(DepositFormDataService);

    get isFilesAdded() {
        return this.depositFormDataService.links?.length || this.depositFormDataService.files?.length;
    }

    private mmsId: string;
    title: string;
    type: string;

    constructor() {
        const data = inject(MAT_DIALOG_DATA);

        this.mmsId = data?.mmsId;
        this.title = data.title;
        this.type = data.type;
    }

    ngOnInit(): void {
    this.depositFormDataService.mmsId.setValue(this.mmsId);
        this.filesAndLinksService.updateAmazonDetails();
    }

    onSubmit() {
        this.filesAndLinksService.uploadNewFilesAndLinks(this.mmsId);
        setTimeout(() => {
            this.dialogRef.close();
        }, 1000);
    }
}
