import {
  Component,
  ContentChild,
  HostBinding,
  Input,
  OnInit,
} from '@angular/core';
import { SpinnerComponent } from '../spinner';
import { OpacityInAnimation } from '../../animations';
import { MatProgressSpinner } from '@angular/material/progress-spinner';
import { NgStyle } from '@angular/common';

@Component({
  selector: 'mulo-loading-slate',
  templateUrl: './loading-slate.component.html',
  styleUrls: ['./loading-slate.component.scss'],
  animations: [OpacityInAnimation],
  host: {
    class: 'mulo-loading-slate is-loading',
  },
  standalone: true,
  imports: [NgStyle, MatProgressSpinner, SpinnerComponent],
})
export class LoadingSlateComponent implements OnInit {
  // @HostBinding('class.is-loading') @Input() isLoading: boolean;
  // @Input() spinnerDisabled: boolean = false;
  @Input() height: string = 'calc(100vh - 300px)';

  @Input() spinnerStyle: 'material' | 'mulo' = 'mulo';
  @Input() spinnerDiameter: number = 80;

  @ContentChild(SpinnerComponent) projectedSpinner: SpinnerComponent;

  private _active: boolean = false;
  @HostBinding('@opacityInAnimation') get visible(): boolean {
    return this._active;
  }

  constructor() {}

  ngOnInit() {
    this._active = true;
  }
}
