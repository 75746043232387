import { coerceBooleanProperty } from '@angular/cdk/coercion';
import { AfterContentInit, ChangeDetectorRef, Component, ContentChild, ContentChildren, Directive, ElementRef, EventEmitter, HostBinding, Input, OnChanges, Output, QueryList, SimpleChanges, inject } from '@angular/core';
import { ThemePalette } from '@angular/material/core';
import { MatButton, MatIconButton } from '@angular/material/button';
import {
  GrowInAnimation,
  GrowOutAnimation,
} from '../../animations/grow.animation';
import { ColorComponentMixinBase } from '../../common-behavior/color';
import { MatIcon } from '@angular/material/icon';


@Directive({
    selector: '[slot="closed-view"]',
    standalone: true
})
export class ContextFrameClosedViewDirective {}

@Component({
    selector: 'mulo-context-frame',
    templateUrl: './context-frame.component.html',
    styleUrls: ['./context-frame.component.scss'],
    animations: [GrowInAnimation, GrowOutAnimation],
    host: {
        '(keydown.escape)': 'closeFrame($event)',
    },
    inputs: ['color'],
    standalone: true,
    imports: [
    MatIconButton,
    MatIcon,
    MatButton
],
})
export class ContextFrameComponent
  extends ColorComponentMixinBase
  implements OnChanges, AfterContentInit
{
  private cdr = inject(ChangeDetectorRef);

  /**
   * @internal
   */
  private _isOpen = false;

  @Input()
  get isOpen(): boolean {
    return this._isOpen;
  }

  set isOpen(value: boolean) {
    this._isOpen = value;
    this.activeClass = value;
    setTimeout(
      () => {
        this.visibleClass = value;
        this.cdr.markForCheck();
      },
      value ? 0 : 500
    );

    (value ? this.open : this.close).emit();
    this.isOpenChange.emit(value);
  }

  @Output() isOpenChange = new EventEmitter<boolean>();
  @Output() open = new EventEmitter<null>();
  @Output() close = new EventEmitter<null>();

  @Input() btnIcon = 'plus';
  @Input() rtlMirrorIcon: boolean;
  @Input() btnLabel = 'Add';
  @Input() showOpenBtn = true;
  @Input() closeBtnAriaLabel = 'Close';

  @HostBinding('class.is-active') activeClass = false;
  @HostBinding('class.is-visible') visibleClass = false;

  @HostBinding('class.hasnt-open-btn') get noOpenBtn() {
    return !this.showOpenBtn;
  }

  @ContentChild(ContextFrameClosedViewDirective) closedView;
  @ContentChildren(MatButton, { descendants: true })
  buttons: QueryList<MatButton>;

  constructor() {
    const elementRef = inject(ElementRef);

    super(elementRef);
    this.color = 'primary';
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes?.isOpen && changes?.isOpen.currentValue === true) {
      this._elementRef.nativeElement.tabIndex = 0;
      this._elementRef.nativeElement.focus();
      this._elementRef.nativeElement.tabIndex = -1;
    }
  }

  ngAfterContentInit() {
    this.buttons.forEach((button) => {
      button.color = button.color || (this.color as ThemePalette);
    });
  }

  closeFrame(event: KeyboardEvent) {
    // Prevent close on opened select dropdown close event
    const el = event.target as HTMLElement;
    if (!coerceBooleanProperty(el.ariaExpanded)) {
      this.isOpen = false;
      event.preventDefault();
      event.stopPropagation();
    }
  }
}
