@if (isOpen) {
  <div @growInAnimation @growOutAnimation class="mulo-context-frame-content">
    <ng-content></ng-content>
    <button
      mat-icon-button
      [color]="color"
      (click)="isOpen = false"
      type="button"
      class="close-btn"
      [attr.aria-label]="closeBtnAriaLabel"
      >
      <mat-icon svgIcon="close"></mat-icon>
    </button>
  </div>
}
@if (!isOpen && showOpenBtn) {
  <div @growInAnimation @growOutAnimation>
    <ng-content select="[slot='closed-view']"></ng-content>
    @if (!closedView) {
      <button mat-button class="main-btn has-icon" [color]="color" (click)="isOpen = true">
        <mat-icon [svgIcon]="btnIcon" [class.mat-icon-rtl-mirror]="rtlMirrorIcon"></mat-icon>
        <span class="btn-text">{{ btnLabel }}</span>
      </button>
    }
  </div>
}
